import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errors'
})
export class ErrorsPipe implements PipeTransform {
  errorArray = [
    {
      errorCode: 'E113',
      modalTitle: 'Please, upgrade your subscription',
      modalText:
        'You have reached the maximum number of nodes allowed on your license. Please, contact our sales team to upgrade your plan.'
    },
    {
      errorCode: 'E114',
      modalTitle: 'Please, upgrade your subscription',
      modalText:
        'Your license does not allow to build images. Please, contact our sales team to upgrade your plan.'
    },
    {
      errorCode: 'E115',
      modalTitle: 'Please, upgrade your subscription',
      modalText:
        'You have reached the maximum number of users allowed on your license. Please, contact our sales team to upgrade your plan.'
    },
    {
      errorCode: 'E063',
      modalTitle: 'Please, upgrade your subscription',
      modalText: 'Your license has expired. Please, contact our sales team to upgrade your plan.'
    },
    {
      errorCode: 'E104',
      modalTitle: 'Please, upgrade your subscription',
      modalText: `Your current subscription doesn't support this functionality. Please, contact our sales team to upgrade your plan.`
    },
    {
      errorCode: 'E103',
      modalTitle: 'Sorry, you do not have the necessary permissions',
      modalText:
        'Your current profile is not authorized to use this functionality. Please contact your administrator for more information.'
    },
    {
      errorCode: 'E077',
      modalTitle: 'Please, update your node firmware.',
      modalText:
        'Your node firmware does not have this capability. Please, update your node firmware (OS and Node Manager) to its latest version.'
    }
  ];

  transform(
    value: string,
    ...args: any[]
  ): { errorCode: string; modalTitle: string; modalText: string } {
    return this.errorArray.find((error) => error.errorCode === value);
  }
}