import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { commonLabels } from '../../common/common.labels';
import { ModalImgCropperComponent } from '../../common/components/modal-img-cropper/modal-img-cropper.component';
import { CommonLabels, dataTable, PaginatorTable } from '../../common/models/common.model';
import { AuthService } from '../../common/services/auth.service';
import { ResizeWindowService } from '../../common/services/resize-window.service';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { StorageService } from '../../common/services/storage.service';
import { TableService } from '../../common/services/table.service';
import { UtilsService } from '../../common/services/utils.service';
import { usersListLabels, usersViewTableLabels } from '../labels/users.labels';
import { UsersListLabels } from '../models/users.model';
import { UsersService } from '../services/users.service';
import { NewUserComponent } from './new-user/new-user.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
@AutoUnsubscribe()
export class UsersListComponent implements OnInit, OnDestroy {
  className = 'users-list';
  @ViewChild('imagenDevice') imagenDevice: ElementRef;
  labels: UsersListLabels = usersListLabels;
  labelsCommon: CommonLabels = commonLabels;
  filterPlaceholderLabel = this.labels.filterPlaceholderLabel;
  minCharacterToFilter: number = 1;
  tabSelectedIndex: number = 0;
  showTable: boolean = true;
  showGrid: boolean = false;
  showMap: boolean = false;
  isDesktop: boolean;
  isLoading: boolean = true;
  selectedView: string = 'table';
  filterInput: string = '';
  from = 0;
  size = 100;
  filter = '';
  search = '';
  blob: any[];
  imgCropperDialogRef: MatDialogRef<ModalImgCropperComponent>;
  width: number;
  imgDefault: string = 'assets/images/avatars/profile.jpg';
  tabsList = [this.labels.confirmed, this.labels.pending];

  originalData: any = [];
  copyData: any = [];
  displayedColumns = [];
  paginator: PaginatorTable = {
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: null,
    page: 0,
    pagingCounter: 0,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0
  };
  dataTable: dataTable[];
  createDialogRef: MatDialogRef<NewUserComponent>;
  uconfimed: any;
  upending: any;
  callService: boolean = false;
  totalUsers: number = 0;
  @ViewChild('tabList', { static: false }) tabList: MatTabGroup;
  roles: any;
  dataCompany: any;
  avatarUri: any;
  showEditImage: boolean;
  userMainRole: string;

  constructor(
    private usersService: UsersService,
    private resizeWindowService: ResizeWindowService,
    private tableService: TableService,
    private storageService: StorageService,
    private matDialog: MatDialog,
    public snackMessageService: SnackMessageService,
    private authService: AuthService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.resizeWindowService.width$.subscribe({
      next: (value: number) => {
        this.isLoading = true;
        this.width = value;
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.tabSelectedIndex === 0
              ? this.prepareDataTableConfirmed()
              : this.prepareDataTablePending();

            this.isLoading = false;
          },
          700,
          'loadingPageUsers'
        );
      }
    });
    this.usersService.getDataCompany().subscribe(
      (response) => {
        this.dataCompany = response;
        this.avatarUri = this.dataCompany.avatar;
      },
      (error) => {
        this.snackMessageService.readError(error);
      }
    );
    this.authService.getMainRol().subscribe((userMainRol) => {
      this.userMainRole = userMainRol;
      this.isLoading = true;
      this.switchTab(this.tabSelectedIndex, this.filterInput, true, 'total');
    });
  }

  getConfirmed(from, size, filter, search?, total?): void {
    this.isLoading = true;
    if (this.userMainRole === 'role_company_admin') {
      this.uconfimed = this.usersService.getUsersConfirmedObs(from, size, filter, search).subscribe(
        (response) => {
          this.originalData = response.users;
          this.copyData = response.users;
          this.paginator.hasNextPage = response.hasNextPage;
          this.paginator.hasPrevPage = response.hasPrevPage;
          this.paginator.limit = response.limit;
          this.paginator.nextPage = response.nextPage;
          this.paginator.page = response.page;
          this.paginator.pagingCounter = response.pagingCounter;
          this.paginator.prevPage = response.prevPage;
          this.paginator.totalDocs = response.totalDocs;
          this.paginator.totalPages = response.totalPages;
          if (total) {
            this.totalUsers = response.totalDocs;
          }
          let sortBy = JSON.parse(this.storageService.retrieveListSorting('_users-confirmed-list'));
          if (sortBy !== null) {
            this.tableService.storeSort(sortBy, this.originalData, '_users-confirmed-list');
          }
          this.prepareDataService(this.originalData, 'Confirmed');
        },
        (error) => {
          //console.log(error);
          this.snackMessageService.readError(error);
          this.isLoading = false;
        }
      );
    } else {
      this.originalData = [];
      this.isLoading = false;
    }
  }

  getPending(from, size, filter, search?, total?): void {
    this.isLoading = true;
    this.upending = this.usersService.getUsersPendingObs(from, size, filter, search).subscribe(
      (response) => {
        this.originalData = response.pending;
        this.copyData = response.pending;
        this.paginator.hasNextPage = response.hasNextPage;
        this.paginator.hasPrevPage = response.hasPrevPage;
        this.paginator.limit = response.limit;
        this.paginator.nextPage = response.nextPage;
        this.paginator.page = response.page;
        this.paginator.pagingCounter = response.pagingCounter;
        this.paginator.prevPage = response.prevPage;
        this.paginator.totalDocs = response.totalDocs;
        this.paginator.totalPages = response.totalPages;
        if (total) {
          this.totalUsers = response.totalDocs;
        }
        this.prepareDataTablePending();
        let sortBy = JSON.parse(this.storageService.retrieveListSorting('_users-pending-list'));
        if (sortBy !== null) {
          this.tableService.storeSort(sortBy, this.originalData, '_users-pending-list');
        }
        this.prepareDataService(this.originalData, 'Pending');
      },
      (error) => {
        this.snackMessageService.readError(error);
        //console.log(error);
        this.isLoading = false;
        //this.snackMessageService.sendMessage(error.error.message);
      }
    );
  }

  getAllPaginator(value): void {
    // Revisar si se pone paginación
    if (value === 0) {
      this.from = value;
      this.paginator.nextPage = value + 1;
      this.originalData = [];
    } else {
      this.from = this.paginator.nextPage;
    }
    if (this.tabSelectedIndex === 0) {
      this.getConfirmed(this.from, this.size, this.filter, this.search);
    } else {
      this.getPending(this.from, this.size, this.filter, this.search);
    }
  }

  prepareDataService(data, tabType) {
    data.forEach((elem) => {
      delete elem.code;
      if (tabType === 'Confirmed') {
        elem.fullName = elem.firstName + ' ' + elem.lastName;
        elem.avatar = elem.avatar !== '' ? elem.avatar : this.imgDefault;
        elem.roles = this.utilsService.getRoles(elem.roles);
      } else {
        elem.roles = this.utilsService.getRoles(elem.roles);
      }
    });
  }

  prepareDataTableConfirmed(): void {
    this.dataTable = [
      {
        labels: usersViewTableLabels.avatar,
        colType: 'img',
        colData: 'avatar',
        detail: false,
        showCol: this.width > 650,
        align: 'center'
      },
      {
        labels: usersViewTableLabels.name,
        colType: 'text',
        colData: 'fullName',
        detail: false,
        width: this.width < 650 ? '45vw' : '17vw',
        showCol: true
      },
      {
        labels: usersViewTableLabels.email,
        colType: 'text',
        colData: 'email',
        showCol: this.width > 650
      },
      {
        labels: usersViewTableLabels.phone,
        colType: 'text',
        colData: 'phone',
        showCol: this.width > 1000
      },
      {
        labels: usersViewTableLabels.roles,
        colType: 'text',
        colData: 'roles',
        showCol: this.width > 1000
      },
      {
        labels: usersViewTableLabels.actions,
        colType: 'button',
        colData: 'actions',
        showCol: true,
        width: this.width > 1000 ? '17vw' : '17vw',
        align: 'center',
        actions: [
          {
            icon: {
              iconName: 'edit',
              labelsTooltip: usersViewTableLabels.edit
            },
            actionType: 'edit',
            disabledButton: false,
            showButton: true
          },
          {
            icon: {
              iconName: 'delete',
              labelsTooltip: usersViewTableLabels.delete
            },
            actionType: 'deleteUser',
            disabledButton: false,
            showButton: true
          }
        ]
      }
    ];
  }

  public changeImage(event: any, typeImg: string, src: string, index?): void {
    //console.log(event, typeImg, src, index);
    this.imgCropperDialogRef = this.matDialog.open(ModalImgCropperComponent, {
      height: 'auto',
      width: this.width > 1000 ? '448px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      disableClose: true,
      data: {
        width: this.width,
        index: index,
        imageChangedEvent: event,
        typeImg: typeImg,
        containWithinAspectRatio: false,
        maintainAspectRatio: true,
        widthImage: 300,
        heightImage: 300,
        cropperStaticWidth: 150,
        cropperStaticHeight: 150,
        roundCropper: false,
        title: this.labelsCommon.cropper + ' Company ' + typeImg,
        button1Label: this.labelsCommon.upload,
        button2Label: this.labelsCommon.cancel
      }
    });
    this.imgCropperDialogRef.afterClosed().subscribe(async (result) => {
      if (result.action === 'cancel') {
        this.blob = [];
        return;
      } else {
        const filesToUpload = result.croppedImage;
        this.updateAvatar(filesToUpload, result.typeImg, result.fileToSend.lastModified);
      }
    });
  }

  updateAvatar(filesToUpload, name, date) {
    const type = filesToUpload.split('/')[1].split(';')[0];
    const avatar = this.utilsService.dataURLtoFile(filesToUpload, `${date}${name}.${type}`);
    this.usersService
      .uploadImageCompany(avatar)
      .then((res: any) => {
        this.avatarUri = res;
        this.snackMessageService.sendMessage('S031');
      })
      .catch((err) => {
        this.snackMessageService.readError(err);
      });
  }

  prepareDataTablePending(): void {
    this.dataTable = [
      {
        labels: usersViewTableLabels.email,
        colType: 'text',
        colData: 'email',
        showCol: true,
        width: this.width > 1025 ? '45vw' : '50vw'
      },
      {
        labels: usersViewTableLabels.roles,
        colType: 'text',
        colData: 'roles',
        showCol: this.width > 650,
        width: this.width > 1025 ? '20vw' : '20vw'
      },
      {
        labels: usersViewTableLabels.actions,
        colType: 'button',
        colData: 'actions',
        showCol: true,
        width: this.width > 1025 ? '15vw' : '17vw',
        align: 'center',
        actions: [
          {
            icon: {
              iconName: 'forward_to_inbox',
              labelsTooltip: usersViewTableLabels.resend
            },
            actionType: 'resend',
            disabledButton: false,
            showButton: true
          },
          {
            icon: {
              iconName: 'delete',
              labelsTooltip: usersViewTableLabels.delete
            },
            actionType: 'deleteInvitation',
            disabledButton: false,
            showButton: true
          }
        ]
      }
    ];
  }

  inputFilter(event) {
    this.filterInput = event.event.target.value;
    this.tabSelectedIndex = event.tab;
    this.switchTab(this.tabSelectedIndex, this.filterInput);
  }

  clearFilter(event) {
    if (event.tab !== undefined && event.clear) {
      this.filterInput = '';
      this.switchTab(event.tab, this.filterInput);
    }
  }

  viewSeleted(event) {
    this.selectedView = event;
    this.storageService.setViewMode(this.selectedView, '_users-list');
  }

  selectedTabChange(event) {
    this.tabSelectedIndex = event.index;
    this.filterInput = '';
    this.callService = true;
    this.switchTab(this.tabSelectedIndex, this.filterInput, this.callService, 'total');
  }

  switchTab(indexTab, filterInput?, callService?, total?): void {
    this.isLoading = true;
    if (callService) {
      switch (indexTab) {
        case 0:
          //confirmed
          if (this.upending !== undefined) {
            this.upending.unsubscribe();
          }
          this.displayedColumns = ['avatar', 'fullName', 'email', 'phone', 'roles', 'actions'];
          this.originalData = [];
          this.from = 0;
          this.search = '';
          this.prepareDataTableConfirmed();
          this.getConfirmed(this.from, this.size, this.search, filterInput, total);
          this.isLoading = false;

          break;
        case 1:
          //pending
          if (this.uconfimed !== undefined) {
            this.uconfimed.unsubscribe();
          }
          this.displayedColumns = ['email', 'roles', 'actions'];
          this.originalData = [];
          this.from = 0;
          this.search = '';
          this.prepareDataTablePending();
          this.getPending(this.from, this.size, this.search, filterInput, total);
          this.isLoading = false;

          break;
      }
    } else {
      if (filterInput.length > 0) {
        this.originalData = this.copyData;
        this.originalData = this.originalData.filter((data) => {
          return JSON.stringify(data).toLowerCase().includes(filterInput.toLowerCase());
        });
        this.isLoading = false;
      } else {
        this.originalData = this.copyData;
        this.isLoading = false;
      }
    }
  }

  //NewUserComponent
  openNewUserModal() {
    this.createDialogRef = this.matDialog.open(NewUserComponent, {
      height: 'auto',
      width: this.width > 700 ? '452px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: false,
      autoFocus: false,
      data: { width: this.width }
    });
    this.createDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.tabSelectedIndex === 1) {
          this.switchTab(this.tabSelectedIndex, '', true, 'total');
        } else {
          this.tabList.selectedIndex = 1;
        }
      }
    });
  }

  check() {
    this.showEditImage = true;
  }

  resultModal(event) {
    if (event === 'updatedUser') {
      // this.switchTab(this.tabSelectedIndex, this.filterInput, true, 'total');
      this.initSubscriptions();
    }
  }

  ngOnDestroy() {}
}
