<div class="{{ className }}">
  <div *ngIf="this.data.width > 700" class="{{ className }}__wrapper-header">
    <img
      alt="Barbara IoT"
      class="{{ className }}__logo"
      src="assets/images/logos/barbara_avatar.svg" />
    <div class="{{ className }}__header">
      <h3 class="{{ className }}__title">{{ labelsCommon.contactSales }}</h3>
      <p class="{{ className }}__subtitle">
        Please fill out the form below and we will get back to you as soon as possible.
      </p>
    </div>
  </div>
  <div *ngIf="this.data.width <= 700" class="{{ className }}__wrapper-header">
    <div class="{{ className }}__header">
      <img
        alt="Barbara IoT"
        class="{{ className }}__logo"
        src="assets/images/logos/barbara_avatar.svg" />
      <h3 class="{{ className }}__title">{{ labelsCommon.contactSales }}</h3>
    </div>
    <p class="{{ className }}__subtitle">
      Please fill out the form below and we will get back to you as soon as possible.
    </p>
  </div>
  <mat-dialog-content class="{{ className }}__wrapper-body">
    <div *ngIf="objectForm" class="{{ className }}__form">
      <form [formGroup]="contactUs" novalidate>
        <div *ngFor="let input of objectForm" class="{{ className }}__form-wrapper">
          <mat-form-field *ngIf="input.id !== 'message'">
            <mat-label>{{ input.placeholder }}</mat-label>
            <input
              [autocomplete]="input.autocomplete"
              [formControlName]="input.formControl"
              [id]="input.id"
              [maxlength]="input.maxLength"
              [name]="input.name"
              [placeholder]="input.placeholder"
              [required]="input.required"
              matInput />
            <mat-error *ngIf="contactUs.get(input.formControl).hasError('required')">
              {{ input.placeholder }}{{ labelsCommon.isRequired }}
            </mat-error>
            <mat-error *ngIf="contactUs.get(input.formControl).hasError('email')">
              {{ input.placeholder }}{{ labelsCommon.isNotValid }}
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="input.id === 'message'">
            <mat-label>{{ input.placeholder }}</mat-label>
            <textarea
              [autocomplete]="input.autocomplete"
              [formControlName]="input.formControl"
              [id]="input.id"
              [maxlength]="input.maxLength"
              [name]="input.name"
              [placeholder]="input.placeholder"
              [required]="input.required"
              [rows]="rowsTextarea"
              cols="100"
              matInput>
            </textarea>
            <mat-error *ngIf="contactUs.get(input.formControl).hasError('required')">
              {{ input.placeholder }}{{ labelsCommon.isRequired }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="{{ className }}__form-wrapper checkbox">
          <mat-checkbox
            [checked]="false"
            [id]="'conditions'"
            [value]="contactUs.get('conditions').value"
            class="no-margin"
            color="primary"
            formControlName="conditions"
            labelPosition="after"
            required>
          </mat-checkbox>
          <p class="{{ className }}__form-fake-label">
            I have read Barbara’s&nbsp;<a
              class="enlace"
              href="https://www.barbara.tech/privacy-policy"
              rel="noopener"
              target="_blank"
              >Privacy Policy</a
            >&nbsp;and I agree to receive email communications related to my request
          </p>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="{{ className }}__wrapper-button">
      <button
        (click)="close()"
        color="dark-blue-inverted"
        id="{{ className }}__button-{{
          labelsCommon.close
            | lowercase
            | replace : '_' : '-'
            | replace : ' ' : '-'
            | replace : '.' : '-'
        }}"
        mat-dialog-close
        mat-flat-button>
        {{ labelsCommon.close }}
      </button>
    </div>

    <div class="{{ className }}__wrapper-button">
      <button
        (click)="submitForm()"
        [disabled]="!contactUs.valid"
        color="dark-blue"
        id="{{ className }}__button-{{
          labelsCommon.close
            | lowercase
            | replace : '_' : '-'
            | replace : ' ' : '-'
            | replace : '.' : '-'
        }}"
        mat-dialog-close
        mat-flat-button>
        <mat-icon>send</mat-icon>
        {{ labelsCommon.submit }}
      </button>
    </div>
  </mat-dialog-actions>
</div>