import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'license'
})
export class LicensePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): any {
    //console.log(value);
    switch (value) {
      case 'role_license_starter':
        return 'License Demo';
      case 'role_license_bronze':
        return 'License Pro';
      case 'role_license_silver':
        return 'License Enterprise';
      case 'role_license_gold':
        return 'License Business';
    }
  }
}