import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { commonLabels } from '../../common.labels';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-modal-error-tier-permission',
  templateUrl: './modal-error-tier-permission.component.html',
  styleUrls: ['./modal-error-tier-permission.component.scss']
})
export class ModalErrorTierPermissionComponent implements OnInit {
  className = 'modal-error-tier-permission';
  labelsCommon = commonLabels;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalErrorTierPermissionComponent>,
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    // console.log(this.data);
  }

  openContactSales(): void {
    this.dialogRef.close({ event: 'contact-sales', data: this.data });
  }

  close(): void {
    // close modal and close session
    this.dialogRef.close({ event: 'close' });
    if (this.data.errorCode === 'E063') {
      this.closeSession();
    }
  }

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/']);
  }

  public logout() {
    this.keycloak.logout();
  }
}