import { Injectable } from '@angular/core';
import { AuthService } from 'app/main/common/services/auth.service';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { StorageService } from 'app/main/common/services/storage.service';
import { UserStoreService } from 'app/main/store/user-store.service';
import { from, Observable } from 'rxjs';

import { ProfileModel } from './profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private backEndCommunicationService: BackEndCommunicationService,
    private userStore: UserStoreService
  ) {}

  public getUserProfile = (): Observable<ProfileModel> => {
    return from(
      this.backEndCommunicationService.getResource('/users/me').then((response) => {
        return response;
      })
    );
  };

  public editProfileInfo = (profileData: ProfileModel): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .putResource('/users/me', { data: profileData })
        .then(() => {
          this.authService.setUserName(profileData.fullName);
          resolve();
        })
        .catch((error) => {
          reject('Error editing user profile');
        });
    });
  };

  public uploadImage = (image): Promise<void> => {
    return new Promise((resolve, reject) => {
      const uploadData = new FormData();
      uploadData.append('file', image);
      this.backEndCommunicationService
        .postZipResource('/users/avatar', uploadData)
        .then((response: any) => {
          this.authService.setUserAvatar(response.imageName);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject('Error uploading image');
        });
    });
  };

  public updateUserPassword = (): Observable<void> => {
    return this.backEndCommunicationService.putResourceObservable('/users/passwd', {});
  };

  public deactivate2faService = (): Observable<any> => {
    return this.backEndCommunicationService.postResourceObservable('/users/deactivate2fa', {});
  };

  public activate2faService = (): Observable<any> => {
    return this.backEndCommunicationService.postResourceObservable('/users/activate2fa', {});
  };
  public getStatus2faService = (): Observable<boolean> => {
    const dataObject = {
      data: {
        email: this.authService.getEmail()
      }
    };
    return this.backEndCommunicationService.postResourceObservable(
      '/users/getStatus2fa',
      dataObject
    );
  };

  public updateSecret2faService = (password: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const dataObject = {
        data: {
          password: password,
          email: this.authService.getEmail()
        }
      };

      this.backEndCommunicationService
        .postResource('/users/generate2fasecret', dataObject)
        .then((res) => resolve(res.result))
        .catch((error) => {
          if (error.error.response.errorCode === 'ERRG2FAUC04') {
            reject('wrongPassword');
          } else {
            reject('Unkown error deactivating service');
          }
        });
    });
  };
}
