<div class="{{ className }}">
  <h2 class="modal__title">{{ labels.title | uppercase }}</h2>

  <mat-dialog-content>
    <form [formGroup]="editUserForm">
      <div class="{{ className }}__avatar">
        <input
          #file
          (change)="changeImage($event, 'avatar', userData.avatar)"
          id="modal-input-file-avatar"
          style="display: none"
          type="file" />
        <img
          *ngIf="this.userData !== undefined"
          [src]="this.userData.avatar === '' ? data.imgDefault : this.userData.avatar"
          alt="avatar"
          class="{{ className }}__avatar-img"
          id="edit-user-avatar" />
        <div *ngIf="showEditAvatar" class="{{ className }}__avatar-edit">
          <button
            (click)="file.click()"
            color="light-grey-2"
            id="edit-user-edit-avatar"
            mat-mini-fab>
            <mat-icon
              TooltipTouchGestures="auto"
              class="{{ className }}__button-icon"
              matTooltip="{{ labels.editAvatar }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              edit
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.email | uppercase }}</span>
        <p class="{{ className }}__form-field-readonly">{{ userData.email }}</p>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.role | uppercase }} *</span>
        <p *ngIf="showEditAvatar" class="{{ className }}__form-field-readonly">{{ selectName }}</p>
        <form *ngIf="!showEditAvatar" class="example-form">
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              #input1
              [formControl]="myControl"
              [matAutocomplete]="autoTrigger1"
              matInput
              type="text" />
            <mat-icon
              (click)="openPanel()"
              *ngIf="!isPanelOpen"
              class="{{ className }}__icon"
              matSuffix>
              arrow_drop_down
            </mat-icon>
            <mat-icon
              #closeInput
              (click)="closePanel($event)"
              *ngIf="isPanelOpen"
              class="{{ className }}__icon-error"
              matSuffix>
              arrow_drop_up
            </mat-icon>
            <mat-autocomplete
              #autoTrigger1="matAutocomplete"
              (closed)="closed()"
              (opened)="opened()"
              (optionSelected)="changeOptionSelected($event)"
              [autoActiveFirstOption]="true"
              [autoSelectActiveOption]="true">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.name | uppercase }} *</span>

        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #fullName
            [placeholder]="userData.firsName"
            autocomplete="off"
            formControlName="firstName"
            id="edit-user-first-name"
            matInput
            maxlength="32"
            name="firstName"
            required="true" />
          <mat-hint align="end">{{ fullName.value.length }} / 32</mat-hint>
          <mat-error *ngIf="editUserForm.controls['firstName'].hasError('required')">
            {{ labels.name + labels.isRequired }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.surname | uppercase }} *</span>
        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #lastName
            [placeholder]="userData.lastName"
            autocomplete="off"
            formControlName="lastName"
            id="edit-user-last-name"
            matInput
            maxlength="32"
            name="lastName"
            required="true" />
          <mat-hint align="end">{{ lastName.value.length }} / 32</mat-hint>
          <mat-error *ngIf="editUserForm.controls['lastName'].hasError('required')">
            {{ labels.name + labels.isRequired }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.telephone | uppercase }}</span>
        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #phone
            [placeholder]="userData.phone"
            autocomplete="off"
            formControlName="phone"
            id="edit-user-phone"
            matInput
            maxlength="32"
            name="phone" />
          <mat-hint align="end">{{ phone.value.length }} / 32</mat-hint>
          <mat-error *ngIf="editUserForm.controls['phone'].hasError('required')">
            {{ labels.telephone + labels.isRequired }}
          </mat-error>
          <mat-error *ngIf="!editUserForm.controls['phone'].hasError('required')">
            {{ labels.telephone + labels.isNotValid }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.position | uppercase }}</span>
        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #position
            [placeholder]="userData.position"
            autocomplete="off"
            formControlName="position"
            id="edit-user-position"
            matInput
            maxlength="32"
            name="position" />
          <mat-hint align="end">{{ position.value.length }} / 32</mat-hint>
          <mat-error *ngIf="editUserForm.controls['position'].hasError('required')">
            {{ labels.position + labels.isRequired }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.location | uppercase }}</span>
        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #location
            [placeholder]="userData.location"
            autocomplete="off"
            formControlName="location"
            id="edit-user-location"
            matInput
            maxlength="32"
            name="location" />
          <mat-hint align="end">{{ location.value.length }} / 32</mat-hint>
          <mat-error *ngIf="editUserForm.controls['location'].hasError('required')">
            {{ labels.location + labels.isRequired }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="{{ className }}__footer">
    <button
      class="{{ className }}__button-cancel"
      color="dark-blue-inverted"
      id="{{ className }}-button-cancel"
      mat-dialog-close
      mat-flat-button>
      {{ labels.cancel | uppercase }}
    </button>
    <button
      (click)="submitEditUserForm()"
      [disabled]="!editUserForm.valid"
      class="{{ className }}__button-create"
      color="dark-blue"
      id="{{ className }}-button-save"
      mat-flat-button>
      {{ labels.save | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
