<div class="{{ className }}">
  <div class="{{ className }}__wrapper {{ close ? 'close' : '' }}">
    @if (icon !== undefined && icon !== '') {
      <mat-icon class="material-symbols-outlined {{ className }}__icon">
        {{ icon }}
      </mat-icon>
    }
    @if (img !== undefined && img !== '') {
      <img [src]="img" alt="{{ title }} img" class="{{ className }}__icon-img" />
    }
    @if (title !== undefined && title !== '') {
      <h3
        class="{{ className }}__title {{ chip !== undefined && chip !== '' ? 'chips' : '' }} {{
          (icon !== undefined && icon !== '') || (img !== undefined && img !== '') ? 'icon' : ''
        }}">
        {{ title }}
        @if (subtitle !== undefined && subtitle !== '') {
          <span class="{{ className }}__subtitle">&nbsp;{{ subtitle }}</span>
        }
      </h3>
    }
    @if (chip !== undefined && chip !== '') {
      <div
        class="{{ className }}__chips {{
          (icon !== undefined && icon !== '') || (img !== undefined && img !== '') ? 'icon' : ''
        }}">
        <span class="{{ className }}__chip">
          {{ chip }}
        </span>
      </div>
    }
  </div>
  @if (close) {
    <button (click)="emitAction('closeModal')" class="big" mat-icon-button>
      <mat-icon class="{{ className }}__close-icon">close</mat-icon>
    </button>
  }
</div>