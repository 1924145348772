<mat-toolbar class="{{ className }}__toolbar">
  <div class="{{ className }}__container">
    <div class="{{ className }}__container-wrapper">
      <span class="{{ className }}__container-text">© {{ year }}</span>
      <a
        (click)="openUrl('https://barbara.tech', 'barbara')"
        class="{{ className }}__footer-container-link"
        matTooltip="Go to Barbara"
        matTooltipClass="custom-tooltip"
        rel="noopener">
        <img
          alt="logo barbara"
          class="{{ className }}__logo"
          src="assets/images/logos/barbara_text.svg" />
      </a>
    </div>
    <div class="{{ className }}__container-social">
      <ul class="{{ className }}__list">
        <li class="{{ className }}__list-item">
          <a
            (click)="openUrl('https://www.linkedin.com/company/barbara-edge', 'barbaralinkedin')"
            class="{{ className }}__list-item-link"
            matTooltip="Linkedin Barbara"
            matTooltipClass="custom-tooltip"
            rel="noopener">
            <img
              alt="logo linkedin"
              class="{{ className }}__list-item-icon"
              src="assets/images/logos/linkedin.svg" />
          </a>
        </li>
        <li class="{{ className }}__list-item">
          <a
            (click)="openUrl('https://twitter.com/barbara_edge_ai', 'barbaraTwitter')"
            class="{{ className }}__list-item-link"
            matTooltip="Twitter Barbara"
            matTooltipClass="custom-tooltip"
            rel="noopener">
            <img
              alt="logo twitter"
              class="{{ className }}__list-item-icon"
              src="assets/images/logos/x_twitter.svg" />
          </a>
        </li>
        <li class="{{ className }}__list-item">
          <a
            (click)="openUrl('https://www.youtube.com/@Barbara-edge', 'barbaraYoutube')"
            class="{{ className }}__list-item-link"
            matTooltip="Youtube Barbara"
            matTooltipClass="custom-tooltip"
            rel="noopener">
            <img
              alt="logo youtube"
              class="{{ className }}__list-item-icon"
              src="assets/images/logos/youtube.svg" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</mat-toolbar>