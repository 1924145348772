import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResizeWindowService } from '../../../../services/resize-window.service';

@Component({
  selector: 'modal-icon-title-chip-close',
  templateUrl: './modal-icon-title-chip-close.component.html',
  styleUrls: ['./modal-icon-title-chip-close.component.scss']
})
export class ModalIconTitleChipCloseComponent implements OnInit, OnDestroy {
  @Output() emitActions = new EventEmitter();
  @Input() chip?: string;
  @Input() close: boolean;
  @Input() icon?: string;
  @Input() img?: string;
  @Input() subtitle?: string;
  @Input() title?: string;

  className = 'modal-icon-title-chip-close';
  width: number;
  arraySubscriptions: Subscription[] = [];

  constructor(private resizeWindowService: ResizeWindowService) {}

  ngOnInit(): void {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe((value: number) => {
        this.width = value;
      })
    );
  }

  emitAction(action: string) {
    this.emitActions.emit({ action: action });
  }

  ngOnDestroy(): void {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}