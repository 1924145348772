import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { SnackMessageService } from '../../services/snackMessage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-modal-img-cropper',
  templateUrl: './modal-img-cropper.component.html',
  styleUrls: ['./modal-img-cropper.component.scss']
})
export class ModalImgCropperComponent implements OnInit, OnDestroy {
  className = 'modal-img-cropper';
  imageChangedEvent: any = '';
  croppedImage: any = '/assets/images/detail-component/ic-thing.png';
  blob: any = [];
  transform: ImageTransform = {};
  width: number;
  height: number;
  device: any;
  url: string;
  inputType: any;
  widthImage: number;
  heightImage: number;
  containWithinAspectRatio: boolean;
  maintainAspectRatio: boolean;
  cropperStaticWidth: number;
  cropperStaticHeight: number;
  roundCropper: boolean = false;
  showCropper: boolean = false;
  ratio: any;
  scale = 1;
  zoomMin = 0.15;
  zoomMax = 2;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalImgCropperComponent>,
    public matDialog: MatDialog,
    private snackMessageService: SnackMessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): any {
    this.width = this.data.width;
    this.containWithinAspectRatio = this.data.containWithinAspectRatio ?? true;
    this.maintainAspectRatio = this.data.maintainAspectRatio ?? true;
    this.widthImage = this.data.widthImage;
    this.heightImage = this.data.heightImage;
    this.cropperStaticWidth = this.data.cropperStaticWidth ?? this.widthImage / 2;
    this.cropperStaticHeight = this.data.cropperStaticHeight ?? this.heightImage / 2;
    this.roundCropper = this.data.roundCropper ?? false;
    this.ratio = this.widthImage / this.heightImage;
    this.imageChangedEvent = this.data.imageChangedEvent;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.blob = base64ToFile(this.croppedImage);
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  loadImageFailed(): void {
    this.snackMessageService.sendMessage('S010');
  }

  onCancel(): void {
    this.dialogRef.close({
      data: this.data.elementData,
      action: 'cancel'
    });
  }

  onConfirmed(): void {
    const fileToSend = new File([this.blob], `${this.data.typeImg}.png`, {
      type: 'image/png'
    });
    this.dialogRef.close({
      index: this.data.index,
      typeImg: this.data.typeImg,
      croppedImage: this.croppedImage,
      fileToSend: fileToSend,
      blob: this.blob
    });
  }

  zoomOut(): void {
    if (this.scale >= this.zoomMin + 0.1) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    } else {
      this.scale = this.zoomMin;
    }
  }

  updateSettings(event) {
    this.scale = event.value;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    if (this.scale < this.zoomMax - 0.1) {
      this.scale += 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    } else {
      this.scale = this.zoomMax;
    }
  }

  ngOnDestroy(): void {}
}
