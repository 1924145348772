import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Observable } from 'rxjs';
import { commonLabels } from '../../../common/common.labels';
import { ModalImgCropperComponent } from '../../../common/components/modal-img-cropper/modal-img-cropper.component';
import { ModalImgComponent } from '../../../common/components/modal-img/modal-img.component';
import { CommonLabels } from '../../../common/models/common.model';
import { AuthService } from '../../../common/services/auth.service';
import { ResizeWindowService } from '../../../common/services/resize-window.service';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { ConfirmDialogMaterialComponent } from '../../../devices/device-detail/components/app-info-device/components/confirm-dialog-material/confirm-dialog-material.component';
import { editUserLabels } from '../../../users/labels/users.labels';
import { EditUserLabels } from '../../../users/models/users.model';
import { UsersService } from '../../../users/services/users.service';
import { ProfileService } from '../profile.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  className = 'profile-page';
  labels: EditUserLabels = editUserLabels;
  labelsCommon: CommonLabels = commonLabels;
  userData: any = '';
  editUserForm: UntypedFormGroup;
  errorForm;
  imgDefault: string = 'assets/images/avatars/profile.jpg';
  width: number;
  avatarUri: string;
  labelModal = {
    title: 'your session will be closed.',
    title2: 'DO YOU WANT TO ACTIVATE IT?',
    subtitle:
      'To set a new password it is necessary to close your session.<br><br> Once you finish, you can log in again and continue your work normally.',
    secondSubtitle: 'Do you want to continue?',
    button: 'ok',
    cancel: commonLabels.cancel
  };
  tabsList: string[] = ['PROFILE', 'SECURITY'];
  imgDialogRef: MatDialogRef<ModalImgComponent>;
  imgCropperDialogRef: MatDialogRef<ModalImgCropperComponent>;
  blob: any[];
  status2fa = false;
  myControl = new FormControl<string>('');
  isPanelOpen = false;
  filteredOptions: Observable<string[]>;
  selected: string = '';
  selectName: string = '';
  options: string[] = [];
  roleList: object[] = [
    { _id: 'role_company_admin', name: 'Administrator' },
    { _id: 'role_company_edit_plus', name: 'Supervisor' },
    { _id: 'role_company_edit', name: 'Editor' },
    { _id: 'role_company_read', name: 'Viewer' }
  ];
  userMainRole: string;
  userRolesList: string[];

  constructor(
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private resizeWindowService: ResizeWindowService,
    private usersService: UsersService,
    private snackMessageService: SnackMessageService,
    private authService: AuthService,
    private profileService: ProfileService,
    private utilsService: UtilsService,
    private readonly keycloak: KeycloakService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.authService.getUserAvatar().subscribe((avatarUri) => {
      this.avatarUri = avatarUri ? avatarUri : 'assets/images/avatars/profile.jpg';
    });
    this.authService.getMainRol().subscribe((userMainRol) => {
      this.userMainRole = userMainRol;
    });
    this.authService.getUserRolesList().subscribe((userRolesList) => {
      this.userRolesList = userRolesList;
    });
    this.selectName = this.roleList.find((role) => role['_id'] === this.userMainRole)['name'];
    this.resizeWindowService.width$.subscribe((value: number) => {
      this.width = value;
    });
    this.profileService.getStatus2faService().subscribe({
      next: (res: any) => {
        this.status2fa = res;
      },
      error: (error: any) => {
        console.error('Error receiving 2FA Status');
      }
    });
  }

  initForm() {
    this.profileService.getUserProfile().subscribe((user) => {
      this.userData = user;
      this.selectName = this.roleList.find((role) => role['_id'] === this.userData.main_role)[
        'name'
      ];
      this.authService.setMainRol(user['main_role']);
      this.authService.setUserRolesList(user['roles']);
      this.prepareDataToForm(user);
    });
  }

  selectedTabChange(event) {}

  prepareDataToForm(value): void {
    this.editUserForm = new FormGroup({
      email: new FormControl(value.email, [Validators.required]),
      firstName: new FormControl(value.firstName, [Validators.required]),
      lastName: new FormControl(value.lastName, [Validators.required]),
      phone: new FormControl(value.phone, [Validators.pattern(/^\+?\d{9,}$/)]),
      position: new FormControl(value.position),
      location: new FormControl(value.location)
    });
    this.selectName = this.roleList.find((role) => role['_id'] === value.main_role)['name'];
  }

  submitEditUserForm() {
    this.userData.avatar = this.avatarUri;
    this.userData.email = this.editUserForm.controls['email'].value;
    this.userData.firstName = this.editUserForm.controls['firstName'].value;
    this.userData.lastName = this.editUserForm.controls['lastName'].value;
    this.userData.phone = this.editUserForm.controls['phone'].value;
    this.userData.position = this.editUserForm.controls['position'].value;
    this.userData.location = this.editUserForm.controls['location'].value;
    this.usersService.editProfileInfo(this.userData).subscribe({
      next: () => {
        this.snackMessageService.sendMessage('S032');
        this.errorForm = null;
      },
      error: (err) => {
        this.snackMessageService.readError(err);
        this.errorForm = err.error.message;
      }
    });
  }

  activateOrDeactivate2FA() {
    if (this.status2fa) {
      this.labelModal.title = 'DO YOU WANT TO DEACTIVATE IT?';
    }
    this.labelModal.subtitle =
      'To manage two factor authentication (2FA) status it is necessary to close your session. <br><br> Once you finish, you can log in again and continue your work normally.';
    this.matDialog
      .open(ConfirmDialogMaterialComponent, {
        closeOnNavigation: true,
        disableClose: true,
        autoFocus: false,
        height: 'auto',
        width: this.width > 700 ? '452px' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        data: { labels: this.labelModal, link: false }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (this.status2fa) {
            this.profileService.deactivate2faService().subscribe({
              next: (res: any) => {
                this.status2fa = res;
                this.closeSession();
              },
              error: (err: any) => {
                this.snackMessageService.readError(err);
                console.error('Error receiving 2FA Status');
              }
            });
          } else {
            this.profileService.activate2faService().subscribe({
              next: (res: any) => {
                this.status2fa = true;
                this.closeSession();
              },
              error: (error: any) => {
                console.error('Error receiving 2FA Status');
              }
            });
          }
        }
      });
  }

  updatePassword() {
    this.labelModal.subtitle =
      'To set a new password it is necessary to close your session.<br><br> Once you finish, you can log in again and continue your work normally.';
    this.matDialog
      .open(ConfirmDialogMaterialComponent, {
        closeOnNavigation: true,
        disableClose: true,
        autoFocus: false,
        height: 'auto',
        width: this.width > 700 ? '452px' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        data: { labels: this.labelModal, link: false }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.profileService.updateUserPassword().subscribe({
            next: () => {
              this.snackMessageService.sendMessage('S032');
              this.closeSession();
            },
            error: (error) => {
              this.snackMessageService.sendMessage('S039');
            }
          });
        }
      });
  }

  closeSession() {
    this.keycloak.logout();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/login']);
  }

  public changeImage(event: any, typeImg: string, src: string, index?): void {
    this.imgCropperDialogRef = this.matDialog.open(ModalImgCropperComponent, {
      height: 'auto',
      width: this.width > 1000 ? '448px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      disableClose: true,
      data: {
        width: this.width,
        index: index,
        imageChangedEvent: event,
        typeImg: typeImg,
        containWithinAspectRatio: false,
        maintainAspectRatio: true,
        widthImage: 300,
        heightImage: 300,
        cropperStaticWidth: 150,
        cropperStaticHeight: 150,
        roundCropper: true,
        title: this.labelsCommon.cropper + ' ' + typeImg,
        button1Label: this.labelsCommon.upload,
        button2Label: this.labelsCommon.cancel
      }
    });
    this.imgCropperDialogRef.afterClosed().subscribe(async (result) => {
      if (result.action === 'cancel') {
        this.blob = [];
        return;
      } else {
        const filesToUpload = result.croppedImage;

        this.updateAvatar(filesToUpload, result.typeImg, result.fileToSend.lastModified);
      }
    });
  }

  updateAvatar(filesToUpload, name, date) {
    const type = filesToUpload.split('/')[1].split(';')[0];
    const avatar = this.utilsService.dataURLtoFile(filesToUpload, `${date}${name}.${type}`);
    this.usersService
      .uploadImage(avatar)
      .then((res: any) => {
        this.avatarUri = res;
        this.snackMessageService.sendMessage('S031');
      })
      .catch((err) => {
        this.snackMessageService.readError(err);
      });
  }

  ngOnDestroy() {}
}
