<div class="{{ className }}">
  <h2 class="modal__title">{{ labels.title | uppercase }}</h2>

  <mat-dialog-content>
    <form [formGroup]="userForm" name="usersForm" novalidate>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.emailLabel | uppercase }}</span>
        <mat-form-field appearance="outline" class="onlyplaceholder">
          <input
            #mail
            formControlName="email"
            id="new-user-email"
            matInput
            maxlength="64"
            name="mail"
            placeholder="{{ labels.emailPlaceholder + ' *' }}"
            required="true" />
          <mat-hint align="end">{{ mail.value.length }} / 64</mat-hint>
          <mat-error *ngIf="userForm.controls['email'].hasError('required')">
            {{ labels.emailLabel + labels.isRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              userForm.controls['email'].hasError('pattern') &&
              !userForm.controls['email'].hasError('required')
            ">
            {{ labels.emailLabel + labels.isNotValid }}
          </mat-error>
        </mat-form-field>
        <mat-error> {{ errorForm }}</mat-error>
      </div>
      <div class="{{ className }}__form-field-wrapper">
        <span class="{{ className }}__form-field-label">{{ labels.role | uppercase }}</span>
        <form class="example-form">
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              #input1
              [formControl]="myControl"
              [matAutocomplete]="autoTrigger1"
              matInput
              type="text" />
            <mat-icon
              (click)="openPanel()"
              *ngIf="!isPanelOpen"
              class="{{ className }}__icon"
              matSuffix>
              arrow_drop_down
            </mat-icon>
            <mat-icon
              #closeInput
              (click)="closePanel($event)"
              *ngIf="isPanelOpen"
              class="{{ className }}__icon-error"
              matSuffix>
              arrow_drop_up
            </mat-icon>
            <mat-autocomplete
              #autoTrigger1="matAutocomplete"
              (closed)="closed()"
              (opened)="opened()"
              (optionSelected)="changeOptionSelected($event)"
              [autoActiveFirstOption]="true"
              [autoSelectActiveOption]="true">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
      <!--     -->
      <!--        <span class="{{ className }}__form-field-label">-->
      <!--          {{ labels.permissionsLabel | uppercase }}-->
      <!--        </span>-->
      <!--        <p class="{{ className }}__form-field-checkbox-separator">-->
      <!--          <mat-checkbox-->
      <!--            class="{{ className }}__form-field-checkbox"-->
      <!--            color="primary"-->
      <!--            labelPosition="after"-->
      <!--            formControlName="userMgmt">-->
      <!--            {{ labels.manageUsers | uppercase }}-->
      <!--          </mat-checkbox>-->
      <!--        </p>-->
      <!--        <p class="{{ className }}__form-field-checkbox-separator">-->
      <!--          <mat-checkbox-->
      <!--            class="{{ className }}__form-field-checkbox"-->
      <!--            color="primary"-->
      <!--            labelPosition="after"-->
      <!--            formControlName="deplyMgmt">-->
      <!--            {{ labels.manageGroups | uppercase }}-->
      <!--          </mat-checkbox>-->
      <!--        </p>-->
      <!--      </div>-->
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="{{ className }}__footer">
    <button
      class="{{ className }}__button-cancel"
      color="dark-blue-inverted"
      id="{{ className }}-button-cancel"
      mat-dialog-close
      mat-flat-button>
      {{ labels.cancel | uppercase }}
    </button>
    <button
      (click)="submitUserForm()"
      [disabled]="!userForm.valid"
      class="{{ className }}__button-create"
      color="dark-blue"
      id="{{ className }}-button-send-invitation"
      mat-flat-button>
      {{ data.width < 650 ? (labels.send | uppercase) : (labels.sendInvitation | uppercase) }}
    </button>
  </mat-dialog-actions>
</div>
