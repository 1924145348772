import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  user = new BehaviorSubject({} as any);
  token = new BehaviorSubject('');

  setUserData(user: any) {
    this.user.next(user);
  }

  setUserToken(token: string) {
    this.token.next(token);
  }

  getUserToken(): BehaviorSubject<string> {
    return this.token;
  }

  getUserData(): BehaviorSubject<any> {
    return this.user;
  }
}
