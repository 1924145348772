<div class="{{ className }}">
  <div class="{{ className }}__container-title">
    <div class="{{ className }}__container-header-img">
      <input
        #file
        (change)="changeImage($event, 'avatar', avatarUri)"
        id="company-input-file-avatar"
        style="display: none"
        type="file" />
      <img
        #imagenDevice
        (blur)="check()"
        [src]="avatarUri ?? imgDefault"
        alt="device"
        class="{{ className }}__picture" />
      <div class="{{ className }}__container-button">
        <button
          (click)="file.click()"
          TooltipTouchGestures="on"
          class="{{ className }}__button"
          color="dark-blue"
          mat-mini-fab
          matTooltip="Edit"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <mat-icon class="{{ className }}__button-icon">edit</mat-icon>
        </button>
      </div>
    </div>
    <div class="{{ className }}__group-company">
      <h2 class="{{ className }}__name-company">
        {{ dataCompany?.name }}
      </h2>
      <p class="{{ className }}__license">
        {{ dataCompany?.main_role | license }}
      </p>
    </div>
  </div>
  <div class="{{ className }}__header">
    <h3 class="title-page">
      <p *ngIf="!isLoading" class="showing">
        {{ labels.showing }} {{ originalData.length }} {{ labels.of | lowercase }}
        {{ totalUsers }}
        {{ totalUsers === 1 ? (labels.user | lowercase) : (labels.users | lowercase) }}
      </p>
    </h3>
    <div *ngIf="width >= 600" class="{{ className }}__group-button">
      <button
        (click)="openNewUserModal()"
        [disabled]="userMainRole !== 'role_company_admin'"
        class="{{ className }}__button-new"
        color="orange"
        mat-raised-button>
        {{ labels.newUser | uppercase }}
      </button>
    </div>
  </div>
  <app-filter-select-view
    (emitClearFilter)="clearFilter($event)"
    (emitInputFilter)="inputFilter($event)"
    (emitSelectedView)="viewSeleted($event)"
    [filterInput]="filterInput"
    [filterPlaceholderLabel]="filterPlaceholderLabel"
    [isLoading]="isLoading"
    [minCharacterToFilter]="minCharacterToFilter"
    [selectedView]="selectedView"
    [showGrid]="showGrid"
    [showMap]="showMap"
    [showTable]="showTable"
    [tabSelectedIndex]="tabSelectedIndex"
    [view]="tabSelectedIndex === 0 ? 'users-confirmed-list' : 'users-pending-list'"
    [width]="width"></app-filter-select-view>
  <div class="{{ className }}__container-tabs">
    <mat-tab-group #tabList (selectedTabChange)="selectedTabChange($event)" class="no-padding">
      <mat-tab *ngFor="let item of tabsList" [label]="item">
        <tabs-user
          (emitGetAllPaginator)="getAllPaginator($event)"
          (emitResultModal)="resultModal($event)"
          (emitTypeTab)="
            switchTab($event.indexTab, $event.filterInput, $event.callService, $event.total)
          "
          [dataTable]="dataTable"
          [displayedColumns]="displayedColumns"
          [imgDefault]="imgDefault"
          [isLoading]="isLoading"
          [originalData]="originalData"
          [selectedView]="selectedView"
          [tabSelectedIndex]="tabSelectedIndex"
          [width]="width"></tabs-user>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="width < 600" class="{{ className }}__group-button">
    <button
      (click)="openNewUserModal()"
      TooltipTouchGestures="on"
      [disabled]="userMainRole !== 'role_company_admin'"
      class="mobile"
      color="orange"
      mat-mini-fab
      matTooltip="{{ labels.newUser }}"
      matTooltipClass="custom-tooltip"
      matTooltipPosition="above">
      <mat-icon class="">add</mat-icon>
    </button>
  </div>
</div>
