import { NgModule } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../common/shared.module';

import { SearchPipe } from 'app/pipes/search.pipe';
import { SortByPipe } from 'app/pipes/sort-by.pipe';

import { ChangelogComponent } from './changelog.component';
import { ChangelogFormComponent } from './components/changelog-form/changelog-form.component';

@NgModule({
  declarations: [ChangelogComponent, ChangelogFormComponent, SearchPipe, SortByPipe],
  imports: [
    MatCardModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatGridListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ]
})
export class ChangelogModule {}
