import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeLogFormModel, ChangeLogLabels } from '../../models/changelog.model';
import { changeLogLabels } from '../../changelog.labels';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-changelog-form',
  templateUrl: './changelog-form.component.html',
  styleUrls: ['./changelog-form.component.scss']
})
export class ChangelogFormComponent implements OnInit {
  @Input() typeForm: string;
  @Input() dataFormEdit: ChangeLogFormModel;
  @Output() cancelButtonEvent = new EventEmitter<boolean>();
  @Output() addButtonEvent = new EventEmitter<object>();
  @Output() saveButtonEvent = new EventEmitter<object>();

  labels: ChangeLogLabels = changeLogLabels;
  className = 'app-changelog-form';
  changeLogNewForm: UntypedFormGroup;
  changeLogEditForm: UntypedFormGroup;

  dataNew: ChangeLogFormModel = {
    version: '',
    date: '',
    changes: []
  };
  dataEdit: ChangeLogFormModel;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.typeForm === 'new') {
      this.initFormChangelog();
    } else {
      this.dataEdit = this.dataFormEdit;
      this.loadForm();
    }
  }

  initFormChangelog(): void {
    this.changeLogNewForm = this.formBuilder.group({
      version: [''],
      date: [''],
      changes: ['']
    });
  }

  loadForm(): void {
    this.prepareDataToForm(this.dataFormEdit);
  }

  onCancelForm(value: boolean) {
    this.cancelButtonEvent.emit(value);
  }

  onAddChangelog() {
    this.prepareDataToSend(this.changeLogNewForm);
    this.addButtonEvent.emit(this.dataNew);
  }

  onSaveChangelog() {
    this.prepareDataToSend(this.changeLogEditForm);
    this.saveButtonEvent.emit(this.dataFormEdit);
  }

  prepareDataToSend(value) {
    let logs = [];
    if (this.typeForm === 'new') {
      this.dataNew.version = value.controls['version'].value;
      this.dataNew.date = moment(value.controls['date'].value._d).format('YYYY-MM-DD');
      logs = value.controls['changes'].value.split('\n');
      this.dataNew.changes = [];
      logs.forEach((log) => {
        this.dataNew.changes.push(log);
      });
    } else {
      this.dataEdit.version = value.controls['version'].value;
      this.dataEdit.date = moment(value.controls['date'].value._d).format('YYYY-MM-DD');
      logs = value.controls['changes'].value.split('\n');
      this.dataEdit.changes = [];
      logs.forEach((log) => {
        this.dataEdit.changes.push(log);
      });
    }
  }

  prepareDataToForm(value) {
    let tempDate = new Date(value.date);
    let logs = value.changes.join('\n');

    this.changeLogEditForm = this.formBuilder.group({
      version: [value.version],
      date: [moment(tempDate)],
      changes: [logs]
    });
  }
}
