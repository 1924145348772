<div class="containerForm" id="register">
  <img class="imageForm" id="imageRegister" src="assets/images/backgrounds/login.svg" />
  <div class="register-form-wrapper">
    <div class="animateForm" id="animateFormRegister">
      <div>
        <div class="logo">
          <img src="assets/images/logos/barbara_avatar.svg" />
        </div>
        <div class="title">
          <h2 class="h2Form">Welcome to Barbara Panel</h2>
        </div>
        <hr class="hrForm" />

        <h3 class="h3Form">Create Account</h3>

        <div class="{{ className }}__form">
          <form [formGroup]="registerForm" name="registerForm" novalidate>
            <div class="{{ className }}__form-field-wrapper">
              <span class="labelForm">First name</span>
              <mat-form-field appearance="outline">
                <input
                  formControlName="firstName"
                  matInput
                  onfocus="this.removeAttribute('readonly');"
                  readonly />
                <mat-icon class="secondary-text" matSuffix>account_box</mat-icon>
                <mat-error> First Name is required</mat-error>
              </mat-form-field>
            </div>
            <div class="{{ className }}__form-field-wrapper">
              <span class="labelForm">Last name</span>
              <mat-form-field appearance="outline">
                <input
                  formControlName="lastName"
                  matInput
                  onfocus="this.removeAttribute('readonly');"
                  readonly />
                <mat-icon class="secondary-text" matSuffix>account_box</mat-icon>
                <mat-error> Last Name is required</mat-error>
              </mat-form-field>
            </div>
            <div class="{{ className }}__form-field-wrapper">
              <span class="labelForm">Password</span>
              <mat-form-field appearance="outline">
                <input
                  [type]="hidePassword ? 'password' : 'text'"
                  formControlName="password"
                  matInput
                  onfocus="this.removeAttribute('readonly');"
                  readonly
                  type="password" />
                <mat-icon (click)="hidePassword = !hidePassword" matSuffix>{{
                  hidePassword ? 'visibility' : 'visibility_off'
                }}</mat-icon>
                <mat-error> Password is required</mat-error>
              </mat-form-field>
            </div>
            <div class="{{ className }}__form-field-wrapper">
              <span class="labelForm">Password confirm</span>
              <mat-form-field appearance="outline">
                <input
                  [type]="hidePassword ? 'password' : 'text'"
                  formControlName="passwordConfirm"
                  matInput
                  onfocus="this.removeAttribute('readonly');"
                  readonly />
                <mat-icon (click)="hidePassword = !hidePassword" matSuffix>{{
                  hidePassword ? 'visibility' : 'visibility_off'
                }}</mat-icon>
                <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                  Password confirmation is required
                </mat-error>
                <mat-error
                  *ngIf="
                    !registerForm.get('passwordConfirm').hasError('required') &&
                    registerForm.get('passwordConfirm').hasError('passwordsNotMatching')
                  ">
                  Passwords must match
                </mat-error>
              </mat-form-field>
            </div>
            <div class="{{ className }}__form-field-wrapper--center">
              <div class="{{ className }}__container-button">
                <button
                  (click)="openDialog()"
                  aria-label="See terms and conditions"
                  class="terms-condition-button"
                  color="accent"
                  id="terms-condition"
                  mat-flat-button>
                  {{ 'Accept Terms & Conditions' | uppercase }}
                </button>
              </div>
              <div class="{{ className }}__container-button">
                <button
                  (click)="submitRegistrationForm()"
                  [disabled]="registerForm.invalid || !termsAccepted"
                  aria-label="CREATE ACCOUNT"
                  color="accent"
                  id="submitRegister"
                  mat-flat-button>
                  CREATE ACCOUNT
                </button>
              </div>
            </div>
            <div *ngIf="registerErrorMessage" class="errorMessage">
              {{ registerErrorMessage }}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
