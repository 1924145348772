import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/main/common/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.authService.isAdmin().subscribe((isAdmin) => {
      if (isAdmin === false) {
        this.router.navigate(['/devices/devices-list']);
        return false;
      } else {
        return true;
      }
    });
  }
}
