import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { DeviceListService } from '../../../devices/services/device-list.service';
import { commonLabels } from '../../common.labels';
import { AuthService } from '../../services/auth.service';
import { SnackMessageService } from '../../services/snackMessage.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-modal-contact-sales',
  templateUrl: './modal-contact-sales.component.html',
  styleUrls: ['./modal-contact-sales.component.scss']
})
export class ModalContactSalesComponent implements OnInit {
  className = 'modal-contact-sales';
  labelsCommon = commonLabels;
  contactUs: UntypedFormGroup;
  objectForm: any;
  userProfile: any;
  errorsArrayTextarea: { rows: number; codeError: string; textMessage: string }[];
  rowsTextarea: number = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalContactSalesComponent>,
    private storageService: StorageService,
    private authService: AuthService,
    private snackMessageService: SnackMessageService,
    private deviceListService: DeviceListService,
    private router: Router,
    private readonly keycloak: KeycloakService
  ) {
    this.objectForm = [
      {
        formControl: 'firstname',
        placeholder: 'First Name',
        id: 'firstname',
        name: 'firstname',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'lastname',
        placeholder: 'Last Name',
        id: 'lastname',
        name: 'lastname',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'email',
        placeholder: 'Email',
        id: 'email',
        name: 'email',
        required: true,
        type: 'email',
        maxLength: 64,
        autocomplete: 'off'
      },
      {
        formControl: 'phone',
        placeholder: 'Phone Number',
        id: 'phone',
        name: 'phone',
        required: true,
        type: 'tel',
        maxLength: 32,
        autocomplete: 'off'
      },
      {
        formControl: 'company',
        placeholder: 'Company',
        id: 'company',
        name: 'company',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'message',
        placeholder: 'Your message',
        id: 'message',
        name: 'message',
        required: true,
        type: 'text',
        maxLength: 300,
        autocomplete: 'off'
      }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.errorsArrayTextarea = [
      {
        rows: this.data.width > 700 ? 3 : 5,
        codeError: 'E113',
        textMessage: `Our current plan has hit its node limit, so we're ready to level up!

Could you send over info on the available upgrade options?`
      },
      {
        rows: this.data.width > 700 ? 4 : 6,
        codeError: 'E114',
        textMessage: `We're looking to unlock the full potential of our license and need the "build images" functionality.

Could you advise on upgrade options to bridge this gap?`
      },
      {
        rows: this.data.width > 700 ? 4 : 6,
        codeError: 'E115',
        textMessage: `Our team has outgrown our current user limit, so we're looking to expand our access with a license upgrade.

Can you share details on the available options?`
      },
      {
        rows: this.data.width > 700 ? 5 : 6,
        codeError: 'E063',
        textMessage: `Our previous license has reached its expiration date, but we're eager to keep the platform humming.

Could you provide information on renewal options to ensure seamless continuation?`
      },
      {
        rows: this.data.width > 700 ? 5 : 7,
        codeError: 'E104',
        textMessage: `Our current license is missing some key functionalities that are crucial for our operations.

Could you advise on upgrade options to bridge this gap and unlock the full potential of the platform?`
      }
    ];
    this.createForm();

    let token = await this.keycloak.getToken();
    if (token !== undefined) {
      let tokenJwt: any = jwtDecode(token);
      let message = this.getTextTextareaMessage(this.data.errorCode);
      this.rowsTextarea = message.rows;
      this.userProfile = await this.keycloak.loadUserProfile();
      this.contactUs.patchValue({
        firstname: this.userProfile.firstName,
        lastname: this.userProfile.lastName,
        email: this.userProfile.email,
        phone: this.userProfile.attributes.phone[0],
        company: tokenJwt.companyName,
        message: message.textMessage
      });
    }
  }

  getTextTextareaMessage(codeError: string) {
    return this.errorsArrayTextarea.find((error: any) => error.codeError === codeError);
  }

  createForm() {
    this.contactUs = new UntypedFormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      conditions: new FormControl(false, [Validators.required])
    });
  }

  submitForm() {
    if (this.contactUs.valid) {
      this.deviceListService.sendFormContactSales(this.contactUs.value).subscribe({
        next: (response) => {
          this.close();
          this.contactUs.reset();
        },
        error: (error) => {
          console.error(error);
          this.close();
        }
      });
    }
  }

  close(): void {
    // close modal and close session
    this.dialogRef.close({ event: 'close' });
    if (this.data.errorCode === 'E063') {
      this.closeSession();
    }
  }

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/']);
  }

  public logout() {
    this.keycloak.logout();
  }
}