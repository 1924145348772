import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageSnackBarService {
  dictionary = [
    {
      messageName: 'modelVersionAlreadyExistsError',
      snackbarmessage: 'Error: The version already exists.',
      snackbarcode: 'S001'
    },
    {
      messageName: 'versionRemovedOK',
      snackbarmessage: 'Version succesfully deleted.',
      snackbarcode: 'S002'
    },
    {
      messageName: 'auditLogStatusError',
      snackbarmessage: 'Error: [API ERROR MESSAGE]',
      snackbarcode: 'S003'
    },
    {
      messageName: 'configSavedOK',
      snackbarmessage: 'Config succesfully saved.',
      snackbarcode: 'S004'
    },
    {
      messageName: 'batchSavedOK',
      snackbarmessage: 'Batch succesfully saved.',
      snackbarcode: 'S005'
    },
    {
      messageName: 'batchLaunchedOK',
      snackbarmessage: 'Batch succesfully launched.',
      snackbarcode: 'S006'
    },
    {
      messageName: 'batchSavedAndLaunchedOK',
      snackbarmessage: 'Batch succesfully saved and launched.',
      snackbarcode: 'S007'
    },
    {
      messageName: 'customConfigSavedOK',
      snackbarmessage: 'Custom config succesfully saved.',
      snackbarcode: 'S008'
    },
    {
      messageName: 'incorrectImageTypeError',
      snackbarmessage:
        'Error: Please, select a supported image file (jpg, jpeg, png, tiff or bmp).',
      snackbarcode: 'S009'
    },
    {
      messageName: 'imageLoadError',
      snackbarmessage: 'Error loading image.',
      snackbarcode: 'S010'
    },
    {
      messageName: 'discardErrorMessageOK',
      snackbarmessage: 'Message succesfully discarded.',
      snackbarcode: 'S011'
    },
    {
      messageName: 'cancelCommandOK',
      snackbarmessage: 'Command succesfully canceled.',
      snackbarcode: 'S012'
    },
    {
      messageName: 'telemetryTimeUpdateOK',
      snackbarmessage: 'Telemetry frequency succesfully updated.',
      snackbarcode: 'S013'
    },
    {
      messageName: 'versionAlreadyExistsError',
      snackbarmessage: 'Error: The version already exists.',
      snackbarcode: 'S014'
    },
    {
      messageName: 'passwordRegeneratedOK',
      snackbarmessage: 'Password succesfully updated.',
      snackbarcode: 'S015'
    },
    {
      messageName: 'fileSizeError',
      snackbarmessage:
        'Error: The uploaded file exceeds the maximum size limit of 10MB. Please try uploading a smaller file.',
      snackbarcode: 'S016'
    },
    {
      messageName: 'standaloneModeDisableSent',
      snackbarmessage: 'Disable Standalone Mode command succesfully sent.',
      snackbarcode: 'S017'
    },
    {
      messageName: 'standaloneModeEnableSent',
      snackbarmessage: 'Enable Standalone Mode command succesfully sent.',
      snackbarcode: 'S018'
    },
    {
      messageName: 'advancedModalError',
      snackbarmessage: 'Error: [API ERROR MESSAGE]',
      snackbarcode: 'S019'
    },
    {
      messageName: 'imageDeletedOK',
      snackbarmessage: 'Image succesfully deleted.',
      snackbarcode: 'S020'
    },
    {
      messageName: 'logDisabledOK',
      snackbarmessage: 'Follow Logs succesfully disabled.',
      snackbarcode: 'S021'
    },
    {
      messageName: 'userUnauthorizedError',
      snackbarmessage: 'Error: [API ERROR MESSAGE]',
      snackbarcode: 'S022'
    },
    {
      messageName: 'singleDeviceRegisteredOK',
      snackbarmessage: 'Node succesfully added.',
      snackbarcode: 'S027'
    },
    {
      messageName: 'clipboardCopyOK',
      snackbarmessage: 'Copied to clipboard',
      snackbarcode: 'S029'
    },
    {
      messageName: 'groupDeletionOK',
      snackbarmessage: 'Group succesfully deleted.',
      snackbarcode: 'S030'
    },
    {
      messageName: 'imageUpdateOK',
      snackbarmessage: 'Image succesfully updated.',
      snackbarcode: 'S031'
    },
    {
      messageName: 'userInfoUpdatedOK',
      snackbarmessage: 'Profile succesfully updated.',
      snackbarcode: 'S032'
    },
    {
      messageName: 'userDeletedOK',
      snackbarmessage: 'User succesfully deleted.',
      snackbarcode: 'S033'
    },
    {
      messageName: 'invitationCanceledOK',
      snackbarmessage: 'Invitation canceled successfully.',
      snackbarcode: 'S034'
    },

    {
      messageName: 'invitationSentAgainOK',
      snackbarmessage: 'Invitation sent again successfully.',
      snackbarcode: 'S035'
    },
    {
      messageName: 'applicationCreatedOK',
      snackbarmessage: 'Application created successfully.',
      snackbarcode: 'S036'
    },
    {
      messageName: 'modelCreatedOK',
      snackbarmessage: 'Model created successfully.',
      snackbarcode: 'S037'
    },
    {
      messageName: 'userPasswordUpdatedOK',
      snackbarmessage: 'User password changed successfully.',
      snackbarcode: 'S038'
    },
    {
      messageName: 'userPasswordUpdateError',
      snackbarmessage: 'Error: password change failed.',
      snackbarcode: 'S039'
    },
    {
      messageName: 'userRegisterOK',
      snackbarmessage: 'User registered successfully.',
      snackbarcode: 'S040'
    },
    {
      messageName: 'invitationSentOK',
      snackbarmessage: 'Invitation sent successfully.',
      snackbarcode: 'S041'
    },
    {
      messageName: 'companyDeletedOK',
      snackbarmessage: 'Company deleted successfully.',
      snackbarcode: 'S042'
    },
    {
      messageName: 'companyCreatedOK',
      snackbarmessage: 'Company created successfully.',
      snackbarcode: 'S043'
    },
    {
      messageName: 'companyUpdatedOK',
      snackbarmessage: 'Company info updated successfully.',
      snackbarcode: 'S044'
    },
    {
      messageName: 'familyCreatedOK',
      snackbarmessage: 'Family created successfully.',
      snackbarcode: 'S045'
    },
    {
      messageName: 'familyDeletedOK',
      snackbarmessage: 'Family deleted successfully.',
      snackbarcode: 'S046'
    },
    {
      messageName: 'agentVersionCreatedOK',
      snackbarmessage: 'Agent version created successfully.',
      snackbarcode: 'S047'
    },
    {
      messageName: 'incrementalOTACompiledOK',
      snackbarmessage: 'Incremental OTA compiled successfully.',
      snackbarcode: 'S048'
    },
    {
      messageName: 'osVersionCreatedOK',
      snackbarmessage: 'OS version created successfully.',
      snackbarcode: 'S049'
    },
    {
      messageName: 'supportedDeviceCreatedOK',
      snackbarmessage: 'Supported device created successfully.',
      snackbarcode: 'S050'
    },
    {
      messageName: 'supportedDeviceUpdatedOK',
      snackbarmessage: 'Supported device updated successfully.',
      snackbarcode: 'S051'
    },
    {
      messageName: 'supportedDeviceDeletedOK',
      snackbarmessage: 'Supported device deleted successfully.',
      snackbarcode: 'S052'
    },
    {
      messageName: 'tierCreatedOK',
      snackbarmessage: 'Tier created successfully.',
      snackbarcode: 'S053'
    },
    {
      messageName: 'tierDeletedOK',
      snackbarmessage: 'Tier deleted successfully.',
      snackbarcode: 'S054'
    },
    {
      messageName: 'tierUpdatedOK',
      snackbarmessage: 'Tier updated successfully.',
      snackbarcode: 'S055'
    },
    {
      messageName: 'versionCreatedOK',
      snackbarmessage: 'Version created successfully.',
      snackbarcode: 'S056'
    },
    {
      messageName: 'modelRemovedOK',
      snackbarmessage: 'Model deleted successfully.',
      snackbarcode: 'S057'
    },
    {
      messageName: 'applicationRemovedOK',
      snackbarmessage: 'Application deleted succesfully.',
      snackbarcode: 'S058'
    },
    {
      messageName: 'disableVPNSentOK',
      snackbarmessage: 'Disable VPN was sent successfully.',
      snackbarcode: 'S059'
    },
    {
      messageName: 'enableVPNSentOK',
      snackbarmessage: 'Enable VPN was sent successfully',
      snackbarcode: 'S060'
    },

    {
      messageName: 'activateVPNSentOK',
      snackbarmessage: 'Activate VPN was sent successfully',
      snackbarcode: 'S061'
    },

    {
      messageName: 'rebootSentOK',
      snackbarmessage: 'Node Reboot sent successfully.',
      snackbarcode: 'S062'
    },

    {
      messageName: 'updateOsSentOK',
      snackbarmessage: 'OS Update sent successfully',
      snackbarcode: 'S063'
    },
    {
      messageName: 'updateNodeManagerSentOK',
      snackbarmessage: 'Node Manager Update sent successfully',
      snackbarcode: 'S064'
    }
  ];
}
