import { Injectable } from '@angular/core';
import { AppsModel } from 'app/main/apps/models/apps.model';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { SnackMessageService } from 'app/main/common/services/snackMessage.service';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { AppsService } from '../../apps/services/apps.service';

import { StorageService } from '../../common/services/storage.service';
import { UtilsService } from '../../common/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceListService {
  onSelectedDevicesChanged: BehaviorSubject<any>;

  onFilterChanged: Subject<any>;

  registryList: AppsModel[];
  selectedDevices: string[];

  constructor(
    private backEndCommunicationService: BackEndCommunicationService,
    private storageService: StorageService,
    private snackMessageService: SnackMessageService,
    private utilsService: UtilsService,
    private appsService: AppsService
  ) {
    this.onSelectedDevicesChanged = new BehaviorSubject([]);

    this.onFilterChanged = new Subject();
    this.selectedDevices = this.storageService.retrieveListSelection();
  }

  public deselectDevices(): void {
    this.selectedDevices = [];
    this.onSelectedDevicesChanged.next(this.selectedDevices);
  }

  public selectDevice(id: string): void {
    if (this.selectedDevices.findIndex((d) => d === id) === -1) {
      this.selectedDevices.push(id);
      this.onSelectedDevicesChanged.next(this.selectedDevices);
    }
  }

  getLabels(step) {
    switch (step) {
      case 1:
        return 'Select application';

      case 2:
        return 'add secrets';

      case 3:
        return 'Configure application';
    }
  }

  public toggleSelectedDevice(id: string): void {
    if (this.selectedDevices.length > 0) {
      const index = this.selectedDevices.indexOf(id);

      if (index !== -1) {
        this.selectedDevices.splice(index, 1);
        this.onSelectedDevicesChanged.next(this.selectedDevices);
        return;
      }
    }
    this.selectedDevices.push(id);

    this.onSelectedDevicesChanged.next(this.selectedDevices);
  }

  copyToClipBoard(value) {
    from(navigator.clipboard.writeText(value)).subscribe((res) => {
      this.snackMessageService.sendMessage('S029');
    });
  }

  sendFormContactSales(data: any): any {
    return this.backEndCommunicationService.postResourceObservable('/contact', data);
  }
}
