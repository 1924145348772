<!--<div className="{{className}}__container">-->
<!--  <h3>Device Id : {{id}}</h3>-->
<!--  <button-->
<!--    (click)="copyToClipBoard(id)"-->
<!--    TooltipTouchGestures="on"-->
<!--    class="{{ className }}__group-info-button"-->
<!--    mat-icon-button-->
<!--    matTooltip="Copy to clipboard"-->
<!--    matTooltipClass="custom-tooltip"-->
<!--    matTooltipPosition="below">-->
<!--    <mat-icon class="material-icons-round {{ className }}__icon-copy">-->
<!--      content_copy-->
<!--    </mat-icon>-->
<!--  </button>-->
<!--</div>-->

<div class="{{className}}__container">
  <div class="{{className}}__container-img">
    <img alt="Barbara" class="{{className}}__container-img-width" src="/assets/images/logos/barbara_text.svg">
  </div>

  <div class="{{className}}__container-text">
    <p>Your Barbara ID is: </p>
    <h3>{{id}}</h3>
    <button (click)="copyToClipBoard(id)" mat-flat-button>Copy ID</button>

  </div>
  <div class="{{className}}__container-rectangle">
    <div *ngFor="let rectangle of listRectangle" [style.background-color]="rectangle" class="{{className}}__rectangle">
    </div>
  </div>
</div>