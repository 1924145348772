import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'app/main/common/services/auth.service';
import { SnackMessageService } from 'app/main/common/services/snackMessage.service';

import { SortCriteria } from 'app/pipes/sort-by.pipe';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalConfirmConfigurableComponent } from '../common/components/modal-confirm-configurable/modal-confirm-configurable.component';
import { changeLogLabels } from './changelog.labels';
import { ChangeLogFormModel, ChangeLogLabels, ChangeLogModel } from './models/changelog.model';
import { ChangeLogService } from './services/changelog.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit, OnDestroy {
  className = 'app-changelog';
  labels: ChangeLogLabels = changeLogLabels;
  isAdmin;
  showFormNew = false;
  isEditing = false;
  isloading = false;

  filterText = '';
  typeForm = '';
  showChanges = '';
  showFormEdit = '';

  changeLogDataService: ChangeLogModel[];
  dataFormEdit: ChangeLogFormModel;
  innerWidth: number;
  totalChangeLog: number;
  isMobile = false;

  public criteria: SortCriteria;
  public data: ChangeLogModel[];

  constructor(
    private authService: AuthService,
    private changelogService: ChangeLogService,
    private snackMessageService: SnackMessageService,
    public matDialog: MatDialog
  ) {
    // For pipe sort
    this.criteria = {
      property: 'date',
      descending: true
    };

    this.data = this.changeLogDataService;
  }

  ngOnInit() {
    this.authService.isAdmin().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
    this.isloading = true;
    this.initChangeLog();

    this.checkWidth();
  }

  initChangeLog() {
    this.changelogService.getChangeLog().subscribe({
      next: (response: ChangeLogModel[]) => {
        this.isloading = false;
        this.changeLogDataService = response;
        this.totalChangeLog = response.length;
      },
      error: (error) => {
        this.isloading = false;
        //console.log(error);
      }
    });
  }

  deleteSearch(deleteSearch?: boolean) {
    if (this.filterText.length === 0 || deleteSearch) {
      this.filterText = '';
    }
  }

  onShowNewForm(): void {
    this.isEditing = false;
    this.typeForm = 'new';
    this.showFormNew = !this.showFormNew;
    this.showFormEdit = '';
  }

  onShowChanges(id: string): void {
    this.showChanges = id;
  }

  onHideChanges(): void {
    this.showChanges = '';
    this.showFormEdit = '';
  }

  onShowEditForm(id: string): void {
    this.isloading = true;
    this.changelogService.getChangeLogById(id).subscribe({
      next: (response: ChangeLogFormModel) => {
        this.dataFormEdit = {
          version: response.version,
          date: response.date,
          changes: response.changes
        };
        this.isloading = false;
        this.showFormNew = false;
        this.typeForm = 'edit';
        this.isEditing = true;
        this.showChanges = id;
        this.showFormEdit = id;
      },
      error: (error) => {
        this.isloading = false;
        this.snackMessageService.readError(error);
      }
    });
  }

  onDeleteChangelog(data: object): void {
    this.mostrarDialogo(data);
  }

  addItem(data: object) {
    this.isloading = true;
    this.changelogService.postChangeLog(data).subscribe({
      next: () => {
        this.initChangeLog();
      },
      error: (error) => {
        this.isloading = false;
        this.snackMessageService.readError(error);
      }
    });
    this.showFormEdit = '';
    this.showFormNew = false;
  }

  saveItem(_id: string, data: object) {
    this.isloading = true;
    this.changelogService.putChangeLog(_id, data).subscribe({
      next: () => {
        this.initChangeLog();
      },
      error: (error) => {
        this.isloading = false;
        this.snackMessageService.readError(error);
      }
    });

    this.showFormEdit = '';
    this.showFormNew = false;
  }

  mostrarDialogo(data: object): void {
    this.matDialog
      .open(ModalConfirmConfigurableComponent, {
        height: 'auto',
        width: window.innerWidth > 700 ? '400px' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        disableClose: false,
        autoFocus: false,
        data: {
          titleMessage: 'Are you sure you want to delete the changelog?',
          contentMessage: 'It will delete the changelog and all its related data from the system.',
          button1Color: 'primary',
          button1Message: 'Ok',
          button1Action: 'deleteChangeLog'
        }
      })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this.isloading = true;
          this.changelogService.deleteChangeLog(data).subscribe({
            next: () => {
              this.initChangeLog();
            },
            error: (error) => {
              this.isloading = false;
              this.snackMessageService.readError(error);
            }
          });
        }
      });
  }

  checkWidth(width?: number): boolean {
    this.innerWidth = width || window.innerWidth;
    if (this.innerWidth < 1024) {
      return (this.isMobile = true);
    } else {
      return (this.isMobile = false);
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.checkWidth(this.innerWidth);
  }

  ngOnDestroy(): void {}
}