import { commonLabels } from 'app/main/common/common.labels';
import {
  EditUserLabels,
  NewUserLabels,
  UsersListLabels,
  UsersViewTableLabels
} from '../models/users.model';

export const usersListLabels: UsersListLabels = {
  of: commonLabels.of,
  showing: commonLabels.showing,
  user: commonLabels.user,
  users: commonLabels.users,
  title: 'Users',
  newUser: 'New User',
  filterPlaceholderLabel: commonLabels.filter,
  viewList: commonLabels.viewList,
  viewGrid: commonLabels.viewGrid,
  result: commonLabels.result,
  confirmed: 'Confirmed',
  pending: 'Pending',
  noData: "WE DIDN'T FIND ANY USER THAT SATISFIES YOUR SEARCH CRITERIA"
};
export const usersViewTableLabels: UsersViewTableLabels = {
  avatar: 'Avatar',
  name: commonLabels.name,
  email: 'Email',
  phone: 'Phone',
  code: 'Code',
  roles: 'Roles',
  manageDevices: 'Manage devices',
  manageGroup: 'Manage groups',
  manageUsers: 'Manage users',
  actions: commonLabels.actions,
  delete: commonLabels.delete,
  edit: commonLabels.edit,
  resend: 'Resend invitation',
  noData: "WE DIDN'T FIND ANY USER THAT SATISFIES YOUR SEARCH CRITERIA"
};
export const newUserLabels: NewUserLabels = {
  cancel: commonLabels.cancel,
  emailLabel: 'Email',
  emailPlaceholder: 'Enter user mail',
  isNotValid: commonLabels.isNotValid,
  isRequired: commonLabels.isRequired,
  manageGroups: 'Manage groups',
  manageUsers: 'Manage users',
  nameLabel: 'Name',
  namePlaceholder: 'Enter user name',
  permissionsLabel: 'Permissions',
  role: 'Role',
  send: 'Send',
  sendInvitation: 'Send invitation',
  title: 'New user  '
};
export const editUserLabels: EditUserLabels = {
  cancel: commonLabels.cancel,
  cropAvatarCancel: commonLabels.cancel,
  cropAvatarTitle: 'Crop avatar',
  cropAvatarUpload: commonLabels.upload,
  cropZoomIn: 'ZoomIn',
  cropZoomOut: 'ZoomOut',
  editAvatar: 'Edit avatar',
  editAvatarCancel: commonLabels.cancel,
  editAvatarDelete: commonLabels.delete,
  editAvatarTitle: 'User avatar',
  editAvatarUpload: commonLabels.upload,
  email: 'Email',
  isNotValid: commonLabels.isNotValid,
  isRequired: commonLabels.isRequired,
  location: 'Location',
  name: 'First name',
  position: 'Position',
  role: 'Role',
  save: commonLabels.save,
  surname: 'Last name',
  telephone: 'Telephone',
  title: 'Edit user'
};
