<div class="{{ className }}">
  <div class="{{ className }}__wrapper">
    <div *ngIf="isLoading" class="{{ className }}__spinner">
      <mat-spinner color="dark-blue"></mat-spinner>
    </div>
    <div *ngIf="selectedView === 'table' && !isLoading" class="{{ className }}__tabla">
      <table-dinamic
        (emitActionsOut)="actionsTable($event)"
        (emitGetAllPaginator)="getAllPaginator($event)"
        [dataTable]="dataTable"
        [displayedColumns]="displayedColumns"
        [isloading]="isLoading"
        [noDataLabel]="labels.noData"
        [originalData]="originalData"
        [view]="
          tabSelectedIndex === 0 ? '-users-confirmed-list' : '-users-pending-list'
        "
        [width]="width"></table-dinamic>
    </div>

  </div>
</div>