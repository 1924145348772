<div class="{{ className }}">
  <div class="{{ className }}__main-container">
    <div class="{{ className }}__header">
      <h3 class="title-page">
        {{ labels.title }}
        <p *ngIf="!isloading" class="showing">
          {{ labels.showing }} {{ (changeLogDataService | search : filterText)?.length }}
          {{ labels.of | lowercase }}
          {{ totalChangeLog }}
          {{ totalChangeLog === 1 ? labels.changeLog : labels.changeLogs }}
        </p>
      </h3>
      <div
        *ngIf="!isMobile; else mobileButtons"
        [ngClass]="isAdmin ? ' app-changelog__col-buttons--admin' : ''"
        class="{{ className }}__col-buttons">
        <button
          (click)="onShowNewForm()"
          *ngIf="isAdmin"
          aria-label="Add ChangeLog"
          color="orange"
          mat-flat-button
          matTooltip="Add ChangeLog"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <mat-icon>add</mat-icon>
          {{ labels.title }}
        </button>
      </div>
    </div>
    <div class="{{ className }}__search-form">
      <div [ngClass]="isAdmin ? ' app-changelog__col--admin' : ''" class="{{ className }}__col">
        <mat-form-field appearance="outline" class="type-filter">
          <mat-icon matPrefix>search</mat-icon>
          <input
            (keyup)="deleteSearch()"
            [(ngModel)]="filterText"
            class="{{ className }}__input"
            matInput
            placeholder="{{ labels.filter }}"
            type="text" />
          <button
            (click)="deleteSearch(true)"
            *ngIf="filterText"
            aria-label="Clear"
            mat-icon-button
            matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <ng-template #mobileButtons>
        <button
          (click)="onShowNewForm()"
          *ngIf="isAdmin"
          [matTooltip]="labels.newLog"
          class="{{ className }}__mobile-button"
          color="orange"
          mat-fab
          matTooltipClass="custom-tooltip">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </div>
    <app-changelog-form
      (addButtonEvent)="addItem($event)"
      (cancelButtonEvent)="this.showFormNew = $event"
      *ngIf="showFormNew && !showFormEdit"
      [typeForm]="typeForm"
      class="form-new"></app-changelog-form>

    <div class="{{ className }}__search-table">
      <div class="{{ className }}__search-table-theader">
        <div class="{{ className }}__search-table-theader-th">{{ labels.version }}</div>
        <div class="{{ className }}__search-table-theader-th">{{ labels.date }}</div>
      </div>
      <mat-progress-spinner *ngIf="isloading" class="custom-loading" mode="indeterminate">
      </mat-progress-spinner>
      <div
        *ngIf="
          (changeLogDataService | search : filterText)?.length !== 0 && !isloading;
          else nodata
        "
        class="{{ className }}__search-table-tbody">
        <div
          *ngFor="let changelog of changeLogDataService | search : filterText | sortBy : criteria"
          class="{{ className }}__search-table-tbody-tr">
          <ng-template #editForm></ng-template>
          <div class="{{ className }}__search-table-tbody-td">{{ changelog.version }}</div>
          <div class="{{ className }}__search-table-tbody-td">
            {{ changelog.date | date : 'longDate' }}
          </div>
          <div
            class="{{ className }}__search-table-tbody-td {{
              className
            }}__search-table-tbody-td-collapsable">
            <button
              (click)="onShowEditForm(changelog._id)"
              *ngIf="showFormEdit !== changelog._id && isAdmin"
              class="{{ className }}__search-table-tbody-td-button"
              mat-icon-button
              matTooltip="{{ labels.edit }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              (click)="onDeleteChangelog(changelog)"
              *ngIf="isAdmin"
              class="{{ className }}__search-table-tbody-td-button"
              mat-icon-button
              matTooltip="{{ labels.delete }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button
              (click)="onShowChanges(changelog._id)"
              *ngIf="showChanges !== changelog._id"
              class="{{ className }}__search-table-tbody-td-button"
              mat-icon-button
              matTooltip="{{ labels.showChanges }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <button
              (click)="onHideChanges()"
              *ngIf="showChanges === changelog._id"
              class="{{ className }}__search-table-tbody-td-button"
              mat-icon-button
              matTooltip="{{ labels.hideChanges }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
          </div>
          <div
            *ngIf="showChanges === changelog._id"
            class="{{ className }}__search-table-tbody-td {{
              className
            }}__search-table-tbody-td-actions">
            <ul>
              <li *ngFor="let change of changelog.changes">{{ change }}</li>
            </ul>
          </div>
          <app-changelog-form
            (cancelButtonEvent)="
              this.isEditing = $event; this.showChanges = ''; this.showFormEdit = ''
            "
            (saveButtonEvent)="saveItem(changelog._id, $event)"
            *ngIf="showFormEdit === changelog._id && !showFormNew"
            [dataFormEdit]="dataFormEdit"
            [typeForm]="typeForm"
            class="form-edit"></app-changelog-form>
        </div>
      </div>
      <ng-template #nodata>
        <p
          *ngIf="!isloading && changeLogDataService?.length === 0; else noResultsSearch"
          class="{{ className }}__search-table-body-nodata">
          {{ labels.noChangelogs }}
        </p>
      </ng-template>
      <ng-template #noResultsSearch>
        <p *ngIf="!isloading" class="{{ className }}__search-table-body-nodata">
          {{ labels.noResults }}
        </p>
      </ng-template>
    </div>
  </div>
</div>
