import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'architectures'
})
export class ArchitecturesPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    const architectures = [];
    value.forEach((element) => {
      switch (element) {
        case 'x86_64':
          architectures.push(' AMD64');
          break;
        case 'aarch64':
          architectures.push(' ARM64');
          break;
        case 'armv7':
          architectures.push(' ARMv7');
          break;
      }
    });

    return architectures;
  }
}
