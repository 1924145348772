import { Injectable } from '@angular/core';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private backEndCommunicationService: BackEndCommunicationService) {}

  public registerUser(
    password: string,
    firstName: string,
    phone: string,
    location: string,
    position: string,
    registrationToken: string,
    lastName: string
    //tier: string,
  ): Observable<void> {
    const sendObject = {
      data: {
        password: password,
        firstName: firstName,
        phone: phone,
        location: location,
        position: position,
        registrationToken: registrationToken,
        lastName
        //tier: tier,
      }
    };
    return this.backEndCommunicationService.postResourceObservable('/users', sendObject);
  }
}
