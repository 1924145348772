<div *ngIf="typeForm === 'new'; else edit" class="{{ className }}">
  <form [formGroup]="changeLogNewForm" class="{{ className }}__form">
    <mat-form-field class="{{ className }}__field-50">
      <mat-label>{{ labels.version }}</mat-label>
      <input
        #version
        autocomplete="off"
        formControlName="version"
        matInput
        name="version"
        required="true" />
    </mat-form-field>
    <mat-form-field class="{{ className }}__field-50">
      <mat-label>{{ labels.date }}</mat-label>
      <input
        #date
        [matDatepicker]="date1"
        autocomplete="off"
        formControlName="date"
        matInput
        name="date"
        required="true" />
      <mat-datepicker-toggle [for]="date1" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #date1></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ labels.changes }}</mat-label>
      <textarea
        #changes
        autocomplete="off"
        cols="100"
        formControlName="changes"
        matInput
        name="changes"
        required="true"
        rows="5"></textarea>
    </mat-form-field>
    <div class="{{ className }}__wrapper-group-buttons">
      <div class="{{ className }}__wrapper-button">
        <button
          (click)="onCancelForm(false)"
          color="dark-blue-inverted"
          id="{{ className }}-button-cancel"
          mat-flat-button>
          {{ labels.cancel | uppercase }}
        </button>
      </div>
      <div class="{{ className }}__wrapper-button">
        <button
          (click)="onAddChangelog()"
          [disabled]="
            version.value.length === 0 || date.value.length === 0 || changes.value.length === 0
          "
          [ngClass]="
            version.value.length === 0 || date.value.length === 0 || changes.value.length === 0
              ? ' app-changelog-form__button--primary-disabled'
              : ''
          "
          color="dark-blue"
          id="{{ className }}-button-add"
          mat-flat-button>
          {{ labels.add | uppercase }}
        </button>
      </div>
    </div>
  </form>
</div>
<ng-template #edit>
  <form [formGroup]="changeLogEditForm" class="{{ className }}__form {{ className }}__form-edit">
    <mat-form-field class="{{ className }}__field-50">
      <mat-label>{{ labels.version }}</mat-label>
      <input
        #version
        autocomplete="off"
        formControlName="version"
        matInput
        name="version"
        required="true" />
    </mat-form-field>
    <mat-form-field class="{{ className }}__field-50">
      <mat-label>{{ labels.date }}</mat-label>
      <input
        #date
        [matDatepicker]="date1"
        autocomplete="off"
        formControlName="date"
        matInput
        name="date"
        required="true" />
      <mat-datepicker-toggle [for]="date1" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #date1></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ labels.changes }}</mat-label>
      <textarea
        #changes
        autocomplete="off"
        cols="100"
        formControlName="changes"
        matInput
        name="changes"
        required="true"
        rows="5"></textarea>
    </mat-form-field>
    <div class="{{ className }}__wrapper-group-buttons">
      <div class="{{ className }}__wrapper-button">
        <button
          (click)="onCancelForm(false)"
          color="dark-blue-inverted"
          id="{{ className }}-button-cancel"
          mat-flat-button>
          {{ labels.cancel | uppercase }}
        </button>
      </div>
      <div class="{{ className }}__wrapper-button">
        <button
          (click)="onSaveChangelog()"
          [disabled]="
            version.value.length === 0 || date.value.length === 0 || changes.value.length === 0
          "
          [ngClass]="
            version.value.length === 0 || date.value.length === 0 || changes.value.length === 0
              ? ' app-changelog-form__button--primary-disabled'
              : ''
          "
          color="dark-blue"
          id="{{ className }}-button-save"
          mat-flat-button>
          {{ labels.save | uppercase }}
        </button>
      </div>
    </div>
  </form>
</ng-template>
