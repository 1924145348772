import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { newUserLabels } from '../../labels/users.labels';
import { NewUserLabels } from '../../models/users.model';
import { UsersService } from '../../services/users.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  @Input() width: number;
  @ViewChild('input1', { read: MatAutocompleteTrigger }) input1: MatAutocompleteTrigger;
  className = 'new-user';
  labels: NewUserLabels = newUserLabels;
  userForm: UntypedFormGroup;
  autocomplete: MatAutocompleteTrigger;
  matAutocomplete: any;
  myControl = new FormControl<string>('');
  isPanelOpen = false;
  filteredOptions: Observable<string[]>;
  selected: string = 'role_company_read';
  selectName: string = 'Viewer';
  options: string[] = [];
  roleList: object[] = [
    { _id: 'role_company_admin', name: 'Administrator' },
    { _id: 'role_company_edit_plus', name: 'Supervisor' },
    { _id: 'role_company_edit', name: 'Editor' },
    { _id: 'role_company_read', name: 'Viewer' }
  ];
  errorForm;
  userData;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewUserComponent>,
    public matDialog: MatDialog,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private snackMessageService: SnackMessageService
  ) {}

  ngOnInit(): any {
    this.userForm = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]
      ],
      main_role: [this.selected, Validators.required]
    });
    setTimeout(() => {
      this.autocomplete = this.input1;
      this.matAutocomplete = this.input1.autocomplete;
      this.roleList.forEach((role) => {
        this.options.push(role['name']);
      });
      this.selected = this.selectOption(
        'Viewer',
        'name',
        this.roleList,
        this.matAutocomplete,
        this.selectName,
        this.myControl
      );
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          let idx = -1;
          if (value.length === 0) {
            this.matAutocomplete.options.forEach((item, index) => {
              if (item.value === this.selectName) {
                idx = index;
              }
            });
            if (idx !== -1) {
              this.autocomplete.autocomplete._keyManager.setActiveItem(idx);
              this.matAutocomplete.options.get(idx).select();
            }
          }

          return this.utilsService._filter(value || '', this.options);
        })
      );
    }, 100);
  }

  selectOption(selected, mode, list, matAutocomplete: MatAutocomplete, selectName, myControl) {
    const select = list.find((option) => option[mode] === selected);
    selectName = select.name;
    myControl.setValue(selectName);
    this.selectOptionSelected(matAutocomplete, selectName);
    return select._id;
  }

  selectOptionSelected(matAutocomplete: MatAutocomplete, selectNameDinamic: string) {
    setTimeout(() => {
      matAutocomplete.options.forEach((option) => {
        if (option.value === selectNameDinamic) {
          option.select();
        }
      });
    }, 200);
  }

  changeOptionSelected(event?) {
    [this.selectName, this.selected] = this.utilsService.changeOptionSelected(event, this.roleList);
    this.userForm.controls['main_role'].setValue(this.selected);
  }

  closed() {
    [this.isPanelOpen, this.myControl] = this.utilsService.closed(
      this.isPanelOpen,
      this.myControl,
      this.selectName
    );
  }

  opened() {
    [this.isPanelOpen, this.myControl] = this.utilsService.opened(this.isPanelOpen, this.myControl);
  }

  openPanel() {
    this.selectName = this.utilsService.openPanel(
      this.autocomplete,
      this.roleList,
      this.selected,
      this.matAutocomplete
    );
  }

  closePanel(event) {
    this.utilsService.closePanel(event, this.autocomplete);
  }

  public submitUserForm(): void {
    this.userData = {
      email: this.userForm.value.email,
      main_role: this.userForm.value.main_role
    };
    this.usersService.newUserObs(this.userData).subscribe(
      (res) => {
        this.errorForm = null;
        this.userForm.reset();
        this.dialogRef.close({ success: true });
      },
      (error: any) => {
        this.errorForm = error.error.response.errorMessage;
      }
    );
  }

  ngOnDestroy(): void {}
}