import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../common/shared.module';
//New
import { UsersListComponent } from './components/users-list.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { UserTabsComponent } from './components/tabs-user/tabs-user.component';
import { VpnDevicesComponent } from './components/vpn-devices/vpn-devices.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

const routes: Routes = [
  {
    path: 'userlist',
    component: UsersListComponent
  },
  {
    path: 'vpn',
    component: VpnDevicesComponent
  }
];

@NgModule({
  declarations: [UsersListComponent, NewUserComponent, UserTabsComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatMenuModule,
    MatAutocompleteModule
  ]
})
export class UsersModule {}
