import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  className = 'app-footer';
  @Input() isDesktop: boolean;
  year = new Date().getFullYear();

  openUrl(url: string, target?: string) {
    window.open(url, target !== undefined ? target : '_blank');
  }
}
