import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalContactSalesComponent } from '../main/common/components/modal-contact-sales/modal-contact-sales.component';
import { ModalErrorTierPermissionComponent } from '../main/common/components/modal-error-tier-permission/modal-error-tier-permission.component';
import { ResizeWindowService } from '../main/common/services/resize-window.service';

@Injectable()
export class InterceptorErrorsService implements HttpInterceptor {
  width: number;

  constructor(public matDialog: MatDialog, private resizeWindowService: ResizeWindowService) {
    this.width = document.documentElement.clientWidth;
    this.resizeWindowService.width$.subscribe((value: number) => {
      this.width = value;

      this.resizeWindowService.waitForFinalEvent(
        () => {
          this.width = value;
        },
        50,
        'loadingModalErrors'
      );
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          /* de momento sólo se quiere capturar errores, no respuestas
          if (event instanceof HttpResponse) { console.log('Respuesta capturada:', event); }
          */
        },
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            //console.error('Error capturado:', error);
            if (error.status === 402 && this.width !== undefined) {
              this.matDialog.closeAll();
              const dialogError402 = this.matDialog.open(ModalErrorTierPermissionComponent, {
                width: this.width > 700 ? '400px' : '100vw',
                height: 'auto',
                maxWidth: this.width > 700 ? '600px' : '100vw',
                maxHeight: this.width > 700 ? 'calc(100vh - 40px)' : '100vh',
                panelClass: 'white',
                backdropClass: 'backdropBackground',
                disableClose: true,
                autoFocus: false,
                data: {
                  errorStatus: error.status,
                  errorCode: error.error.response.errorCode
                }
              });
              dialogError402.afterClosed().subscribe(async (result) => {
                if (result !== undefined) {
                  if (result.event === 'contact-sales') {
                    console.log('width', this.width);
                    const dialogContactSales = this.matDialog.open(ModalContactSalesComponent, {
                      width: this.width > 700 ? '600px' : '100vw',
                      height: 'auto',
                      maxWidth: this.width > 700 ? '600px' : '100vw',
                      maxHeight: this.width > 700 ? 'calc(100vh - 40px)' : '100vh',
                      panelClass: 'white',
                      backdropClass: 'backdropBackground',
                      disableClose: true,
                      autoFocus: false,
                      data: { errorCode: result.data.errorCode, width: this.width }
                    });
                  }
                }
              });
            }
            if (error.status === 403 && this.width !== undefined) {
              console.log('width', this.width);
              this.matDialog.closeAll();
              const dialogError403 = this.matDialog.open(ModalErrorTierPermissionComponent, {
                width: this.width > 700 ? '400px' : '100vw',
                height: 'auto',
                maxWidth: this.width > 700 ? '600px' : '100vw',
                maxHeight: this.width > 700 ? 'calc(100vh - 40px)' : '100vh',
                panelClass: 'white',
                backdropClass: 'backdropBackground',
                disableClose: true,
                autoFocus: false,
                data: {
                  errorStatus: error.status,
                  errorCode: error.error.response.errorCode
                }
              });
            }
          }
        }
      })
    );
  }
}