<div
  *ngIf="
    userLoggeIn && url !== '/devices/detail/logs' && url !== '/login' && url !== '/devices/readfile'
  "
  class="header">
  <app-header [isAdmin]="isAdmin" [isDesktop]="isDesktop" [userRoles]="userRoles"></app-header>
</div>
<div [ngClass]="url === '/devices/detail/logs' ? 'big' : ''" class="content">
  <!-- PROGRESS BAR -->
  <!--  <fuse-progress-bar></fuse-progress-bar>-->
  <!-- / PROGRESS BAR -->
  <router-outlet></router-outlet>
</div>
<div
  *ngIf="
    userLoggeIn && url !== '/devices/detail/logs' && url !== '/login' && url !== '/devices/readfile'
  "
  class="footer">
  <app-footer></app-footer>
</div>
