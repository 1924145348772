import { Component, HostListener, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from './main/common/services/storage.service';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './main/common/services/auth.service';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DeviceStoreService } from './main/store/device-store.service';
import { KeycloakService } from 'keycloak-angular';
import { UsersService } from './main/users/services/users.service';
import { MoveCardService } from './main/devices/services/move-card.service';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navigation: any;
  enviroment: any = environment;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  userLoggeIn = false;
  isAdmin: boolean;
  userRoles: string[];
  userMainRole: string;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  isDesktop = false;
  url: string;
  public isLoggedIn = false;
  public userProfile: any | null = null;

  constructor(
    private idle: Idle,
    private router: Router,
    private storageService: StorageService,
    private keepalive: Keepalive,
    private gtmService: GoogleTagManagerService,
    private moveCardService: MoveCardService,
    private authService: AuthService,
    private usersService: UsersService,
    private deviceStoreService: DeviceStoreService,
    private readonly keycloak: KeycloakService
  ) {
    this.moveCardService.getCustomData();
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.storageService.removelocalStorageData();
      this.userLoggeIn = false;
      this.authService.setUserLoggedIn(false);
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.router.navigate(['/']);
      window.location.reload();
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = `You've gone idle!`;
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();

    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', this.enviroment.analytics_id, {
        page_path: event.urlAfterRedirects
      });
    });
  }

  reset(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  async ngOnInit(): Promise<void> {
    this.url = window.location.pathname;
    this.userLoggeIn = await this.keycloak.isLoggedIn();
    //this.userRoles = this.keycloak.getUserRoles();
    this.isAdmin = await this.keycloak.isUserInRole('role_superadmin');
    await this.keycloak.updateToken(5);

    if (this.userLoggeIn) {
      this.authService.setUserLoggedIn(true);
      this.userProfile = await this.keycloak.loadUserProfile();
      this.userLoggeIn = true;
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
      if (this.isAdmin) {
        this.authService.setIsAdmin(true);
        this.router.navigate(['/admin/companies']);
      } else {
        this.authService.setIsAdmin(false);
        this.usersService.getUserProfile().subscribe((user) => {
          this.authService.setMainRol(user['main_role']);
          this.authService.setUserRolesList(user['roles']);
        });
        this.authService.setUserName(this.userProfile.firstName + ' ' + this.userProfile.lastName);
        this.authService.setEmail(this.userProfile.email);
        this.authService.setUserLoggedIn(true);
        this.authService.setUserAvatar(this.userProfile.attributes.avatar[0]);

        if (
          window.location.pathname === '/devices' ||
          window.location.pathname.startsWith('/admin')
        ) {
          this.router.navigate(['devices/devices-list']);
        } else {
          this.router.navigate([window.location.pathname]);
        }
      }
    } else {
      this.authService.setUserLoggedIn(false);
      this.userLoggeIn = false;
      this.idle.stop();
      this.timedOut = true;
    }

    this.authService.getUserRolesList().subscribe((userRoleList) => {
      this.userRoles = userRoleList;
    });

    this.checkWidth(this.width);

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
        this.url = item.url.split('?')[0];

        this.deviceStoreService.getSelectedDevice().subscribe((deviceId) => {
          if (deviceId === '') {
            const id = this.storageService.getDeviceId();
            if (id) {
              this.deviceStoreService.selectedDevice(id);
            }
          }
        });
      }
    });
  }

  checkWidth(width: number): void {
    this.isDesktop = false;
    if (width >= 1024) {
      this.isDesktop = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.checkWidth(this.width);
  }

  public login() {
    this.keycloak.login();
  }
}
