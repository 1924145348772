import { commonLabels } from 'app/main/common/common.labels';

import { ChangeLogLabels } from './models/changelog.model';

export const changeLogLabels: ChangeLogLabels = {
  changeLog: commonLabels.changeLog,
  changeLogs: commonLabels.changeLogs,
  of: commonLabels.of,
  showing: commonLabels.showing,
  title: 'Changelog',
  filter: 'Filter',
  search: commonLabels.search,
  newLog: 'New Changelog',
  version: 'Version',
  date: 'Date',
  changes: 'Changes',
  add: commonLabels.add,
  cancel: commonLabels.cancel,
  showChanges: 'Show changes',
  hideChanges: 'Hide changes',
  edit: commonLabels.edit,
  save: commonLabels.save,
  delete: commonLabels.delete,
  confirmDeleteTitle: 'Confirm delete Changelog',
  confirmDeteteText: 'Are you sure you want to delete Changelog?',
  confirmDeleteButton: 'YES, I WANT TO DELETE CHANGELOG',
  noChangelogs: `WE DIDN\'T FIND ANY CHANGELOG THAT SATISFIES YOUR SEARCH CRITERIA`,
  noResults: `WE DIDN\'T FIND ANY CHANGELOG THAT SATISFIES YOUR SEARCH CRITERIA`
};
