<div class="{{ className }}">
  <h2 class="modal__title">{{ data.title | uppercase }}</h2>

  <mat-dialog-content>
    <div class="{{ className }}__cropper">
      <image-cropper
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        [imageChangedEvent]="imageChangedEvent"
        format="png"
        output="base64"
        [aspectRatio]="ratio"
        [transform]="transform"
        [maintainAspectRatio]="maintainAspectRatio"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [resizeToWidth]="widthImage"
        [resizeToHeight]="heightImage"
        [cropperStaticWidth]="cropperStaticWidth"
        [cropperStaticHeight]="cropperStaticHeight"
        [roundCropper]="roundCropper"
        alignImage="center"
        [hideResizeSquares]="true"
        [disabled]="false"
        [allowMoveImage]="true"
        class="{{ className }}__image-cropper"></image-cropper>
    </div>
    <div class="{{ className }}__zoom-icons">
      <button
        (click)="zoomOut()"
        class="{{ className }}__zoom-button"
        color="white"
        mat-icon-button>
        <mat-icon class="{{ className }}__zoom-icon material-icon-outlined">zoom_out</mat-icon>
      </button>
      <mat-slider
        class="{{ className }}__zoom-slider"
        step="0.1"
        [max]="zoomMax"
        [min]="zoomMin"
        [discrete]="true"
        #ngSlider>
        <input
          matSliderThumb
          [value]="scale"
          (change)="
            updateSettings({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })
          "
          #ngSliderThumb="matSliderThumb" />
      </mat-slider>
      <button (click)="zoomIn()" class="{{ className }}__zoom-button" color="white" mat-icon-button>
        <mat-icon class="{{ className }}__zoom-icon material-icon-outlined">zoom_in</mat-icon>
      </button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="{{ className }}__footer">
    <button
      (click)="onCancel()"
      id="{{ className }}-button-cancel"
      color="dark-blue-inverted"
      mat-flat-button
      mat-dialog-close>
      {{ data.button2Label | uppercase }}
    </button>
    <button
      (click)="onConfirmed()"
      id="{{ className }}-button-create"
      color="dark-blue"
      mat-flat-button>
      {{ data.button1Label | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
