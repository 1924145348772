<div class="{{ className }}__filter-wrapper">
  <div class="{{ className }}__filter">
    <mat-form-field class="type-filter">
      <mat-label>{{ filterPlaceholderLabel }}</mat-label>
      <mat-icon matPrefix>{{ typeSearch ? 'search' : 'filter_alt' }}</mat-icon>
      <input
        (keyup)="onInputFilter($event)"
        [(ngModel)]="inputFilter"
        autocomplete="off"
        class="{{ className }}__input"
        id="{{ view ? view : className }}_input-filter-search"
        matInput
        type="search" />
      <button
        (click)="onClearFilter({ tab: tabSelectedIndex, clear: true })"
        *ngIf="inputFilter"
        aria-label="Clear"
        id="{{ view ? view : className }}_button-clear"
        mat-icon-button
        matSuffix>
        <mat-icon>highlight_off</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>