import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { SnackMessageService } from 'app/main/common/services/snackMessage.service';

import { TermsConditionsComponent } from '../profile/terms-conditions/terms-conditions.component';
import { RegisterService } from './register.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  className: string = 'app-register';
  registerForm: UntypedFormGroup;
  registerErrorMessage: string;
  hidePassword: boolean;
  termsAccepted: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private router: Router,
    private snackMessageService: SnackMessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      phone: [''],
      location: [''],
      position: [''],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, this.confirmPasswordValidator]]
    });

    this.hidePassword = true;
    this.termsAccepted = false;
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(TermsConditionsComponent, {
      height: 'auto',
      width: '98vw',
      maxHeight: '98vh',
      maxWidth: '98vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.termsAccepted = result;
    });
  }

  public submitRegistrationForm(): void {
    this.registerService
      .registerUser(
        this.registerForm.value.password,
        this.registerForm.value.firstName,
        this.registerForm.value.phone,
        this.registerForm.value.location,
        this.registerForm.value.position,
        this.route.snapshot.queryParams['token'],
        this.registerForm.value.lastName
        //this.registerForm.value.tiers,
      )
      .subscribe({
        next: () => {
          this.registerErrorMessage = null;
          this.snackMessageService.sendMessage('S040');
          this.router.navigate(['/']);
        },
        error: (error) => {
          this.snackMessageService.readError(error);
          this.registerErrorMessage = error.error.response.errorMessage;
        }
      });
  }

  ngOnDestroy(): void {}

  private confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (!control.parent || !control) {
      return null;
    }
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
      return null;
    }
    if (passwordConfirm.value === '') {
      return null;
    }
    if (password.value === passwordConfirm.value) {
      return null;
    }
    return { passwordsNotMatching: true };
  };
}
