<div class="{{ className }}">
  <div class="modal__title">{{ labels.title }}</div>
  <div class="modal__subtitle">{{ labels.title2  }}</div>
  <mat-dialog-content class="{{ className }}_container">
    <div [innerHTML]="labels.subtitle" class="{{ className }}__text"></div>
    <div [innerHTML]="labels.secondSubtitle" class="{{ className }}__text">

      <span (click)="openDocu()" *ngIf="link" class="{{ className }}__fake-link">here.</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      (click)="closeModal()"
      *ngIf="labels.cancel.length > 0"
      class="{{ className }}__button-cancel"
      color="dark-blue-inverted"
      id="{{ className }}-button-cancel"
      mat-flat-button>
      {{ labels.cancel  }}
    </button>
    <button
      (click)="aceptModal()"
      class="{{ className }}__button"
      color="dark-blue"
      id="{{ className }}-button-accept"
      mat-flat-button>
      {{ labels.button  }}
    </button>
  </mat-dialog-actions>
</div>
