import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { commonLabels } from '../../../common/common.labels';
import { ModalImgCropperComponent } from '../../../common/components/modal-img-cropper/modal-img-cropper.component';
import { CommonLabels } from '../../../common/models/common.model';
import { AuthService } from '../../../common/services/auth.service';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { editUserLabels } from '../../labels/users.labels';
import { EditUserLabels } from '../../models/users.model';
import { UsersService } from '../../services/users.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  className = 'edit-user';
  labels: EditUserLabels = editUserLabels;
  labelsCommon: CommonLabels = commonLabels;
  @ViewChild('input1', { read: MatAutocompleteTrigger }) input1: MatAutocompleteTrigger;
  userData: any;
  editUserForm: UntypedFormGroup;
  autocomplete: MatAutocompleteTrigger;
  matAutocomplete: any;
  myControl = new FormControl<string>('');
  isPanelOpen = false;
  filteredOptions: Observable<string[]>;
  selected: string;
  selectName: string = '';
  options: string[] = [];
  roleList: object[] = [
    { _id: 'role_company_admin', name: 'Administrator' },
    { _id: 'role_company_edit_plus', name: 'Supervisor' },
    { _id: 'role_company_edit', name: 'Editor' },
    { _id: 'role_company_read', name: 'Viewer' }
  ];
  errorForm;
  width: number;
  showEditAvatar: boolean = false;
  imgCropperDialogRef: MatDialogRef<ModalImgCropperComponent>;
  blob: any[];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditUserComponent>,
    public matDialog: MatDialog,
    private usersService: UsersService,
    private authService: AuthService,
    private utilsService: UtilsService,
    private snackMessageService: SnackMessageService
  ) {}

  ngOnInit() {
    this.userData = this.data.elementData;
    this.showEditAvatar = this.userData.email === this.authService.getEmail();
    this.width = this.data.width;
    this.prepareDataToForm(this.data.elementData);
  }

  prepareDataToForm(value): void {
    this.editUserForm = this.fb.group({
      email: [value.email],
      main_role: [value.main_role],
      firstName: [value.firstName, [Validators.required]],
      lastName: [value.lastName, [Validators.required]],
      phone: [value.phone, [Validators.pattern(/^\+?\d{9,}$/)]],
      position: [value.position],
      location: [value.location]
    });
    if (!this.showEditAvatar) {
      setTimeout(() => {
        this.autocomplete = this.input1;
        this.matAutocomplete = this.input1.autocomplete;
        this.roleList.forEach((role) => {
          this.options.push(role['name']);
        });
        this.selectName = this.roleList.find((role) => role['_id'] === value.main_role)['name'];
        this.selected = this.selectOption(
          this.editUserForm.controls['main_role'].value,
          '_id',
          this.roleList,
          this.matAutocomplete,
          this.selectName,
          this.myControl
        );
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => {
            let idx = -1;
            if (value.length === 0) {
              this.matAutocomplete.options.forEach((item, index) => {
                if (item.value === this.selectName) {
                  idx = index;
                }
              });
              if (idx !== -1) {
                this.autocomplete.autocomplete._keyManager.setActiveItem(idx);
                this.matAutocomplete.options.get(idx).select();
              }
            }

            return this.utilsService._filter(value || '', this.options);
          })
        );
      }, 100);
    } else {
      this.selectName = this.roleList.find((role) => role['_id'] === value.main_role)['name'];
    }
  }

  selectOption(selected, mode, list, matAutocomplete: MatAutocomplete, selectName, myControl) {
    const select = list.find((option) => option[mode] === selected);
    selectName = select.name;
    myControl.setValue(selectName);
    this.selectOptionSelected(matAutocomplete, selectName);
    return select._id;
  }

  selectOptionSelected(matAutocomplete: MatAutocomplete, selectNameDinamic: string) {
    setTimeout(() => {
      matAutocomplete.options.forEach((option) => {
        if (option.value === selectNameDinamic) {
          option.select();
        }
      });
    }, 200);
  }

  changeOptionSelected(event?) {
    [this.selectName, this.selected] = this.utilsService.changeOptionSelected(event, this.roleList);
    this.editUserForm.controls['main_role'].setValue(this.selected);
  }

  closed() {
    [this.isPanelOpen, this.myControl] = this.utilsService.closed(
      this.isPanelOpen,
      this.myControl,
      this.selectName
    );
  }

  opened() {
    [this.isPanelOpen, this.myControl] = this.utilsService.opened(this.isPanelOpen, this.myControl);
  }

  openPanel() {
    this.selectName = this.utilsService.openPanel(
      this.autocomplete,
      this.roleList,
      this.selected,
      this.matAutocomplete
    );
  }

  closePanel(event) {
    this.utilsService.closePanel(event, this.autocomplete);
  }

  public changeImage(event: any, typeImg: string, src: string, index?): void {
    //console.log(event, typeImg, src, index);
    this.imgCropperDialogRef = this.matDialog.open(ModalImgCropperComponent, {
      height: 'auto',
      width: this.width > 1000 ? '448px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      disableClose: true,
      data: {
        width: this.width,
        index: index,
        imageChangedEvent: event,
        typeImg: typeImg,
        containWithinAspectRatio: false,
        maintainAspectRatio: true,
        widthImage: 300,
        heightImage: 300,
        cropperStaticWidth: 150,
        cropperStaticHeight: 150,
        roundCropper: true,
        title: this.labelsCommon.cropper + ' ' + typeImg,
        button1Label: this.labelsCommon.upload,
        button2Label: this.labelsCommon.cancel
      }
    });
    this.imgCropperDialogRef.afterClosed().subscribe(async (result) => {
      if (result.action === 'cancel') {
        this.blob = [];
        return;
      } else {
        const filesToUpload = result.croppedImage;
        this.updateAvatar(filesToUpload, result.typeImg, result.fileToSend.lastModified);
      }
    });
  }

  updateAvatar(filesToUpload, name, date) {
    const type = filesToUpload.split('/')[1].split(';')[0];
    const avatar = this.utilsService.dataURLtoFile(filesToUpload, `${date}${name}.${type}`);
    this.usersService
      .uploadImage(avatar)
      .then((res: any) => {
        this.userData.avatar = res;
        this.snackMessageService.sendMessage('S032');
      })
      .catch((err) => {
        this.snackMessageService.readError(err);
      });
  }

  submitEditUserForm() {
    this.userData.avatar = this.userData.avatar;
    this.userData.email = this.editUserForm.controls['email'].value;
    this.userData.main_role = this.editUserForm.controls['main_role'].value;
    this.userData.firstName = this.editUserForm.controls['firstName'].value;
    this.userData.lastName = this.editUserForm.controls['lastName'].value;
    this.userData.phone = this.editUserForm.controls['phone'].value;
    this.userData.position = this.editUserForm.controls['position'].value;
    this.userData.location = this.editUserForm.controls['location'].value;

    this.usersService.updateUserInfo(this.userData).subscribe(
      () => {
        this.errorForm = null;
        this.editUserForm.reset();
        this.dialogRef.close({ action: 'updatedUser' });
      },
      (error) => {
        this.snackMessageService.readError(error);
        this.errorForm = error;
      }
    );
  }

  ngOnDestroy() {}
}
