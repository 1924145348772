import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ModalMessageComponent } from '../main/common/components/modal-message/modal-message.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../main/common/shared.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    SharedModule,
    MatCheckboxModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatStepperModule,
    MatProgressBarModule,
    MatDialogModule
  ],
  exports: [ModalMessageComponent, HeaderComponent, FooterComponent],
  declarations: [ModalMessageComponent, HeaderComponent, FooterComponent]
})
export class LayoutModule {}
