<div *ngIf="editUserForm" class="{{ className }}">
  <h2 class="{{ className }}__title">{{ 'profile settings' | uppercase }}</h2>

  <mat-dialog-content>
    <div class="{{ className }}__container-data">
      <div class="{{ className }}__avatar">
        <input
          #file
          (change)="changeImage($event, 'avatar', userData.avatar)"
          id="profile-input-file-avatar"
          style="display: none"
          type="file" />
        <img
          [src]="avatarUri ?? imgDefault"
          alt="avatar"
          class="{{ className }}__avatar-img"
          id="edit-user-avatar" />
        <div class="{{ className }}__avatar-edit">
          <button
            (click)="file.click()"
            color="light-grey-2"
            id="edit-user-edit-avatar"
            mat-mini-fab>
            <mat-icon
              class="{{ className }}__button-icon"
              matTooltip="{{ labels.editAvatar }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above">
              edit
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="{{ className }}__email-role">
        <div class="{{ className }}__form-field-wrapper">
          <span class="{{ className }}__form-field-label">{{ labels.email | uppercase }}</span>
          <p class="{{ className }}__form-field-readonly">{{ userData.email }}</p>
        </div>
        <div class="{{ className }}__form-field-wrapper">
          <span class="{{ className }}__form-field-label">{{ 'Role' | uppercase }}</span>
          <p class="{{ className }}__form-field-readonly">
            {{ selectName }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <mat-tab-group
        #tabList
        (selectedTabChange)="selectedTabChange($event)"
        class="{{ className }}__tabs">
        <mat-tab *ngFor="let item of tabsList" [label]="item">
          <form *ngIf="item === 'PROFILE'" [formGroup]="editUserForm">
            <div class="{{ className }}__group-input">
              <div class="{{ className }}__section-input">
                <div class="{{ className }}__form-field-wrapper input">
                  <span class="{{ className }}__form-field-label">
                    {{ labels.name | uppercase }} *
                  </span>
                  <mat-form-field appearance="outline" class="onlyplaceholder">
                    <input
                      #fullName
                      [placeholder]="userData.firstName"
                      autocomplete="off"
                      formControlName="firstName"
                      id="edit-user-first-name"
                      matInput
                      maxlength="32"
                      name="firstName"
                      required="true" />
                    <mat-hint align="end">{{ fullName.value.length }} / 32</mat-hint>
                    <mat-error *ngIf="editUserForm.controls['firstName'].hasError('required')">
                      {{ labels.name + labels.isRequired }}
                    </mat-error>
                  </mat-form-field>
                  <mat-error> {{ errorForm }}</mat-error>
                </div>
                <div class="{{ className }}__form-field-wrapper input">
                  <span class="{{ className }}__form-field-label">
                    {{ labels.surname | uppercase }} *
                  </span>
                  <mat-form-field appearance="outline" class="onlyplaceholder">
                    <input
                      #lastName
                      [placeholder]="userData.lastName"
                      autocomplete="off"
                      formControlName="lastName"
                      id="edit-user-last-name"
                      matInput
                      maxlength="32"
                      name="lastName"
                      required="true" />
                    <mat-hint align="end">{{ lastName.value.length }} / 32</mat-hint>
                    <mat-error *ngIf="editUserForm.controls['lastName'].hasError('required')">
                      {{ labels.name + labels.isRequired }}
                    </mat-error>
                  </mat-form-field>
                  <mat-error> {{ errorForm }}</mat-error>
                </div>
              </div>
              <div class="{{ className }}__form-field-wrapper input">
                <span class="{{ className }}__form-field-label">{{
                  labels.position | uppercase
                }}</span>
                <mat-form-field appearance="outline" class="onlyplaceholder">
                  <input
                    #position
                    [placeholder]="userData.position"
                    autocomplete="off"
                    formControlName="position"
                    id="edit-user-position"
                    matInput
                    maxlength="32"
                    name="position" />
                  <mat-hint align="end">{{ position.value.length }} / 32</mat-hint>
                  <mat-error *ngIf="editUserForm.controls['position'].hasError('required')">
                    {{ labels.position + labels.isRequired }}
                  </mat-error>
                </mat-form-field>
                <mat-error> {{ errorForm }}</mat-error>
              </div>
            </div>
            <div class="{{ className }}__group-input">
              <div class="{{ className }}__form-field-wrapper input">
                <span class="{{ className }}__form-field-label">{{
                  labels.telephone | uppercase
                }}</span>
                <mat-form-field appearance="outline" class="onlyplaceholder">
                  <input
                    #phone
                    [placeholder]="userData.phone"
                    autocomplete="off"
                    formControlName="phone"
                    id="edit-user-phone"
                    matInput
                    maxlength="32"
                    name="phone" />
                  <mat-hint align="end">{{ phone.value.length }} / 32</mat-hint>
                  <mat-error *ngIf="editUserForm.controls['phone'].hasError('required')">
                    {{ labels.telephone + labels.isRequired }}
                  </mat-error>
                  <mat-error *ngIf="!editUserForm.controls['phone'].hasError('required')">
                    {{ labels.telephone + labels.isNotValid }}
                  </mat-error>
                </mat-form-field>
                <mat-error> {{ errorForm }}</mat-error>
              </div>

              <div class="{{ className }}__form-field-wrapper input">
                <span class="{{ className }}__form-field-label">{{
                  labels.location | uppercase
                }}</span>
                <mat-form-field appearance="outline" class="onlyplaceholder">
                  <input
                    #location
                    [placeholder]="userData.location"
                    autocomplete="off"
                    formControlName="location"
                    id="edit-user-location"
                    matInput
                    maxlength="32"
                    name="location" />
                  <mat-hint align="end">{{ location.value.length }} / 32</mat-hint>
                  <mat-error *ngIf="editUserForm.controls['location'].hasError('required')">
                    {{ labels.location + labels.isRequired }}
                  </mat-error>
                </mat-form-field>
                <mat-error> {{ errorForm }}</mat-error>
              </div>
            </div>
          </form>
          <div *ngIf="item === 'SECURITY'">
            <div class="{{ className }}__container-security form">
              <div class="{{ className }}__group-security">
                <h4 class="{{ className }}__container-security-title">
                  {{ 'password' | uppercase }}
                </h4>
                <div class="{{ className }}__wrapper-group">
                  <div class="{{ className }}__container-security-label-no-data">
                    Change here your Barbara password.
                  </div>
                  <div>
                    <button
                      (click)="updatePassword()"
                      class="{{ className }}__modal-button"
                      color="dark-blue"
                      id="{{ className }}-button-toggle-credentials"
                      mat-flat-button>
                      {{ 'update password' | uppercase }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="{{ className }}__group-security">
                <h4 class="{{ className }}__container-security-title">
                  {{ 'two factor authentication' | uppercase }}
                </h4>
                <div class="{{ className }}__wrapper-group">
                  <div class="{{ className }}__container-security-label-no-data">
                    Two Factor Authentication (2FA) is a method of adding additional security to
                    your account.
                  </div>
                  <div>
                    <button
                      (click)="activateOrDeactivate2FA()"
                      class="{{ className }}__modal-button"
                      color="dark-blue"
                      id="{{ className }}-button-toggle-credentials"
                      mat-flat-button>
                      {{ (!status2fa? 'Activate':'Deactivate') + ' 2fa' | uppercase }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="{{ className }}__footer">
    <button
      (click)="submitEditUserForm()"
      [disabled]="!editUserForm.valid"
      class="{{ className }}__button-create"
      color="dark-blue"
      id="{{ className }}-button-save"
      mat-flat-button>
      {{ 'save profile' | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
