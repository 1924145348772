import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'persist'
})
export class PersistPipe implements PipeTransform {
  transform(value: any): any {
    if (value === 0) {
      return '0 Kb';
    } else {
      if (value > 1024 * 1024 * 1024) {
        value = Math.round((value * 100) / (1024 * 1024 * 1024)) / 100.0 + ' Gb';
      } else if (value > 1024 * 1024 && value < 1024 * 1024 * 1024) {
        value = Math.round((value * 100) / (1024 * 1024)) / 100.0 + ' Mb';
      } else if (value < 1024 * 1024) {
        value = Math.round((value * 100) / 1024) / 100.0 + ' Kb';
      }
    }
    return value;
  }
}