import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DeviceModel } from '../../common/models/common.model';
import { BackEndCommunicationService } from '../../common/services/beComm.service';
import {
  ApplicationList,
  AppsModel,
  AppVersionList,
  ModelListModelsList,
  ServiceModel,
  VersionModel,
  WizardListModel,
  WizardVersionModel
} from '../models/apps.model';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  onSelectedApplicationsChanged: BehaviorSubject<any>;
  onApplicationsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  public applications: DeviceModel[];
  selectedApplications: string[] = [];
  private deletedApplications = new Subject<void>();

  constructor(private backEndCommunicationService: BackEndCommunicationService) {
    this.onSelectedApplicationsChanged = new BehaviorSubject([]);
    this.onApplicationsChanged = new BehaviorSubject([]);
    this.onFilterChanged = new Subject();
  }

  public getAppsList = (
    from: number,
    size: number,
    filter: string,
    search: string = ''
  ): Observable<ApplicationList> => {
    //filter = 'all','app' or 'model'
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/paginate?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  };

  public getAppListReduced = (filter): Observable<any> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/list?filter=${filter}`
    );
  };

  public createNewRegistry = (upload): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .postZipResource('/applications', upload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public createNewRegistryModel = (data): Observable<any> => {
    return this.backEndCommunicationService.postZipResourceObservable('/models', data);
  };

  public getApplicationById = (applicationId: string): Observable<AppVersionList> => {
    return this.backEndCommunicationService.getResourceObservable('/applications/' + applicationId);
  };

  getListVersions = (applicationId: string, appVersionId: string): Observable<AppVersionList> => {
    return this.backEndCommunicationService.getResourceObservable(
      '/applications/' + applicationId + '/appVersions/' + appVersionId
    );
  };

  public upploadModelVersionWithProgress = (applicationId: string, data): Observable<any> => {
    return this.backEndCommunicationService.postZipResourceWithProgress(
      `/models/${applicationId}/modelversions`,
      data
    );
  };

  public filterListVersionObservable = (
    applicationId: string,
    from,
    size,
    filter,
    search = ''
  ): Observable<AppVersionList> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/${applicationId}/appversions?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  };
  public updateApplicationById = (applicationId: string, data): Observable<AppsModel> => {
    return this.backEndCommunicationService.putResourceObservable(
      '/applications/' + applicationId,
      data
    );
  };
  public updateModelById = (modelId: string, data): Observable<AppsModel> => {
    return this.backEndCommunicationService.putResourceObservable('/models/' + modelId, data);
  };
  public deleteApplicationById = (registryId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/applications/' + registryId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public deleteModelById = (modelId: string): Observable<void> => {
    return this.backEndCommunicationService.deleteResourceObservable('/models/' + modelId);
  };

  public deleteApplicationVersion = (registryId: string, applicationId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/applications/' + applicationId + '/appversions/' + registryId)
        .then(() => {
          this.deletedApplications.next();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public deleteModelVersion = (registryId: string, applicationId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/models/' + applicationId + '/modelversions/' + registryId)
        .then(() => {
          this.deletedApplications.next();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public uploadApplicationVersion(applicationId: string, formData): Observable<any> {
    return this.backEndCommunicationService.postZipResourceObservable(
      '/applications/' + applicationId + '/appversions',
      formData
    );
  }

  updateIcon(applicationId, icon) {
    const formData = new FormData();
    formData.append('icon', icon);
    return this.backEndCommunicationService.putZipResourceObservable(
      `/applications/${applicationId}/icon`,
      formData
    );
  }

  updateIconModel(modelId, icon) {
    const formData = new FormData();
    formData.append('icon', icon);
    return this.backEndCommunicationService.putZipResourceObservable(
      `/models/${modelId}/icon`,
      formData
    );
  }

  uploadPhotos(applicationId, position, picture) {
    const formData = new FormData();
    formData.append('picture', picture);
    return this.backEndCommunicationService.postZipResourceObservable(
      `/applications/${applicationId}/pictures/${position}`,
      formData
    );
  }

  uploadPhotosModel(modelId, position, picture) {
    const formData = new FormData();
    formData.append('picture', picture);
    return this.backEndCommunicationService.postZipResourceObservable(
      `/models/${modelId}/pictures/${position}`,
      formData
    );
  }

  updatePhoto(applicationId, position, picture) {
    const formData = new FormData();
    formData.append('picture', picture);
    return this.backEndCommunicationService.putZipResourceObservable(
      `/applications/${applicationId}/pictures/${position}`,
      formData
    );
  }

  updatePhotoModel(modelId, position, picture) {
    const formData = new FormData();
    formData.append('picture', picture);
    return this.backEndCommunicationService.putZipResourceObservable(
      `/models/${modelId}/pictures/${position}`,
      formData
    );
  }

  deletePhoto(applicationId, position) {
    return this.backEndCommunicationService.deleteResourceObservable(
      `/applications/${applicationId}/pictures/${position}`,
      {}
    );
  }

  deletePhotoModel(modelId, position) {
    return this.backEndCommunicationService.deleteResourceObservable(
      `/models/${modelId}/pictures/${position}`,
      {}
    );
  }

  existNameVersionApp(applicationId, name) {
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/${applicationId}/appversions/checkexists?versionName=${name}`
    );
  }

  existNameVersionModel(modelId, name) {
    return this.backEndCommunicationService.getResourceObservable(
      `/models/${modelId}/modelversions/checkexists?versionName=${name}`
    );
  }

  createAppMarketplace(data, deviceId) {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/market/workloads`,
      data
    );
  }

  createModel(deviceId, data) {
    return this.backEndCommunicationService.postResourceObservable(
      `/devices/${deviceId}/model/workloads`,
      data
    );
  }

  updateAppModel(data, deviceId, workloadsId) {
    return this.backEndCommunicationService.putResourceObservable(
      `/devices/${deviceId}/model/workloads/${workloadsId}`,
      data
    );
  }

  updateAppMarketplace(data, deviceId, workloadsId) {
    return this.backEndCommunicationService.putResourceObservable(
      `/devices/${deviceId}/market/workloads/${workloadsId}`,
      data
    );
  }

  getModelsList = (
    from: number,
    size: number,
    filter: string,
    search: string = ''
  ): Observable<ModelListModelsList> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/models?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  };
  getModelById = (modelId): Observable<ApplicationList> => {
    return this.backEndCommunicationService.getResourceObservable(`/models/${modelId}`);
  };
  getModelVersionsList = (modelId): Observable<VersionModel> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/models/${modelId}/modelversions`
    );
  };
  getModelsVersionServicesTemplate = (): Observable<ServiceModel> => {
    return this.backEndCommunicationService.getResourceObservable(
      '/models/version/services/template'
    );
  };
  getListAppsWizard = (
    from: number,
    size: number,
    filter: string,
    search: string = ''
  ): Observable<WizardListModel> => {
    //filter = 'all','app' or 'model'
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/paginate?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  };
  getListModelsWizard = (): Observable<WizardListModel> => {
    return this.backEndCommunicationService.getResourceObservable('/models');
  };

  getListModelsVersionsWizard = (modelId): Observable<WizardVersionModel> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/models/${modelId}/modelversions`
    );
  };
  getListAppsVersionsWizard = (
    applicationId: string,
    appVersionId: string
  ): Observable<WizardVersionModel> => {
    return this.backEndCommunicationService.getResourceObservable(
      '/applications/' + applicationId + '/appVersions/' + appVersionId
    );
  };

  public getFilterAppsList(mode): Observable<AppsModel[]> {
    return this.backEndCommunicationService.getResourceObservable(`/applications?filter=${mode}`);
  }

  public upploadModelVersionWithProgressNew = (applicationId: string, data): Observable<any> => {
    return this.backEndCommunicationService.postZipResourceWithProgressNew(
      `/models/${applicationId}/modelversions`,
      data
    );
  };

  public upploadAppVersionWithProgressNew(applicationId: string, formData): Observable<any> {
    return this.backEndCommunicationService.postZipResourceWithProgressNew(
      `/applications/${applicationId}/appversions`,
      formData
    );
  }
}