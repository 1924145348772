import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BackEndCommunicationService {
  private serverUrl = '';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    this.serverUrl = environment.apiUrl;
  }

  public getResource(uri: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        })
      };
      this.http
        .get(this.serverUrl + uri, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public putResourceObservable(uri: string, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    };
    return this.http.put(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public putResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        })
      };
      this.http
        .put(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public postResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        })
      };
      this.http
        .post(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (response) {
            resolve(response.response);
          } else {
            resolve('OK');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public postZipResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({})
      };
      this.http
        .post(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public postZipResourceWithProgress(uri: string, formData: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({}),
      reportProgress: true
    };

    const request = new HttpRequest('POST', this.serverUrl + uri, formData, httpOptions);

    return this.http.request(request).pipe(
      map((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          return Math.round((event.loaded / event.total) * 100);
        } else if (event.type === HttpEventType.Response) {
          return event.body;
        }
      })
    );
  }

  public postZipResourceWithProgressNew(uri: string, formData: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({}),
      reportProgress: true
    };

    const request = new HttpRequest('POST', this.serverUrl + uri, formData, httpOptions);
    let progress = 0;
    return this.http.request(request).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (event.loaded < event.total) {
              progress = Math.round((100 * event.loaded) / event.total);
            }
            return progress;
          case HttpEventType.ResponseHeader:
          case HttpEventType.DownloadProgress:
            return true;
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${HttpEventType}`;
        }
      })
    );
  }

  public postAnonResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        })
      };
      this.http
        .post(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteResource(uri: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        }),
        body: data
      };
      this.http
        .delete(this.serverUrl + uri, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public putZipResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({})
      };
      this.http
        .put(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          resolve(response.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getResourceObservable(uri: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    };
    return this.http.get(this.serverUrl + uri, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public deleteResourceObservable(uri: string, data?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      }),
      body: data
    };
    return this.http.delete(this.serverUrl + uri, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public postResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.post(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public postZipResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.post(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public putZipResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.put(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }
}
