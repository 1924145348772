import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { UserModel } from '../models/users.model';
import { AuthService } from '../../common/services/auth.service';
import { map } from 'rxjs/operators';
import { ProfileModel } from '../../account/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  onSelectedContactsChanged: BehaviorSubject<any>;
  contacts: UserModel[];
  onContactsChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<any>;
  private deletedUsers = new Subject<void>();

  constructor(
    private backEndCommunicationService: BackEndCommunicationService,
    private authService: AuthService
  ) {
    this.onSelectedContactsChanged = new BehaviorSubject([]);
    this.onContactsChanged = new BehaviorSubject([]);
    this.onFilterChanged = new Subject();
  }

  public newUserObs(userValues: any): Observable<any[]> {
    const dataObject = {
      data: userValues
    };
    return this.backEndCommunicationService.postResourceObservable(
      '/users/invitations',
      dataObject
    );
  }

  public getUsersConfirmedObs(from, size, filter, search): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable(
      `/users/paginate?from=${from}&size=${size}&filter=${filter}&search=${search}`
    );
  }

  public savePosition(data): Observable<any> {
    return this.backEndCommunicationService.putResourceObservable('/user/customData', { data });
  }

  public getUsersPendingObs(from, size, filter, search): Observable<any> {
    return this.backEndCommunicationService
      .getResourceObservable(
        `/users/invitations/paginate?from=${from}&size=${size}&filter=${filter}&search=${search}`
      )
      .pipe(
        map((response) => {
          response = response.invitations;
          return response;
        })
      );
  }

  getDataCompany(): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable('/companies/me');
  }

  getListOfDevicesRecent() {
    return this.backEndCommunicationService.getResourceObservable(
      '/user/customdata/recentdevicelist'
    );
  }

  saveListOfDevicesRecent(list: string[]) {
    return this.backEndCommunicationService.putResourceObservable(
      '/user/customdata/recentdevicelist',
      {
        data: { recentDeviceList: list }
      }
    );
  }

  public resendInvitation(invitationId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const sendObject = {
        data: {
          invitationId: invitationId
        }
      };
      this.backEndCommunicationService
        .postResource('/users/invitations/mail', sendObject)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteInvitation(invitationId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/users/invitations/' + invitationId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getUserById(userId: string): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable('/users/' + userId);
  }

  public deleteUser(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backEndCommunicationService
        .deleteResource('/users/' + userId)
        .then(() => {
          this.onContactsChanged.next(this.contacts);
          this.deletedUsers.next();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public uploadImage = (image): Promise<void> => {
    return new Promise((resolve, reject) => {
      const uploadData = new FormData();
      uploadData.append('file', image);
      this.backEndCommunicationService
        .postZipResource('/users/avatar', uploadData)
        .then((response: any) => {
          this.authService.setUserAvatar(response.imageName);
          resolve(response.imageName);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  public uploadImageCompany = (image): Promise<void> => {
    return new Promise((resolve, reject) => {
      const uploadData = new FormData();
      uploadData.append('file', image);
      this.backEndCommunicationService
        .postZipResource('/companies/avatar', uploadData)
        .then((response: any) => {
          resolve(response.imageName);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  public updateUserInfo(userInfo: UserModel): Observable<void> {
    const sendObject = {
      data: userInfo
    };
    return this.backEndCommunicationService
      .putResourceObservable('/users/' + userInfo._id, sendObject)
      .pipe(
        map((response) => {
          if (userInfo.email === this.authService.getEmail()) {
            this.authService.setUserName(userInfo.firstName + ' ' + userInfo.lastName);
            this.authService.setMainRol(userInfo['main_role']);
          }
          return response;
        })
      );
  }

  public getUserProfile = (): Observable<ProfileModel> => {
    return from(
      this.backEndCommunicationService.getResource('/users/me').then((response) => {
        return response;
      })
    );
  };
  public editProfileInfo = (data): Observable<ProfileModel> => {
    return from(
      this.backEndCommunicationService.putResourceObservable('/users/me', { data }).pipe(
        map((response) => {
          if (data.email === this.authService.getEmail()) {
            this.authService.setUserName(data.firstName + ' ' + data.lastName);
            this.authService.setMainRol(data['main_role']);
          }
          return response;
        })
      )
    );
  };
}
