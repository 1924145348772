import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { dataTable } from 'app/main/common/models/common.model';
import { ModalConfirmConfigurableComponent } from '../../../common/components/modal-confirm-configurable/modal-confirm-configurable.component';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { StorageService } from '../../../common/services/storage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { usersViewTableLabels } from '../../labels/users.labels';
import { UsersViewTableLabels } from '../../models/users.model';
import { UsersService } from '../../services/users.service';
import { EditUserComponent } from '../edit-user/edit-user.component';

@Component({
  selector: 'tabs-user',
  templateUrl: './tabs-user.component.html',
  styleUrls: ['./tabs-user.component.scss']
})
export class UserTabsComponent implements OnDestroy {
  className = 'tabs-user';
  labels: UsersViewTableLabels = usersViewTableLabels;

  user;
  editDialogRef: MatDialogRef<EditUserComponent>;
  deleteUserDialogRef: MatDialogRef<ModalConfirmConfigurableComponent>;
  resendDialogRef: MatDialogRef<ModalConfirmConfigurableComponent>;
  deleteInvitationDialogRef: MatDialogRef<ModalConfirmConfigurableComponent>;

  @Input() originalData: any;
  @Input() displayedColumns: string[];
  @Input() dataTable: dataTable[];
  @Input() selectedView: string;
  @Input() isLoading: boolean;
  @Input() width: number;
  @Input() tabSelectedIndex: number;
  @Input() imgDefault: string;
  @Output() emitResultModal: EventEmitter<string> = new EventEmitter();
  @Output() emitGetAllPaginator: EventEmitter<number> = new EventEmitter();
  @Output() emitTypeTab: EventEmitter<{
    indexTab: number;
    filterInput: string;
    callService: boolean;
    total: string;
  }> = new EventEmitter();

  constructor(
    private usersService: UsersService,
    private commonService: UtilsService,
    private storageService: StorageService,
    private snackMessageService: SnackMessageService,
    private matDialog: MatDialog
  ) {}

  actionsTable(value): void {
    switch (value.actions) {
      case 'edit':
        this.user = value.element;
        this.getDataUser(this.user['_id']);
        break;
      case 'deleteUser':
        this.openDeleteUserModal(value.element._id);
        break;
      case 'resend':
        this.openResendInvitationModal(value.element._id);
        break;
      case 'deleteInvitation':
        this.openDeleteInvitationModal(value.element._id);
        break;
    }
  }

  getDataUser(userId) {
    this.usersService.getUserById(userId).subscribe({
      next: (response) => {
        let config = {
          data: {
            width: this.width,
            elementData: response,
            imgDefault: this.imgDefault,
            typeImg: 'avatar',
            img: response.avatar
          }
        };

        this.openEditUserModal(config);
      },
      error: (error) => {
        //console.log(error);
        this.snackMessageService.readError(error);
      }
    });
  }

  //EditUserComponent
  openEditUserModal(config) {
    this.editDialogRef = this.matDialog.open(EditUserComponent, {
      height: 'auto',
      width: this.width > 700 ? '452px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: true,
      autoFocus: false,
      data: config.data
    });
    this.editDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.action === 'updatedUser') {
          this.emitResultModal.emit('updatedUser');
          this.snackMessageService.sendMessage('S032');
        }
      }
    });
  }

  openDeleteUserModal(userId) {
    this.deleteUserDialogRef = this.matDialog.open(ModalConfirmConfigurableComponent, {
      height: 'auto',
      width: this.width > 700 ? '400px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: false,
      autoFocus: false,
      data: {
        width: this.width,
        titleMessage: 'Are you sure you want to delete this user?',
        contentMessage: 'It will delete the user and all its related data from the system.',
        button1Color: 'primary',
        button1Message: 'OK'
      }
    });
    this.deleteUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersService.deleteUser(userId).then(
          (response) => {
            this.isLoading = false;
            this.getAllPaginator(0);
            this.emitTypeTab.emit({
              indexTab: 0,
              filterInput: '',
              callService: true,
              total: 'total'
            });
            this.snackMessageService.sendMessage('S033');
          },
          (error) => {
            //console.log(error);
            this.isLoading = false;
            this.snackMessageService.readError(error);
          }
        );
      }
    });
  }

  openDeleteInvitationModal(invitationId) {
    this.deleteInvitationDialogRef = this.matDialog.open(ModalConfirmConfigurableComponent, {
      height: 'auto',
      width: this.width > 700 ? '400px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: false,
      autoFocus: false,
      data: {
        width: this.width,
        titleMessage: 'Are you sure you want to delete the invitation to this user?',
        contentMessage:
          'The user will no longer be able to access your company using this invitation.',
        button1Color: 'primary',
        button1Message: 'Ok'
      }
    });
    this.deleteInvitationDialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.usersService
            .deleteInvitation(invitationId)
            .then((response) => {
              this.isLoading = false;
              this.getAllPaginator(0);
              this.emitTypeTab.emit({
                indexTab: 1,
                filterInput: '',
                callService: true,
                total: 'total'
              });
              this.snackMessageService.sendMessage('S034');
            })
            .catch((error) => {
              //console.log(error);
              this.isLoading = false;
              this.snackMessageService.readError(error);
            });
        }
      },
      (error) => {
        this.snackMessageService.readError(error);
      }
    );
  }

  openResendInvitationModal(invitationId) {
    this.resendDialogRef = this.matDialog.open(ModalConfirmConfigurableComponent, {
      height: 'auto',
      width: this.width > 700 ? '400px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-container',
      backdropClass: 'backdropBackground',
      disableClose: false,
      autoFocus: false,
      data: {
        width: this.width,
        titleMessage: 'Are you sure you want to resend the invitation to this user?',
        contentMessage:
          'A new invitation mail will be sent to the user. Previous access code will be invalid, a new one will be generated.',
        button1Color: 'primary',
        button1Message: 'Ok'
      }
    });
    this.resendDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.usersService.resendInvitation(invitationId).then(
          (response) => {
            this.isLoading = false;
            this.snackMessageService.sendMessage('S035');
          },
          (error) => {
            //console.log(error);
            this.isLoading = false;
            this.snackMessageService.readError(error);
          }
        );
      }
    });
  }

  getAllPaginator(value): void {
    if (value === 0) {
      this.emitGetAllPaginator.emit(value);
    } else {
      this.emitGetAllPaginator.emit();
    }
  }

  ngOnDestroy() {}
}