import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { secretsLabels } from '../../../devices/device-detail/devices-detail.labels';

@Component({
  selector: 'app-modal-confirm-configurable',
  templateUrl: './modal-confirm-configurable.component.html',
  styleUrls: ['./modal-confirm-configurable.component.scss']
})
export class ModalConfirmConfigurableComponent implements OnInit {
  className = 'modal-confirm-configurable';
  protected readonly labels = secretsLabels;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalConfirmConfigurableComponent>
  ) {}

  ngOnInit() {
    //console.log(this.data);
  }

  back(): void {
    // closing itself and sending data to parent component
    if (
      this.data.button2Action !== undefined &&
      (this.data.credential !== undefined || this.data.secret !== undefined)
    ) {
      this.dialogRef.close({ event: this.data.button2Action });
    } else {
      this.dialogRef.close();
    }
  }

  onConfirmed(): void {
    // closing itself and sending data to parent component
    if (
      this.data.button1Action !== undefined &&
      (this.data.credential !== undefined || this.data.secret !== undefined)
    ) {
      this.dialogRef.close({
        result: true,
        event: this.data.button1Action,
        data: this.data.credential ?? this.data.secret
      });
    } else {
      this.dialogRef.close({ result: true });
    }
  }
}