<h2 class="modal-title">Barbara Saas: Terms &amp; Conditions</h2>
<mat-dialog-content class="modal-content">
  <div class="justify-text">
    <h3 class="title">Modifications</h3>
    <p class="initial">
      &emsp;We may modify the Terms of Service at any time, in our sole discretion. If we do so,
      we’ll let you know either by posting the modified Terms of Service on the Site or through
      other communications. It’s important that you review the Terms of Service whenever we modify
      them because if you continue to use the Service after we have posted modified Terms of Service
      on the Site, you are indicating to us that you agree to be bound by the modified Terms of
      Service. If you don’t agree to be bound by the modified Terms of Service, then you may not use
      the Service anymore. Because our Service is evolving over time we may change or discontinue
      all or any part of the Service, at any time and without notice, at our sole discretion.
    </p>
    <p class="initial">
      &emsp;If you violate any of the terms in this Terms of Service, we can terminate your Account
      in our sole discretion. You agree to use the Service at your own risk.
    </p>

    <h3 class="title">Definitions</h3>
    <p class="initial">
      <b>“Application” & “Content”</b> means an app you are running on top of Barbara OS and that is
      managed by Barbara Panel and Barbara API.<br />
      <b>“Barbara Marks” or “Marks”</b> mean any trademarks, service marks, service or trade names,
      logos, and other designations of Barbara and its affiliates that we may make available to you
      in connection with this Agreement.<br />
      <b>“Barbara Site”</b> means
      <a class="link" href="https://www.barbaraiot.com">https://www.barbaraiot.com</a>,
      <a class="link" href="http://www.barbarapanel.com">http://www.barbarapanel.com</a>, any
      subdomain owned or operated by Barbara, and any successor or related site designated by us.<br />
      <b>“End User”</b> means any individual or entity that directly or indirectly through another
      user accesses or uses Barbara services under your account.<br />
      <b>“GDPR Personal Data”</b> means personally identifiable information as defined in Article 4
      of the European Union’s General Data Protection Regulation.<br />
      The <b>“Services”</b> means the Barbara cloud services, including Barbara Panel, Barbara API,
      interfaces, related web sites, networks, embeddable widgets, downloadable software, and other
      services we provide.<br />
      The <b>“Agreement”, “Terms”, “Policy” or “Policies”</b> refers to the terms described in this
      document if not stated differently in the document.<br />
      <b>“Maintenance”</b> means scheduled Unavailability of the Services, as announced by us prior
      to the job.
    </p>

    <h3 class="title">Summary</h3>
    <p class="initial">
      &emsp;You acknowledge and agree that, as provided in greater detail in this Agreement:
    </p>
    <ul class="with-bullets">
      <li>
        The Services are licensed, not sold to you, and that you may use the Services only as set
        forth in this Agreement;
      </li>
      <li>
        The Services are provided “as is” without warranties of any kind, and Barbara’s liability to
        you is limited by the terms set in this agreement or others that might exist between the
        parties;
      </li>
      <li>
        You are solely responsible for protecting the privacy and legal rights of your End Users,
        except as provided for in a Business Associate Agreement or Data Use Agreement between you
        and Barbara, if these exsists;
      </li>
      <li>
        You are solely responsible for your own data backups and exporting Your Content from the
        Services prior to closing your Barbara account;
      </li>
    </ul>
    <p class="initial">
      &emsp;This Agreement takes effect when you sign a contract that incorporates these terms by
      reference, or, if earlier, when you use any of the Services (the “Effective Date”), and will
      remain in effect, unless terminated by any of the parties via the means or penalizations
      described in this agreement (“The Term”).
    </p>

    <h3 class="title">Your Account and Use of the Services</h3>

    <p class="initial">
      &emsp;To access the Services, you must create a Barbara user account. You may also need to
      create a Barbara organization account, if one does not exist already.
    </p>
    <p class="initial">
      &emsp;Only human beings may use the Service, which you can only use if you create an account
      (“Account”). We don’t allow for bots or other automation tools to register Accounts, and
      registering an Account this way is prohibited.
    </p>
    <p class="initial">
      &emsp;When you register for the accounts, you may be required to provide us with some
      information about yourself, such as your phone number or other contact information. You agree
      that the information you provide to us is accurate and that you will keep it accurate at all
      times. You are responsible for all activities that occur under your accounts, regardless of
      whether the activities are undertaken by you, your employees or a third party (including your
      contractors or agents). You may create additional user accounts for users within your
      organization.
    </p>
    <p class="initial">
      &emsp;Each Barbara user account is protected by credentials. You are responsible for
      maintaining the security of your credentials. Barbara and our affiliates are not responsible
      for unauthorized access to your account, except to the extent caused by our breach of this
      Agreement.
    </p>

    <h3 class="title">Privacy</h3>

    <p class="initial">
      &emsp;You are responsible for protecting the privacy and legal rights of your End Users. Your
      obligations include but are not limited to:
    </p>
    <ol class="with-bullets">
      <li>Properly configuring the Barbara Services to protect your users’ information;</li>
      <li>
        Configuring and enforcing user access policies and permissions for the Barbara Services; and
      </li>
      <li>
        Providing legally adequate privacy notices for your users, and informing them that their
        information may be available to Barbara and/or our affiliates under certain circumstances,
        pursuant to our
        <a class="link" href="https://barbaraiot.com/en/politica-de-privacidad/">Privacy Policy</a>.
      </li>
    </ol>

    <p class="initial">
      &emsp;In accordance with the regulations in force in Protection of Personal Data, we inform
      you that the data gathered by Barbara IoT will be incorporated into the treatment system owned
      by BARBARA IOT SL with CIF B24697385 and registered office at CALLE PADRE LOJENDIO 5, 4ºDRCHA,
      CP 48008, BILBAO, VIZCAYA, with the purpose of providing the necessary support to the
      activities derived from the present agreement. In compliance with current regulations, BARBARA
      IOT SL informs you that the data will be kept during the PERIOD LEGALLY ESTABLISHED.
    </p>
    <p class="initial">
      &emsp;In BARBARA IOT SL we inform you that we continue to process your data according to the
      existence of a legitimate interest on the part of BARBARA IOT SL.
    </p>
    <p class="initial">
      &emsp;BARBARA IOT SL informs you that we will proceed to treat the data in a lawful, loyal,
      transparent, adequate, pertinent, limited, exact and updated way. That is why BARBARA IOT SL
      undertakes to adopt all reasonable measures so that these are suppressed or rectified without
      delay when they are inaccurate.
    </p>
    <p class="initial">
      &emsp;In accordance with the rights conferred by the current regulations on data protection
      you may exercise the rights of access, rectification, limitation of treatment, deletion,
      portability and opposition to the processing of their personal data as well as the consent
      given for the treatment of the same, directing your request to the email
      <a class="link" href="mailto:info@barbaraiot.com">info&#64;barbaraiot.com</a>.
    </p>
    <p class="initial">
      &emsp;You can go to the competent Control Authority to present the claim that you consider
      appropriate.
    </p>

    <h3 class="title">Fees and Charges</h3>
    <p class="initial">
      &emsp;You incur fees and charges based on your use of the Services. We bill as indicated in
      your purcahse order, as applicable. We may invoice you more frequently if we determine that
      your account is at risk of non-payment. Any outstanding balance becomes immediately due and
      payable upon termination of this Agreement for any reason.
    </p>

    <p class="initial">
      &emsp;All amounts payable are denominated in Euro, and you agree to pay all such amounts in
      Euro.
    </p>
    <p class="initial">
      &emsp;Late payments may bear interest at the rate of 1.5% per month. We reserve the right to
      discontinue the provision of the Services to you for any late payments.
    </p>

    <h3 class="title">Barbara API terms</h3>
    <p class="initial">
      &emsp;We allow our users to access their Barbara Account data via Barbara API (Application
      Program Interface). Any use of the Barbara API, including use of the API through a third-party
      product that accesses Barbara, is bound by these Terms of Service, including the following
      terms that are specific to the use of the Barbara API:
    </p>

    <p class="initial">
      &emsp;You expressly understand and agree that Barbara will not be liable for any direct,
      indirect, incidental, special, consequential or exemplary damages, including but not limited
      to, damages for loss of profits, goodwill, use, data or other intangible losses (even if
      Barbara has been advised of the possibility of such damages), resulting from your use of
      Barbara API or third-party products that access data via the API.
    </p>
    <p class="initial">
      &emsp;Excessively frequent requests to Barbara PANEL via Barbara API or other forms of abuse
      of the API may result in the temporary or permanent suspension of your Account’s access to
      Barbara API. Barbara, in its sole discretion, will determine abuse or excessive usage of
      Barbara API. Barbara will make a reasonable attempt via email to warn the Account owner prior
      to suspension.
    </p>
    <p class="initial">
      &emsp;Barbara reserves the right at any time to modify or discontinue, temporarily or
      permanently, your access to Barbara API (or any part thereof) with or without notice.
    </p>

    <h3 class="title">Intellectual Property</h3>
    <p class="initial">
      &emsp;As between you and Barbara, you own the apps you develop and install on top of Barbara
      OS (“Your Content”). You are solely responsible for the development, operation, maintenance,
      and use of Your Content. For example, you are solely responsible for:
    </p>

    <ol class="with-bullets">
      <li>
        Backing up and securing Content at the Application level, except as delineated in a Business
        Associate Agreement you may enter into with us, or to the extent backups or security
        controls are provided as part of the Services;
      </li>
      <li>Compliance of Your Content with policies, and any applicable laws or regulations;</li>
      <li>Any claims relating to Your Content; and</li>
      <li>
        Properly handling and processing notices sent to you (or any of your affiliates) by any
        person claiming that Your Content violates such person’s rights.
      </li>
    </ol>

    <p class="initial">&emsp;You represent and warrant to us that:</p>
    <ol class="with-bullets">
      <li>
        You or your licensors own all right, title, and interest in and to Your Content and
        Applications;
      </li>
      <li>
        You have all rights in Your Content and Applications necessary to grant the rights
        contemplated by this Agreement;
      </li>
    </ol>
    <p class="initial">&emsp;Notwithstanding that general principle:</p>
    <ol class="with-bullets">
      <li>You consent to our use of Your Content to provide the Services to you;</li>
      <li>
        We may disclose Your Content to comply with any request of a governmental or regulatory body
        (including subpoenas or court orders);
      </li>
    </ol>
    <h4 class="subtitle">License to access and use the Services</h4>
    <p class="initial">
      &emsp;We grant you a limited, revocable, non-exclusive, non-sublicensable, non-transferrable
      license to access and use the Services solely in accordance with this Agreement. This license
      is valid only during the term of this Agreement, and is revoked upon termination. This license
      is subject to the following restrictions:
    </p>
    <ol class="with-bullets">
      <li>
        Neither you nor any End User may use the Services in any manner or for any purpose other
        than as expressly permitted by this Agreement.
      </li>
      <li>
        Neither you nor any End User may, or may attempt to (a) modify, alter, tamper with, repair,
        or otherwise create derivative works of any software included in the Services (except to the
        extent software included in the Services are provided to you under a separate license that
        expressly permits the creation of derivative works), (b) reverse engineer, disassemble, or
        decompile the Services or apply any other process or procedure to derive the source code of
        any software included in the Services, (c) access or use the Services in a way intended to
        avoid incurring fees or exceeding usage limits or quotas, (d) resell or sublicense the
        Services (except to the extent software included in the Services are provided to you under a
        separate license that expressly permits the creation of derivative works), or (e) circumvent
        any security mechanisms used by Barbara, the Services, or any Applications running on the
        Services.
      </li>
      <li>
        All licenses granted to you in this Agreement are conditional on your continued compliance
        with this Agreement, and will immediately and automatically terminate if you do not comply
        with any term or condition of this Agreement.
      </li>
      <li>
        During and after the Term, you will not assert, nor will you authorize, assist, or encourage
        any third party to assert, against us or any of our affiliates, customers, vendors, business
        partners, or licensors, any patent infringement or other intellectual property infringement
        claim regarding any Services you have used.
      </li>
    </ol>
    <h4 class="subtitle">Our trademarks</h4>
    <p class="initial">
      &emsp;Barbara hereby grants you a limited, non-exclusive, royalty-free, non-transferable
      license, with no right to sub-license, to display the Barbara trademarks for the sole purpose
      of promoting or advertising that you use the Barbara Services. These licenses shall be
      exercised only in accordance with the current version of our Trademark Policy (not publicly
      available, if required please request sending an email to info&#64;barbaraiot.com). You agree that
      all goodwill generated through your use of the Barbara trademarks shall insure to the benefit
      of Barbara.
    </p>
    <h4 class="subtitle">Termination</h4>
    <p class="initial">
      &emsp;The license granted in this Agreement will remain in effect, unless terminated by any of
      the parties via the means or penalizations described in this agreement.
    </p>
    <p class="initial">
      &emsp;You are solely responsible for exporting your apps from the services prior to closing
      your account. If we cancel your account, we will provide you a reasonable opportunity to
      retrieve your content.
    </p>
    <h3 class="title">Your Obligations</h3>
    <p class="initial">
      &emsp;You agree that you will be responsible for your use of the Services, and if you harm
      someone or get in a dispute with someone else, we will not be involved. You agree to defend
      and indemnify Barbara and its officers, directors, employees, consultants, affiliates,
      subsidiaries and agents (together, the ”Barbara Entities“) from and against every third-party
      claim, liability, damage, loss, and expense, including reasonable attorneys’ fees and costs,
      arising out of or in any way connected with:
    </p>

    <ol class="with-bullets">
      <li>Your access to, use of, or alleged use of, the Services;</li>
      <li>
        Your violation of any portion of this Agreement, any representation, warranty, or agreement
        referenced in this Agreement, or any applicable law or regulation;
      </li>
      <li>
        Your violation of any third-party right, including any intellectual property right or
        publicity, confidentiality, other property, or privacy right; or
      </li>
      <li>Any dispute or issue between you and any third party.</li>
    </ol>
    <p class="initial">
      &emsp;Agreement to this Terms implies the following obligations for your company:
    </p>

    <ol class="with-bullets">
      <li>
        Your use of the Services must comply with all applicable laws, regulations, and ordinances,
        including any laws regarding the export of data or software.
      </li>
      <li>
        You will provide us with true and accurate information and responses in connection with your
        use of the Services.
      </li>
      <li>
        You will contact us immediately if you believe an unauthorized third party may be using your
        account or if your account information is lost or stolen.
      </li>
      <li>You will not attempt to gain access to Barbara’s internal administrative tools.</li>
      <li>You will not attempt to disrupt the Services.</li>
      <li>
        You will abide by the restrictions in our SLA and Support Policy (see Support and SLA
        policy) and Security Policy (see Security Policy) on how the Services may be implemented.
      </li>
      <li>
        You will remit payment timely for the Services, as specified in the Billing Policy (see Fees
        and Charges) and any purchase order you may execute.
      </li>
    </ol>

    <h3 class="title">Governing Law</h3>
    <p class="initial">
      &emsp;This Agreement is governed by the laws of Madrid, Spain without regard to conflict of
      law principles. If a lawsuit or court proceeding is permitted under this Agreement, then,
      unless another location is expressly specified in other Agreement, you and Barbara agree to
      litigate any dispute in Madrid courts.
    </p>

    <h3 class="title">Notices</h3>
    <p class="initial">
      &emsp;All notices to Barbara must be in writing and addressed to info&#64;barbaraiot.com. Notice
      will be treated as given on receipt as verified by written automated receipt or by electronic
      log (as applicable).
    </p>
    <p class="initial">
      &emsp;You agree that Barbara may provide you with notices, including those regarding changes
      to this Agreement, by email, regular mail, or postings on the Barbara Services. By providing
      Barbara your email address, you consent to our using the email address to send you any notices
      required by law in lieu of communication by postal mail.
    </p>

    <h3 class="title">Force Majeure</h3>
    <p class="initial">
      &emsp;Barbara shall not be liable for failing or delaying performance of its obligations
      resulting from any condition beyond its reasonable control, including but not limited to,
      governmental action, acts of terrorism, earthquake, fire, flood or other acts of nature, labor
      conditions, power failures, and Internet disturbances.
    </p>

    <h3 class="title">Barbara Services SLA</h3>
    <p class="initial">
      &emsp;Unless otherwise provided in an specific agreement between parties, this SLA is subject
      to the provisions of the Terms.
    </p>
    <p class="initial">
      &emsp;Barbara Panel Uptime commitment is 99,5% of the time per year. The downtime should be
      avoided at all times but it might be necessary due to maintenance, update or security jobs
    </p>
    <p class="initial">
      &emsp;“Maintenance” means scheduled Unavailability of the Services, as announced by us prior
      to the job.
    </p>
    <p class="initial">
      &emsp;Service Credits will be issued in case of failing to achieve the Barbara Panel Uptime
      commitment during a monthly billing cycle. They will be calculated as a percentage of the
      total charges due on your Barbara invoice for the monthly billing cycle in which the
      Unavailability occurred, applied proportionally to the Services that were Unavailable, in
      accordance with the schedule below:
    </p>
    <ul class="with-bullets">
      <li>
        For Monthly Uptime Percentage less than 99.8% you will be eligible for a Service Credit of
        5% of the charges attributable to the affected resources.
      </li>
      <li>
        We will apply any Service Credits only against future payments for the Services otherwise
        due from you. Service Credits may not be transferred or applied to any other account.
      </li>
    </ul>
    <h4 class="subtitle">Credit Request and Payment Procedures</h4>
    <p class="initial">
      &emsp;To receive a Service Credit, you must submit a claim by emailing
      <a class="link" href="mailto:support@barbaraiot.com">support&#64;barbaraiot.com</a>. To be
      eligible, the credit request must be received by us by the end of the second billing cycle
      after which the incident occurred and must include:
    </p>
    <ul class="with-bullets">
      <li>the dates and times of each Unavailability incident that you are claiming;</li>
      <li>the account handle(s); and</li>
      <li>
        logs that document the errors and corroborate your claimed outage (any confidential or
        sensitive information in these logs should be removed or replaced with asterisks).
      </li>
    </ul>
    <h4 class="subtitle">SLA Exclusions</h4>
    <p class="initial">
      &emsp;The Service Commitment does not apply to any Unavailability:
      <a class="link" href="mailto:support@barbaraiot.com">support&#64;barbaraiot.com</a>. To be
      eligible, the credit request must be received by us by the end of the second billing cycle
      after which the incident occurred and must include:
    </p>
    <ol class="with-bullets">
      <li>
        Caused by factors outside of our reasonable control, including any force majeure event,
        Internet access, or problems beyond the demarcation point of the Barbara IoT network;
      </li>
      <li>That results from any actions or inactions of you or any third party;</li>
      <li>
        That results from the equipment, software or other technology of you or any third party
        (other than third party equipment within our direct control);
      </li>
      <li>That results from any Maintenance.</li>
    </ol>

    <h3 class="title">Technical Support Policy</h3>
    <p class="initial">
      &emsp;We offer several options for technical support. All billable accounts include basic
      support, and we offer paid upgrade options. Check with Barbara IoT team
      <a class="link" href="mailto:info@barbaraiot.com">info&#64;barbaraiot.com</a> for critical support
      plans and pricing. Regardless of any other agreements that may exist due to consultancy or
      developing services on top of Barbara platform, Barbara support includes:
    </p>
    <ul class="with-bullets">
      <li>Answering questions about Barbara services and features</li>
      <li>Troubleshooting Barbara services and products</li>
      <li>Limited support of third party applications, services and frameworks</li>
    </ul>
    <p class="initial">
      &emsp;Unless explicitely agreed in another agreement, Barbara support does not include:
    </p>
    <ul class="with-bullets">
      <li>Developing your application code</li>
      <li>Debugging custom software</li>
      <li>Performing manual system administration tasks</li>
    </ul>
    <h4 class="subtitle">Support Business Hours and SLA</h4>
    <p class="initial">
      &emsp;Normal Barbara business hours are 9am-6pm CET. The basic support included in all
      accounts has the following SLA:
    </p>
    <ol class="with-bullets">
      <li>
        Support enquiries are directed through email
        <a class="link" href="mailto:support@barbaraiot.com">support&#64;barbaraiot.com</a>
      </li>
      <li>
        The time for a response is max one working day in which a Barbara support team member will
        contact the customer to gather information around the issue and investigate resolution.
      </li>
      <li>
        If a problem is found it will be resolved in a maximum time period of two working days.
      </li>
    </ol>
    <h4 class="subtitle">Deprecation</h4>
    <p class="initial">
      &emsp;We will announce if we intend to discontinue or make backwards incompatible changes to
      any Service. We will always try to give you as much time as possible to make any necessary
      modifications to your applications or processes. This policy does not apply to versions,
      features, and functionality that we label as “beta” or “experimental.”
    </p>
    <h4 class="subtitle">Documentation</h4>
    <p class="initial">
      &emsp;We may provide documentation for the Services and their use. Our documentation may
      specify restrictions on how Applications may be built or configured, or how Services must be
      configured. You agree to comply with any such restrictions as specified.
    </p>
    <h4 class="subtitle">End User Support</h4>
    <p class="initial">
      &emsp;You are responsible for providing customer service (if any) to End Users (1st level
      support). We do not provide any support or services to End Users unless we have a separate
      agreement with you or an End User for the provision of such services.
    </p>

    <h3 class="title">Security Policy</h3>
    <p class="initial">
      &emsp;You are responsible for properly configuring and using the Services and taking your own
      steps to maintain appropriate security, protection and backup of Your Content, which may
      include the use of encryption technology to protect Your Content from unauthorized access and
      routinely archiving Your Content. Where configurable or optional security controls are offered
      as part of the Services, you are responsible for configuring or enabling those controls. You
      are ultimately responsible for determining whether the security controls applied to your
      Applications and data are sufficient for your requirements.
    </p>
    <p class="initial">
      &emsp;Barbara IoT access credentials and private keys generated by the Services are for your
      internal use only. You may not sell, transfer or sublicense them to any other entity or
      person, except that you may disclose your private key to your agents and subcontractors
      performing work on your behalf.
    </p>
    <h4 class="subtitle">Requesting Penetration Testing Authorization</h4>
    <p class="initial">
      &emsp;You may conduct penetration tests of your services deployed with Barbara. To do so,
      please contact us with the following information:
    </p>
    <ul class="with-bullets">
      <li>Start and end times for the scan window (YYYY-MM-DD HH:SS format)</li>
      <li>Environment(s) to be tested</li>
      <li>Source IPs (and owners of those IPs) for the scan</li>
      <li>Peak bandwidth in Gbps</li>
      <li>Expected peak requests per second</li>
      <li>Name, email, and phone for a point of contact for both you and the testing company</li>
    </ul>
    <h4 class="subtitle">Reporting Security Vulnerabilities</h4>
    <p class="initial">
      &emsp;If you discover a potential security vulnerability we strongly prefer that you notify us
      in private. Publicly disclosing a security vulnerability without informing us first puts the
      community at risk. When you notify us of a potential problem, we will work with you to make
      sure we understand the scope and cause of the issue. Thank you.
    </p>
    <h3 class="title">Other terms</h3>
    <p class="initial">
      &emsp;You understand that Barbara uses third-party vendors and hosting partners to provide the
      necessary hardware, software, networking, storage, and related technology required to run the
      Service.
    </p>
    <p class="initial">
      &emsp;You may not modify, adapt or hack the Service or modify another website so as to falsely
      imply that it is associated with the Service, Barbara, or any other Barbara service.
    </p>
    <p class="initial">
      &emsp;You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
      Service, use of the Service, or access to the Service without the express written permission
      by Barbara.
    </p>
    <p class="initial">
      &emsp;We may, but have no obligation to, remove Content and Accounts containing Content that
      we determine in our sole discretion are unlawful, offensive, threatening, libelous,
      defamatory, pornographic, obscene or otherwise objectionable or violates any party's
      intellectual property or these Terms of Service.
    </p>
    <p class="initial">
      &emsp;Verbal, physical, written or other abuse (including threats of abuse or retribution) of
      any Barbara customer, employee, member, or officer will result in immediate Account
      termination.
    </p>
    <p class="initial">
      &emsp;YOU EXPRESSLY UNDERSTAND AND AGREE THAT BARBARA WILL NOT BE LIABLE FOR ANY INDIRECT,
      INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
      FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF BARBARA HAS BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO
      USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM
      ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
      TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR
      ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY ON THE
      SERVICE; (V) OR ANY OTHER MATTER RELATING TO THE SERVICE.
    </p>
    <p class="initial">
      &emsp;The failure of Barbara to exercise or enforce any right or provision of the Terms of
      Service will not constitute a waiver of such right or provision. The Terms of Service
      constitutes the entire agreement between you and Barbara and govern your use of the Service,
      superseding any prior agreements between you and Barbara (including, but not limited to, any
      prior versions of the Terms of Service).
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-actions">
  <button [mat-dialog-close]="false" class="modal-button" color="accent-inverted" mat-button>
    Reject
  </button>
  <button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="modal-button accept"
    color="accent"
    mat-button>
    Accept
  </button>
</mat-dialog-actions>
