import { Injectable } from '@angular/core';
import { BackEndCommunicationService } from 'app/main/common/services/beComm.service';
import { Observable } from 'rxjs';
import { ChangeLogModel } from '../models/changelog.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {
  constructor(private backEndCommunicationService: BackEndCommunicationService) {}

  getChangeLog = (): Observable<ChangeLogModel[]> => {
    return this.backEndCommunicationService.getResourceObservable('/changelog');
  };
  getChangeLogById = (_id: string): Observable<ChangeLogModel> => {
    return this.backEndCommunicationService.getResourceObservable(`/changelog/${_id}`);
  };
  postChangeLog = (data: object): Observable<ChangeLogModel> => {
    return this.backEndCommunicationService.postResourceObservable('/changelog', { data });
  };
  putChangeLog = (_id: string, data: object): Observable<ChangeLogModel> => {
    return this.backEndCommunicationService.putResourceObservable(`/changelog/${_id}`, { data });
  };
  deleteChangeLog = (dataItem: object): Observable<ChangeLogModel> => {
    return this.backEndCommunicationService.deleteResourceObservable('/changelog/', {
      data: {
        changelogIds: [dataItem]
      }
    });
  };
}
