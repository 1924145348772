import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DeviceListService } from '../../../devices/services/device-list.service';
import { DeviceStoreService } from '../../../store/device-store.service';
import { dataTable } from '../../models/common.model';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { TableService } from '../../services/table.service';

@AutoUnsubscribe()
@Component({
  selector: 'table-dinamic',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnDestroy, OnChanges {
  className = 'table';
  @Input() checkboxes: any;
  @Input() originalData: any;
  @Input() displayedColumns;
  @Input() dataTable: dataTable[];
  @Input() isloading: boolean;
  @Input() width: number;
  @Input() noDataLabel?: string;
  @Input() view?: string;
  @Input() borderRadius?: string;
  @Input() boxShadow?: string;
  @Input() selectedIncludedDevices?;
  @Input() dataSourceIncluded;
  @Output() emitActionsOut = new EventEmitter();
  @Output() emitGetAllPaginator = new EventEmitter();
  @Output() emitSelected = new EventEmitter();
  @Input() selectionIncluded?;

  show = false;
  dataSource: any;
  matSortActive: any;
  matSortDirection: any;
  totalChecked: number;
  totalRows: number;
  allChecked: boolean;
  dataIncludedDevice: boolean;
  userMainRole: string;

  constructor(
    public deviceListService: DeviceListService,
    private deviceStoreService: DeviceStoreService,
    private authService: AuthService,
    private storageService: StorageService,
    private tableService: TableService
  ) {}

  ngOnChanges(): void {
    if (this.width) {
      this.show = true;
      this.createTable();
    }
  }

  ngOnInit(): void {
    this.createTable();
    this.authService.getMainRol().subscribe((userMainRol) => {
      this.userMainRole = userMainRol;
    });
  }

  createTable() {
    this.dataSource = null;
    this.dataSource = new MatTableDataSource(this.originalData);
    //console.log(this.dataSource);
    let sortBy = JSON.parse(this.storageService.retrieveListSorting(this.view));
    if (sortBy !== null) {
      this.matSortActive = sortBy.active;
      this.matSortDirection = sortBy.direction;
    }

    if (this.selectedIncludedDevices?.length) {
      this.totalChecked = this.selectedIncludedDevices.length;
      this.totalRows = this.dataSource.data.length;
      this.dataIncludedDevice = false;
      this.dataSource.data.forEach((device) => {
        if (this.selectedIncludedDevices.includes(device._id)) {
          this.dataIncludedDevice = true;
        }
      });

      if (this.totalChecked === this.totalRows && this.dataIncludedDevice) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    } else {
      this.allChecked = false;
    }
  }

  public isAllIncludedSelected(): any {
    const numSelectedOne = this.selectedIncludedDevices.length;
    const numRowsOne = this.dataSourceIncluded.data.length;
    return numSelectedOne === numRowsOne;
  }

  masterAvailableToggle(): void {
    if (this.isAllIncludedSelected()) {
      this.selectionIncluded.clear();
      this.deselectIncludedDevices();
    } else {
      this.dataSourceIncluded.data.forEach((row) => {
        this.selectionIncluded.select(row);
        this.selectIncludedDevices();
      });
    }
    this.emitSelected.emit(this.selectedIncludedDevices);
  }

  public deselectIncludedDevices(): void {
    this.selectedIncludedDevices = [];
    this.emitSelected.emit(this.selectedIncludedDevices);
  }

  public selectIncludedDevices(filterValue?, filterParameter?): void {
    // If there is no filter, select all contacts
    if (filterParameter === undefined || filterValue === undefined) {
      this.dataSourceIncluded.data.map((device) => {
        this.selectedIncludedDevices.push(device._id);
        // filter repeated elements
        this.selectedIncludedDevices = this.selectedIncludedDevices.filter(
          (item, index) => this.selectedIncludedDevices.indexOf(item) === index
        );
      });
    }
  }

  public onSelectedIncludedChange($event: Event, device: any): any {
    if ($event) {
      this.selectedIncludedDevices.push(device._id);
      this.selectedIncludedDevices = this.selectedIncludedDevices.filter(
        (elm) => elm !== undefined
      );
    } else {
      const index = this.selectedIncludedDevices.indexOf(device._id);
      this.selectedIncludedDevices = this.selectedIncludedDevices.filter(
        (elm) => elm !== undefined
      );
      this.selectedIncludedDevices.splice(index, 1);
    }
    this.emitSelected.emit(this.selectedIncludedDevices);
  }

  emitActions(element, actions) {
    this.emitActionsOut.emit({ element, actions });
  }

  getAllPaginator(size?) {
    if (size === 0) {
      this.emitGetAllPaginator.emit(size);
    } else {
      this.emitGetAllPaginator.emit();
    }
  }

  storeSort(event, view?) {
    const listSort = event;
    if (listSort) {
      this.dataSource.data = this.tableService.storeSort(listSort, this.dataSource.data, view);
    } else {
      this.dataSource.data = this.tableService.storeSort(
        { active: 'online', direction: 'asc' },
        this.dataSource.data,
        view
      );
    }
  }

  identify(index) {
    return index;
  }

  ngOnDestroy(): void {}
}
