import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceListService } from '../devices/services/device-list.service';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent {
  id: any;
  className = 'qr';

  listRectangle = [
    '#0aae4c',
    '#92940d',
    '#e6bf18',
    '#fb8649',
    '#fb3449',
    '#e54fe5',
    '#b428be',
    '#006494',
    '#0087c9'
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceListService: DeviceListService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
    });
  }

  copyToClipBoard(id) {
    this.deviceListService.copyToClipBoard(id);
  }
}
