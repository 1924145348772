import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSnackBarService } from '../validators/messageSnackBar.service';

@Injectable({
  providedIn: 'root'
})
export class SnackMessageService {
  messageSnackBar = inject(MessageSnackBarService);
  snackBar = inject(MatSnackBar);

  sendMessage(code: string): void {
    const result = this.messageSnackBar.dictionary.find((item) => item['snackbarcode'] === code);
    let message = result ? result['snackbarmessage'] : code;

    this.snackBar.open(message, 'Dismiss', {
      duration: 4000,
      panelClass: 'app-notification-success'
    });
  }

  readError(error: any): void {
    const errorCode = error.error.response.errorCode;

    if (
      errorCode === 'E113' ||
      errorCode === 'E114' ||
      errorCode === 'E115' ||
      errorCode === 'E063' ||
      errorCode === 'E104' ||
      errorCode === 'E103' ||
      errorCode === 'E077'
    ) {
      return;
    }

    const errorMessage = error.error.response.errorMessage;
    this.sendMessage(errorMessage);
  }
}
