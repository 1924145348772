<div class="{{ className }}__dialog-content">
  <div class="{{ className }}__header">
    <modal-icon-title-chip-close
      (emitActions)="back()"
      [chip]="data.chipMessage | reduce: 6 : 7"
      [close]="true"
      [title]="data.titleMessage"></modal-icon-title-chip-close>
  </div>

  <mat-dialog-content>
    <div [innerHtml]="data.contentMessage | safe: 'html'" class="{{ className }}__text"></div>
  </mat-dialog-content>

  <mat-dialog-actions
    *ngIf="data.button1Color !== undefined && data.button2Color !== undefined"
    align="end"
    class="{{ className }}__footer">
    <button
      (click)="back()"
      class="big"
      color="{{ data.button2Color }}"
      id="{{ className }}__button-{{
        data.button2Message
          | lowercase
          | replace: '_' : '-'
          | replace: ' ' : '-'
          | replace: '.' : '-'
      }}"
      mat-stroked-button>
      {{ data.button2Message }}
    </button>
    <button
      (click)="onConfirmed()"
      class="big"
      color="{{ data.button1Color }}"
      id="{{ className }}__button-{{
        data.button1Message
          | lowercase
          | replace: '_' : '-'
          | replace: ' ' : '-'
          | replace: '.' : '-'
      }}"
      mat-flat-button>
      {{ data.button1Message }}
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions
    *ngIf="data.button1Color === undefined || data.button2Color === undefined"
    align="end"
    class="{{ className }}__footer">
    <button
      (click)="back()"
      *ngIf="data.button2Color !== undefined"
      class="big"
      color="{{ data.button2Color }}"
      id="{{ className }}__button-{{
        data.button2Message
          | lowercase
          | replace: '_' : '-'
          | replace: ' ' : '-'
          | replace: '.' : '-'
      }}"
      mat-stroked-button>
      {{ data.button2Message }}
    </button>
    <button
      (click)="onConfirmed()"
      *ngIf="data.button1Color !== undefined"
      class="big"
      color="{{ data.button1Color }}"
      id="{{ className }}__button-{{
        data.button1Message
          | lowercase
          | replace: '_' : '-'
          | replace: ' ' : '-'
          | replace: '.' : '-'
      }}"
      mat-flat-button>
      {{ data.button1Message }}
    </button>
  </mat-dialog-actions>
</div>