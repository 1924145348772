import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonLabels } from '../../models/common.model';
import { commonLabels } from '../../common.labels';
import { ResizeWindowService } from '../../services/resize-window.service';

@Component({
  selector: 'app-filter-select-view',
  templateUrl: './filter-select-view.component.html',
  styleUrls: ['./filter-select-view.component.scss']
})
export class FilterSelectViewComponent implements OnChanges {
  @Input() typeSearch?: boolean;
  @Input() filterInput: string;
  @Input() filterPlaceholderLabel: string;
  @Input() minCharacterToFilter: number;
  @Input() tabSelectedIndex?: number;
  @Input() tabSelectedId?: string;
  @Input() showTable?: boolean;
  @Input() showGrid?: boolean;
  @Input() showMap?: boolean;
  @Input() selectedView?: string;
  @Input() isLoading: boolean;
  @Input() width: number;
  @Input() view?: string;
  @Input() showCalendar?: boolean;
  @Output() emitInputFilter = new EventEmitter();
  @Output() emitClearFilter = new EventEmitter();
  @Output() emitSelectedView = new EventEmitter();
  className = 'filter-select-view';
  labels: CommonLabels = commonLabels;
  inputFilter: string;

  constructor(private resizeWindowService: ResizeWindowService) {}

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
    //console.log(this.tabSelectedIndex);
    //console.log(this.tabSelectedId);
    this.inputFilter = this.filterInput;
  }

  onInputFilter($event) {
    //console.log($event);
    if (
      $event.code === 'Backspace' &&
      $event.target.value.length > 0 &&
      $event.target.value.length < this.minCharacterToFilter
    ) {
      return;
    } else {
      if ($event.target.value.length >= this.minCharacterToFilter) {
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.emitInputFilter.emit({
              event: $event,
              tab: this.tabSelectedIndex !== undefined ? this.tabSelectedIndex : 0,
              tabId: this.tabSelectedId !== undefined ? this.tabSelectedId : ''
            });
          },
          300,
          'search filter'
        );
      } else if ($event.target.value.length === 0) {
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.onClearFilter({
              tab: this.tabSelectedIndex !== undefined ? this.tabSelectedIndex : 0,
              tabId: this.tabSelectedId !== undefined ? this.tabSelectedId : '',
              clear: true
            });
          },
          300,
          'clear filter'
        );
      } else {
        return;
      }
    }
  }

  onClearFilter($event) {
    // console.log($event);
    this.emitClearFilter.emit({
      tab: $event.tab !== undefined ? $event.tab : 0,
      tabId: this.tabSelectedId !== undefined ? this.tabSelectedId : '',
      clear: $event.clear
    });
  }
}
