import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UpperCasePipe } from '@angular/common';
import { SharedModule } from '../common/shared.module';

import { ProfilePageComponent } from './profile/profile-page/profile-page.component';
import { TermsConditionsComponent } from './profile/terms-conditions/terms-conditions.component';
import { VpnDevicesComponent } from '../users/components/vpn-devices/vpn-devices.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfilePageComponent
  }
];

@NgModule({
  declarations: [
    ProfilePageComponent,
    RegisterComponent,
    TermsConditionsComponent,
    VpnDevicesComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    TranslateModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ImageCropperModule,
    MatDialogModule,
    UpperCasePipe,
    MatAutocompleteModule
  ]
})
export class AccountModule {}
