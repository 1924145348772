import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogLabels } from 'app/main/devices/models/device-list-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-dialog-material',
  templateUrl: './confirm-dialog-material.component.html',
  styleUrls: ['./confirm-dialog-material.component.scss']
})
export class ConfirmDialogMaterialComponent implements OnInit {
  className = 'confirmDialogMaterialComponent';
  labels: ConfirmDialogLabels;
  link = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { labels: ConfirmDialogLabels; link: boolean },
    public dialogRef: MatDialogRef<ConfirmDialogMaterialComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.labels = this.data.labels;
    this.link = this.data.link;
  }

  openDocu() {
    window.open('https://academy.barbaraiot.com/?ht_kb=vpn-virtual-network-management', '_blank');
  }

  aceptModal() {
    this.dialogRef.close('ok');
  }

  closeModal() {
    this.dialogRef.close();
  }
}
