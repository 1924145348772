export const navigation: any[] = [
  {
    id: 'devices',
    title: 'Nodes',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-symbols-outlined',
    icon: 'router',
    url: '/devices/devices-list'
  },
  {
    id: 'running-applications',
    title: 'Workloads',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-symbols-outlined',
    icon: 'assistant_on_hub',
    url: '/running-applications/list'
  },

  {
    id: 'registryList',
    title: 'Library',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'collections_bookmark',
    url: '/application/list'
  },

  {
    id: 'vpn',
    title: 'VPN',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'vpn_lock',
    url: '/users/vpn'
  },
  {
    id: 'batches',
    title: 'Batches',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'rocket_launch',
    url: '/batches/list'
  },
  {
    id: 'images',
    title: 'OS Image',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'adjust',
    url: '/os-image/list'
  },
  {
    id: 'auditlogs',
    title: 'Audit Logs',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'content_paste_search',
    url: '/auditlogs/list'
  },
  // {
  //   id: 'users',
  //   title: 'Users',
  //   type: 'item',
  //   externalUrl: false,
  //   borderTop: false,
  //   classFont: 'material-icons-outlined',
  //   icon: 'supervisor_account',
  //   url: '/users/userlist'
  // },
  /*
  {
    id: 'documentation',
    title: 'QuickStart Guide',
    type: 'item',
    externalUrl: true,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'insert_drive_file',
    url: 'https://prod.images.barbaraiot.com/generic/Barbara_Quickstart_v1.3.pdf'
  },*/
  {
    id: 'changeLog',
    title: 'Changelog',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'checklist',
    url: '/changelog'
  }
];
export const navigationAdmin: any[] = [
  {
    id: 'companies',
    title: 'Companies',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'account_balance',
    url: '/admin/companies'
  },
  {
    id: 'tiers',
    title: 'Tiers',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-sharp',
    icon: 'production_quantity_limits',
    url: '/admin/tiers'
  },
  {
    id: 'osversions',
    title: 'Versions',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'file_upload',
    url: '/admin/osversions'
  },
  {
    id: 'families',
    title: 'Families',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'workspaces',
    url: '/admin/families'
  },
  {
    id: 'supportedDevices',
    title: 'Supported Nodes',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'memory',
    url: '/admin/supporteddevices'
  },
  {
    id: 'changeLog',
    title: 'Changelog',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    classFont: 'material-icons-outlined',
    icon: 'checklist',
    url: '/changelog'
  }
  // {
  //   id: 'up-marketplace',
  //   title: 'Up-App-Markeplace',
  //   type: 'item',
  //   externalUrl: false,
  //   borderTop: false,
  //   classFont: 'material-icons-outlined',
  //   icon: 'file_upload',
  //   url: 'admin/up-marketplace'
  // }
];
