import { Component, OnInit } from '@angular/core';

import { ProfileModel, VpnModel } from '../../../account/profile/profile.model';
import { ProfileService } from '../../../account/profile/profile.service';

@Component({
  selector: 'app-vpn-devices',
  templateUrl: './vpn-devices.component.html',
  styleUrls: ['./vpn-devices.component.scss']
})
export class VpnDevicesComponent implements OnInit {
  className: string = 'vpn-devices';
  profile: ProfileModel;
  vpn: VpnModel;
  dataSource: { ip: string; configFile: string }[] = [];
  displayedColumns: string[] = ['ip', 'configFile'];
  arraySubscription: any = [];

  constructor(private profileService: ProfileService) {}

  ngOnInit() {
    this.arraySubscription.push(
      this.profileService.getUserProfile().subscribe({
        next: (response) => {
          this.profile = response;
          if (this.profile.vpn) {
            this.vpn = this.profile.vpn;
            this.createdataSource();
          }
        },
        error: (error) => {
          this.dataSource = [];
          console.error(error);
        }
      })
    );
  }

  downloadConfig() {
    const a = document.createElement('a');
    const txt = new Blob([this.vpn.configFile], { type: 'text/plain' });
    const url = URL.createObjectURL(txt);
    a.href = url;
    a.download = 'wg.conf';
    a.click();
    URL.revokeObjectURL(url);
  }

  private createdataSource() {
    const dataSource = [
      {
        ip: this.vpn.ip,
        configFile: this.vpn.configFile
      }
    ];
    this.dataSource = dataSource;
  }
}