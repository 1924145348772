import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduce'
})
export class ReducePipe implements PipeTransform {
  transform(value: any, from = 7, to = 7): any {
    if (!value) {
      console.log('value', value);
      return '';
    } else if (value.length <= from + to) {
      return value;
    } else {
      const firstFourLetters = value.slice(0, from);
      const lastSixDigits = value.slice(-to);
      return `${firstFourLetters}...${lastSixDigits}`;
    }
  }
}
