<div class="{{ className }}">
  <div class="{{ className }}__wrapper" tabindex="0">
    <table
      (matSortChange)="storeSort($event, view)"
      [dataSource]="dataSource"
      [matSortActive]="matSortActive"
      [matSortDirection]="matSortDirection"
      [style.border-radius]="borderRadius ? borderRadius : ''"
      [style.box-shadow]="boxShadow ? boxShadow : ''"
      class="without-scroll {{ borderRadius ? 'radius' : '' }}"
      id="table{{ view }}"
      mat-table
      matSort>
      <ng-container
        *ngFor="let data of dataTable; let i = index; trackBy: identify"
        [matColumnDef]="data.colData">
        <!--check-->
        <ng-container *ngIf="data.colType === 'check'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-header-cell>
            <mat-checkbox
              (change)="$event ? masterAvailableToggle() : null"
              [checked]="(selectionIncluded.hasValue() && isAllIncludedSelected()) || allChecked"
              [disabled]="userMainRole === 'role_company_read'"
              [labelPosition]="'before'"
              [style.display]="data.showCol ? 'table-cell' : 'none'"
              [style.width]="data.width ?? 'auto'"
              class="{{ className }}__th-{{ i }}  {{
                data.align ? className + '__' + data.align : ''
              }}"
              color="dark-blue"
              id="table{{ view }}-checkbox-header">
            </mat-checkbox>
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <mat-checkbox
              (change)="$event ? selectionIncluded.toggle(element) : null"
              (click)="$event.stopPropagation()"
              (ngModelChange)="onSelectedIncludedChange($event, element)"
              [(ngModel)]="
                element.hasOwnProperty('_id') ? checkboxes[element._id] : checkboxes[element]
              "
              [checked]="selectedIncludedDevices.includes(element._id)"
              [disabled]="userMainRole === 'role_company_read'"
              [labelPosition]="'before'"
              [style.display]="data.showCol ? 'table-cell' : 'none'"
              [style.width]="data.width ?? 'auto'"
              class="{{ className }}__td-{{ i }}  {{
                data.align ? className + '__' + data.align : ''
              }}"
              color="dark-blue"
              id="table{{ view }}-checkbox-{{ element._id }}">
            </mat-checkbox>
            {{ element.id | json }}
          </td>
        </ng-container>
        <!--link-->
        <ng-container *ngIf="data.colType === 'link'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.max-width]="data.width ?? 'auto'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            <span
              [style.max-width]="data.width ?? 'auto'"
              class="ellipsis"
              matTooltip="{{ data.labels }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before"
              >{{ data.labels }}</span
            >
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span
              (click)="emitActions(element, 'detail')"
              [style.max-width]="data.width ?? 'auto'"
              class="{{ className }}__link ellipsis"
              id="table{{ view }}__link-detail-{{
                element[data.colData]
                  | lowercase
                  | replace: '_' : '-'
                  | replace: ' ' : '-'
                  | replace: '.' : '-'
              }}"
              matTooltip="{{ element[data.colData] }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before">
              {{ element[data.colData] }}
            </span>
          </td>
        </ng-container>
        <ng-container *ngIf="data.colType === 'link-icon'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.max-width]="data.width ?? 'auto'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            <span
              [style.max-width]="data.width ?? 'auto'"
              class="ellipsis"
              matTooltip="{{ data.labels }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before"
              >{{ data.labels }}</span
            >
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span
              (click)="emitActions(element, 'detail')"
              [style.max-width]="data.width ?? 'auto'"
              class="{{ className }}__link ellipsis"
              id="table{{ view }}-link-detail-{{ element._id }}"
              matTooltip="{{ element[data.colData] }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before">
              {{ element[data.colData] }}
            </span>
            <mat-icon
              *ngIf="element.updateDeviceVersion"
              [style.font-size]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
              [style.height]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
              [style.line-height]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
              [style.width]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
              class="material-icons-outlined {{ className }}__text-icon-icon"
              color="{{ data.iconText.color !== '' ? data.iconText.color : 'primary' }}"
              matTooltip="{{ data.iconText.labelsTooltip }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="after"
              >{{ data.iconText.iconName }}
            </mat-icon>
          </td>
        </ng-container>
        <!--img-->
        <ng-container *ngIf="data.colType === 'img'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-header-cell>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <div *ngIf="data.colData === 'avatar'" class="{{ className }}__avatar">
              <img
                alt="avatar"
                src="{{
                  element.avatar !== '' ? element.avatar : 'assets/images/avatars/profile.jpg'
                }}" />
            </div>
          </td>
        </ng-container>
        <!--icon & iconType-->
        <ng-container *ngIf="data.colType === 'icon' && data.colData === 'iconType'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span class="{{ className }}__wrapper-icon">
              <img
                *ngIf="element.iconType === '00_marketplace'"
                class="{{ data.icon[0].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[0].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[0].iconName }}" />

              <img
                *ngIf="element.iconType === '01_docker'"
                class="{{ data.icon[1].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[1].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[1].iconName }}" />
              <img
                *ngIf="element.iconType === '03_tensorflow'"
                class="{{ data.icon[2].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[2].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[2].iconName }}" />
              <img
                *ngIf="element.iconType === '04_onnx'"
                class="{{ data.icon[3].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[3].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[3].iconName }}" />
              <img
                *ngIf="element.iconType === '05_pytorch'"
                class="{{ data.icon[4].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[4].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[4].iconName }}" />
              <img
                *ngIf="element.iconType === '06_scikit'"
                class="{{ data.icon[5].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[5].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[5].iconName }}" />
              <img
                *ngIf="element.iconType === '07_xgboost'"
                class="{{ data.icon[6].customClassIcon }} {{ className }}__icon-svg"
                matTooltip="{{ data.icon[6].labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                src="{{ data.icon[6].iconName }}" />
            </span>
          </td>
        </ng-container>
        <!--icon & alive || deviceAlive-->
        <ng-container
          *ngIf="
            data.colType === 'icon' && (data.colData === 'alive' || data.colData === 'deviceAlive')
          ">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span [style]="'margin-right:20px'" class="{{ className }}__wrapper-icon">
              <mat-icon
                class="{{
                  element.alive || element.deviceAlive
                    ? data.icon[0].customClassIcon
                    : data.icon[1].customClassIcon
                }} {{ className }}__icon"
                matTooltip="{{
                  element.alive || element.deviceAlive
                    ? data.icon[0].labelsTooltip
                    : data.icon[1].labelsTooltip
                }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                >{{
                  element.alive || element.deviceAlive
                    ? data.icon[0].iconName
                    : data.icon[1].iconName
                }}
              </mat-icon>
            </span>
          </td>
        </ng-container>
        <!--icon & !alive && !deviceAlive && !iconType-->
        <ng-container
          *ngIf="
            data.colType === 'icon' &&
            data.colData !== 'iconType' &&
            data.colData !== 'alive' &&
            data.colData !== 'deviceAlive'
          ">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-header-cell>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <ng-container *ngFor="let icon of data.icon">
              <span
                *ngIf="icon.iconData && icon.iconDisabled"
                class="{{ className }}__wrapper-icon">
                <mat-icon
                  class="{{ icon.customClassIcon }} {{ className }}__icon{{
                    !element.icons[icon.iconDisabled] ? '-disabled' : ''
                  }}"
                  matTooltip="{{ icon.labelsTooltip }}"
                  matTooltipClass="custom-tooltip"
                  matTooltipPosition="before"
                  >{{ icon.iconName }}
                </mat-icon>
              </span>
            </ng-container>
          </td>
        </ng-container>
        <!--running-->
        <ng-container *ngIf="data.colType === 'running'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <ng-container>
              <span>
                {{ element.runningApps + ' of ' + element.installedApps }}
              </span>
            </ng-container>
          </td>
        </ng-container>
        <!--tags-->
        <ng-container *ngIf="data.colType === 'tags'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <mat-chip-listbox name="tags">
              <mat-chip-option *ngIf="element[data.colData].length <= 3">
                <span
                  *ngFor="let tag of element[data.colData] | slice: 0 : 3"
                  class="devicesTable__tags">
                  {{ tag }}
                </span>
              </mat-chip-option>
              <mat-chip-option *ngIf="element[data.colData].length > 3 && width > 1030">
                <span
                  *ngFor="let tag of element[data.colData] | slice: 0 : 2"
                  class="devicesTable__tags">
                  {{ tag }}
                </span>
                <span
                  class="devicesTable__tags"
                  matTooltip="{{ element.tags }}"
                  matTooltipClass="custom-tooltip"
                  matTooltipPosition="above"
                  >+{{ element.tags.length - 2 }}</span
                >
              </mat-chip-option>
              <mat-chip-option *ngIf="element[data.colData].length > 3 && width <= 1030">
                <span
                  *ngFor="let tag of element[data.colData] | slice: 0 : 1"
                  class="devicesTable__tags">
                  {{ tag }}
                </span>
                <span
                  class="devicesTable__tags"
                  matTooltip="{{ element[data.colData] }}"
                  matTooltipClass="custom-tooltip"
                  matTooltipPosition="above"
                  >+{{ element[data.colData].length - 1 }}</span
                >
              </mat-chip-option>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <!--text-->
        <ng-container *ngIf="data.colType === 'text'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.max-width]="data.width ?? 'auto'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            <span
              [style.max-width]="data.width ?? 'auto'"
              class="ellipsis {{ data.align ? className + '__' + data.align : '' }}"
              matTooltip="{{ data.labels }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before"
              >{{ data.labels }}</span
            >
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span
              [style.max-width]="data.width ?? 'auto'"
              class="ellipsis"
              matTooltip="{{ element[data.colData] }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before"
              >{{ element[data.colData] }}</span
            >
          </td>
        </ng-container>
        <!--text-icon-->
        <ng-container *ngIf="data.colType === 'text-icon'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <div class="{{ className }}__text-icon">
              <span
                class="{{ className }}__text-icon-text"
                matTooltip="{{ element[data.colData] }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="before"
                >{{ element[data.colData] }}
              </span>
              <mat-icon
                *ngIf="element.updateAvailable !== ''"
                [style.font-size]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
                [style.height]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
                [style.line-height]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
                [style.width]="data.iconText.fontSize !== '' ? data.iconText.fontSize : ''"
                class="material-icons-outlined {{ className }}__text-icon-icon"
                color="{{ data.iconText.color !== '' ? data.iconText.color : 'primary' }}"
                matTooltip="{{ data.iconText.labelsTooltip }}"
                matTooltipClass="custom-tooltip"
                matTooltipPosition="after"
                >{{ data.iconText.iconName }}
              </mat-icon>
            </div>
          </td>
        </ng-container>
        <!--pipe-->
        <ng-container *ngIf="data.colType === 'pipe'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            disableClear
            mat-header-cell
            mat-sort-header>
            {{ data.labels }}
          </th>
          <td
            *matCellDef="let element"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <span>
              {{
                data.pipe === 'uppercase'
                  ? (element[data.colData] | uppercase)
                  : data.pipe === 'architectures'
                    ? (element[data.colData] | architectures)
                    : data.pipe === 'persist'
                      ? (element[data.colData] | persist)
                      : data.pipe === 'date' && element[data.colData] !== ''
                        ? (element[data.colData] | date: 'd/M/yy, h:mm a') === '1/1/70, 1:00 AM'
                          ? 'Never Seen'
                          : (element[data.colData] | date: 'd/M/yy, h:mm a')
                        : 'Online'
              }}
            </span>
          </td>
        </ng-container>
        <!--button-->
        <ng-container *ngIf="data.colType === 'button'">
          <th
            *matHeaderCellDef
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.max-width]="data.width ?? 'auto'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__th-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-header-cell>
            <span
              class="ellipsis"
              matTooltip="{{ data.labels }}"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="before"
              >{{ data.labels }}</span
            >
          </th>
          <td
            *matCellDef="let element; let k = index"
            [style.display]="data.showCol ? 'table-cell' : 'none'"
            [style.width]="data.width ?? 'auto'"
            class="{{ className }}__td-{{ i }}  {{
              data.align ? className + '__' + data.align : ''
            }}"
            mat-cell>
            <!-- status === 1   PROGRESS BAR-->
            <ng-container *ngIf="data.actions && element.status === 1">
              <div class="progress-bar">
                <mat-progress-bar
                  class="progress-bar"
                  color="primary"
                  mode="indeterminate"></mat-progress-bar>
              </div>
            </ng-container>
            <!-- status === 2   actions.actionType === 'update' -->
            <ng-container *ngIf="data.actions && element.status === 2">
              <ng-container *ngFor="let actions of data.actions">
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.showButton &&
                    element.name !== 'Default' &&
                    element.apptype !== 1 &&
                    element.iconType !== '00_marketplace' &&
                    actions.actionType === 'update'
                  "
                  [disabled]="element.updateAvailable === ''"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.showButton &&
                    element.name !== 'Default' &&
                    element.apptype !== 1 &&
                    element.iconType !== '00_marketplace' &&
                    actions.actionType !== 'update'
                  "
                  [disabled]="actions.disabledButton"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <!-- sin status -->
            <ng-container *ngIf="data.actions && !element.status">
              <ng-container *ngFor="let actions of data.actions">
                <!-- BOTONES GRUPO DEFAULT -->
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="actions.showButton && element.name === 'Default'"
                  [disabled]="element.name === 'Default' && actions.actionType === 'delete'"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <!-- BOTONES MARKETPLACE -->
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.showButton &&
                    element.apptype === 1 &&
                    element.iconType === '00_marketplace' &&
                    actions.icon.iconName !== 'visibility'
                  "
                  [disabled]="element.apptype === 1"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}-prueba"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.icon.iconName === 'visibility' &&
                    element.apptype === 1 &&
                    element.iconType === '00_marketplace'
                  "
                  [disabled]="
                    actions.icon.iconName === 'visibility' && element.releaseNotes[0] !== ''
                      ? false
                      : element.marketplace
                  "
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}-aqui"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.icon.iconName === 'visibility' &&
                    element.apptype !== 1 &&
                    element.iconType !== '00_marketplace'
                  "
                  [disabled]="
                    actions.icon.iconName === 'visibility' ? element.releaseNotes[0] === '' : false
                  "
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <!-- BOTONES PLAY/STOP -->

                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    (actions.showButton && actions.actionType === 'play') ||
                    (actions.showButton && actions.actionType === 'stop')
                  "
                  [disabled]="
                    (element.disablePlayButton && actions.actionType === 'play') ||
                    (element.disableStopButton && actions.actionType === 'stop')
                  "
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <!-- BOTON LOGS -->
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="actions.showButton && actions.actionType === 'logs'"
                  [disabled]="element.disableLogButton"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
                <!-- RESTO DE BOTONES -->
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="
                    actions.showButton &&
                    element.name !== 'Default' &&
                    element.apptype !== 1 &&
                    element.iconType !== '00_marketplace' &&
                    actions.actionType !== 'play' &&
                    actions.actionType !== 'stop' &&
                    actions.actionType !== 'logs' &&
                    actions.icon.iconName !== 'visibility'
                  "
                  [disabled]="actions.disabledButton"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <!-- columna Network OS Image -->
            <ng-container *ngIf="element.actions2">
              <ng-container *ngFor="let actions of data.actions2">
                <button
                  (click)="emitActions(element, actions.actionType)"
                  *ngIf="element.actions2.includes(actions.actionType)"
                  id="table{{ view }}-button-{{ actions.actionType }}-{{ k }}"
                  mat-icon-button>
                  <mat-icon
                    class="material-icons-outlined {{ className }}__icon"
                    matTooltip="{{ actions.icon.labelsTooltip }}"
                    matTooltipClass="custom-tooltip"
                    matTooltipPosition="before"
                    >{{ actions.icon.iconName }}
                  </mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="nodata">
        <td
          *matFooterCellDef
          [colSpan]="displayedColumns.length"
          class="no-data"
          mat-cell
          mat-footer-row>
          {{ noDataLabel ?? 'No data found' }}
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns; let k = index" mat-row></tr>
      <tr *matFooterRowDef="['nodata']" [hidden]="dataSource.data.length > 0" mat-footer-row></tr>
    </table>
  </div>
</div>
<!--[disabled]="actions.icon.iconName === 'visibility' ? false : element.marketplace"-->