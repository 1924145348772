<div class="{{ className }}">
  <div class="{{ className }}__header">
    <modal-icon-title-chip-close
      (emitActions)="close()"
      [close]="true"
      [title]="(data.errorCode | errors).modalTitle"></modal-icon-title-chip-close>
  </div>
  <mat-dialog-content>
    <p class="modal__text">{{ (data.errorCode | errors).modalText }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="{{ data.errorStatus !== 402 ? 'display:none' : '' }}">
    <div *ngIf="data.errorStatus === 402" class="{{ className }}__wrapper-button">
      <button
        (click)="openContactSales()"
        class="big"
        color="primary"
        id="{{ className }}__button-{{
          labelsCommon.contactSales
            | lowercase
            | replace: '_' : '-'
            | replace: ' ' : '-'
            | replace: '.' : '-'
        }}"
        mat-dialog-close
        mat-flat-button>
        {{ labelsCommon.contactSales }}
      </button>
    </div>
  </mat-dialog-actions>
</div>